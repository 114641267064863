import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Container,
} from '@mui/material';
import { fetchInactiveUsers, activateUser } from '../services/api/userApi';
import '../styles/pages.scss';

const InactiveUsers = () => {
  const [inactiveUsers, setInactiveUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await fetchInactiveUsers();
        setInactiveUsers(users);
      } catch (error) {
        console.error('Error fetching inactive users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleActivateUser = async (userId) => {
    try {
      await activateUser(userId);
      setInactiveUsers(inactiveUsers.filter((user) => user.idUser !== userId));
    } catch (error) {
      console.error('Error activating user:', error);
    }
  };

  return (
    <Container maxWidth="lg" className="inactive-users-container">
      <Typography variant="h4" gutterBottom className="page-title">
        Usuarios Inactivos
      </Typography>
      {inactiveUsers.length > 0 ? (
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell><strong>Nombre</strong></TableCell>
                <TableCell><strong>Apellido</strong></TableCell>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Contacto</strong></TableCell>
                <TableCell><strong>Acciones</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inactiveUsers.map((user) => (
                <TableRow key={user.idUser}>
                  <TableCell>{user.idUser}</TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.contact}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleActivateUser(user.idUser)}
                      sx={{ marginRight: '10px' }}
                    >
                      Activar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" align="center" sx={{ marginTop: '20px' }}>
          No hay usuarios inactivos.
        </Typography>
      )}
    </Container>
  );
};

export default InactiveUsers;
