import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Grid,
  Paper,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { fetchImagesByServiceId } from '../services/api/imageServiceApi';
import { fetchTradesByServiceId } from '../services/api/tradeApi';
import { fetchDriverServicesByServiceId } from '../services/api/driverServiceApi';
import { fetchExpensesByServiceId } from '../services/api/expenseApi';
import { fetchExtraHoursByServiceId } from '../services/api/extraHourApi';
import { fetchServiceById } from '../services/api/serviceApi';
import EditDriverModal from './EditDriverModal';
import EditTradeModal from './EditTradeModal';
import EditExpensesModal from './EditExpensesModal';
import EditExtraHourModal from './EditExtraHourModal';
import EditImageModal from './EditImageModal';
import EditServicesModal from './EditServicesModal';

const PendingReviewModal = ({ isOpen, onClose, selectedService, handleFinalizeService }) => {
  const [relatedData, setRelatedData] = useState({
    trades: [],
    driverServices: [],
    expenses: [],
    images: [],
    extraHours: [],
  });
  const [editModalData, setEditModalData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [currentService, setCurrentService] = useState(selectedService); // Estado local para el servicio

  useEffect(() => {
    if (selectedService) {
      setCurrentService(selectedService);
      refreshRelatedData();
    }
  }, [selectedService]);

  const refreshRelatedData = async () => {
    if (currentService) {
      try {
        const trades = await fetchTradesByServiceId(currentService.idService);
        const driverServices = await fetchDriverServicesByServiceId(currentService.idService);
        const expenses = await fetchExpensesByServiceId(currentService.idService);
        const images = await fetchImagesByServiceId(currentService.idService);
        const extraHours = await fetchExtraHoursByServiceId(currentService.idService);

        setRelatedData({ trades, driverServices, expenses, images, extraHours });
      } catch (error) {
        console.error('Error al refrescar los datos relacionados:', error);
      }
    }
  };

  const handleEdit = (type, data) => {
    setModalType(type);
    setEditModalData(data);
  };

  const handleCloseEditModal = async () => {
    if (modalType === 'service') {
      const updatedService = await fetchServiceById(currentService.idService);
      setCurrentService(updatedService);
    }
    await refreshRelatedData();
    setModalType(null);
    setEditModalData(null);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="service-details-modal-title"
      aria-describedby="service-details-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Detalles del Servicio
        </Typography>

        {/* Información del Servicio */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Información del Servicio
            <IconButton onClick={() => handleEdit('service', currentService)}>
              <EditIcon />
            </IconButton>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1"><strong>ID del Cliente:</strong> {currentService?.clientId}</Typography>
              <Typography variant="body1"><strong>Fecha del Servicio:</strong> {currentService?.serviceDate}</Typography>
              <Typography variant="body1"><strong>Matrícula del Vehículo:</strong> {currentService?.vehiclePlate}</Typography>
              <Typography variant="body1"><strong>Marca del Vehículo:</strong> {currentService?.vehicleBrand}</Typography>
              <Typography variant="body1"><strong>Modelo del Vehículo:</strong> {currentService?.vehicleModel}</Typography>
              <Typography variant="body1"><strong>Color del Vehículo:</strong> {currentService?.vehicleColor}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1"><strong>Dirección de Recepción:</strong> {currentService?.receptionAddress}</Typography>
              <Typography variant="body1"><strong>Dirección de Entrega:</strong> {currentService?.deliveryAddress}</Typography>
              <Typography variant="body1"><strong>Nombre del Receptor:</strong> {currentService?.recipientName}</Typography>
              <Typography variant="body1"><strong>NIF del Receptor:</strong> {currentService?.recipientNif}</Typography>
              <Typography variant="body1"><strong>Kilometraje Inicial:</strong> {currentService?.initialKm}</Typography>
              <Typography variant="body1"><strong>Kilometraje Final:</strong> {currentService?.finalKm}</Typography>
              <Typography variant="body1"><strong>Total Kilometraje:</strong> {currentService?.totalKm}</Typography>
              <Typography variant="body1"><strong>Precio Base:</strong> {currentService?.basePrice}</Typography>
              <Typography variant="body1"><strong>Estado del Vehículo:</strong> {currentService?.vehicleStatus}</Typography>
              <Typography variant="body1"><strong>Comentarios de Recepción:</strong> {currentService?.reEntityComments}</Typography>
              <Typography variant="body1"><strong>Comentarios de Entrega:</strong> {currentService?.eentityComments}</Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* Trades Section */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">Vehículos de Intercambio (Trades)</Typography>
          {relatedData.trades.length > 0 ? (
            relatedData.trades.map((trade) => (
              <Box key={trade.idTrade} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                  <Typography variant="body2"><strong>Matrícula del Vehículo:</strong> {trade.tradeVehiclePlate}</Typography>
                  <Typography variant="body2"><strong>Marca:</strong> {trade.tradeVehicleBrand}</Typography>
                  <Typography variant="body2"><strong>Modelo:</strong> {trade.tradeVehicleModel}</Typography>
                  <Typography variant="body2"><strong>Color:</strong> {trade.tradeVehicleColor}</Typography>
                  <Typography variant="body2"><strong>Año:</strong> {trade.tradeVehicleYear}</Typography>
                  <Typography variant="body2"><strong>Dirección de Entrega:</strong> {trade.deliveryAddressTrade}</Typography>
                  <Typography variant="body2"><strong>Nombre del Receptor:</strong> {trade.recipientNameTrade}</Typography>
                  <Typography variant="body2"><strong>NIF del Receptor:</strong> {trade.recipientNifTrade}</Typography>
                  <Typography variant="body2"><strong>Kilometraje Inicial:</strong> {trade.initialKmTrade}</Typography>
                  <Typography variant="body2"><strong>Kilometraje Final:</strong> {trade.finalKmTrade}</Typography>
                  <Typography variant="body2"><strong>Total Kilometraje:</strong> {trade.totalKmTrade}</Typography>
                </Box>
                <IconButton onClick={() => handleEdit('trade', trade)}><EditIcon /></IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No hay vehículos de intercambio registrados.</Typography>
          )}
        </Paper>

        {/* Driver Services Section */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">Servicios de Conductores</Typography>
          {relatedData.driverServices.length > 0 ? (
            relatedData.driverServices.map((driverService) => (
              <Box key={driverService.idDriverService} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                  <Typography variant="body2"><strong>ID del Conductor:</strong> {driverService.driverId}</Typography>
                  <Typography variant="body2"><strong>Tipo de Conductor:</strong> {driverService.driverType}</Typography>
                  <Typography variant="body2"><strong>Tarifa del Conductor:</strong> {driverService.driverRate}</Typography>
                  <Typography variant="body2"><strong>Comentarios Iniciales:</strong> {driverService.reDriverComments}</Typography>
                  <Typography variant="body2"><strong>Comentarios Finales:</strong> {driverService.eDriverComments}</Typography>
                  <Typography variant="body2"><strong>Activo:</strong> {driverService.active ? 'Sí' : 'No'}</Typography>
                </Box>
                <IconButton onClick={() => handleEdit('driverService', driverService)}><EditIcon /></IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No hay servicios de conductores registrados.</Typography>
          )}
        </Paper>

        {/* Expenses Section */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">Gastos</Typography>
          {relatedData.expenses.length > 0 ? (
            relatedData.expenses.map((expense) => (
              <Box key={expense.idExpense} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                  <Typography variant="body2"><strong>Tipo de Gasto:</strong> {expense.expenseType}</Typography>
                  <Typography variant="body2"><strong>Monto Base:</strong> {expense.amountBase}</Typography>
                  <Typography variant="body2"><strong>Monto sin IVA:</strong> {expense.amountExclVat}</Typography>
                  <Typography variant="body2"><strong>Descripción:</strong> {expense.description}</Typography>
                  {expense.image && <Typography variant="body2"><strong>Imagen:</strong> {expense.image}</Typography>}
                </Box>
                <IconButton onClick={() => handleEdit('expense', expense)}><EditIcon /></IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No hay gastos registrados.</Typography>
          )}
        </Paper>

        {/* Images Section */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">Imágenes del Servicio</Typography>
          {relatedData.images.length > 0 ? (
            relatedData.images.map((image) => (
              <Box key={image.idImage} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                  <Typography variant="body2"><strong>Tipo de Imagen:</strong> {image.imageType}</Typography>
                  <Typography variant="body2"><strong>URL de la Imagen:</strong> {image.imageUrl}</Typography>
                </Box>
                <IconButton onClick={() => handleEdit('image', image)}><EditIcon /></IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No hay imágenes registradas.</Typography>
          )}
        </Paper>

        {/* Extra Hours Section */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">Horas Extras</Typography>
          {relatedData.extraHours.length > 0 ? (
            relatedData.extraHours.map((extraHour) => (
              <Box key={extraHour.idExtraHour} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                  <Typography variant="body2"><strong>Horas Extras:</strong> {extraHour.extraHours}</Typography>
                  <Typography variant="body2"><strong>Beneficio:</strong> {extraHour.wedeliveryBenefit}</Typography>
                  <Typography variant="body2"><strong>IVA Beneficio:</strong> {extraHour.wedeliveryBenefitVat}</Typography>
                </Box>
                <IconButton onClick={() => handleEdit('extraHour', extraHour)}><EditIcon /></IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No hay horas extras registradas.</Typography>
          )}
        </Paper>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button variant="outlined" onClick={onClose}>Cerrar</Button>
          <Button variant="contained" color="primary" onClick={handleFinalizeService}>Finalizar Servicio</Button>
        </Box>

        {/* Modales de edición */}
        {modalType === 'service' && (
          <EditServicesModal
            open={!!editModalData}
            onClose={handleCloseEditModal}
            service={editModalData}
            setServices={() => {}}
          />
        )}
        {modalType === 'trade' && (
          <EditTradeModal
            open={!!editModalData}
            onClose={handleCloseEditModal}
            trade={editModalData}
          />
        )}
        {modalType === 'driverService' && (
          <EditDriverModal
            open={!!editModalData}
            onClose={handleCloseEditModal}
            driverService={editModalData}
          />
        )}
        {modalType === 'expense' && (
          <EditExpensesModal
            open={!!editModalData}
            onClose={handleCloseEditModal}
            expense={editModalData}
          />
        )}
        {modalType === 'image' && (
          <EditImageModal
            open={!!editModalData}
            onClose={handleCloseEditModal}
            image={editModalData}
          />
        )}
        {modalType === 'extraHour' && (
          <EditExtraHourModal
            open={!!editModalData}
            onClose={handleCloseEditModal}
            extraHour={editModalData}
          />
        )}
      </Box>
    </Modal>
  );
};

export default PendingReviewModal;
