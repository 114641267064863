import React, { useState, useEffect } from 'react';
import {
  Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions,
  DialogContent, DialogTitle, TextField, Select, MenuItem, FormControl, InputLabel,
  Container, Typography, Paper, IconButton
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { fetchRateSegments, createRateSegment, updateRateSegment, deleteRateSegment } from '../services/api/rateSegmentApi';
import { fetchAllRates } from '../services/api/rateApi';

const RateSegmentForm = ({ open, onClose, segment, rates, onSave }) => {
  const [formData, setFormData] = useState({
    idRateSegment: null,
    idRate: '',
    minKm: '',
    maxKm: '',
    price: '',
  });

  useEffect(() => {
    if (segment) {
      setFormData(segment);
    } else {
      setFormData({
        idRateSegment: null,
        idRate: '',
        minKm: '',
        maxKm: '',
        price: '',
      });
    }
  }, [segment]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (formData.idRateSegment) {
      await updateRateSegment(formData.idRateSegment, formData);
    } else {
      await createRateSegment(formData);
    }
    onSave();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{formData.idRateSegment ? 'Editar Segmento de Tarifa' : 'Crear Nuevo Segmento'}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="rate-label">Tarifa</InputLabel>
          <Select
            labelId="rate-label"
            name="idRate"
            value={formData.idRate}
            onChange={handleChange}
          >
            {rates.map((rate) => (
              <MenuItem key={rate.idRate} value={rate.idRate}>
                {rate.rateType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          name="minKm"
          label="Min Km"
          type="number"
          fullWidth
          value={formData.minKm}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="maxKm"
          label="Max Km"
          type="number"
          fullWidth
          value={formData.maxKm}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="price"
          label="Precio"
          type="number"
          fullWidth
          value={formData.price}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

const RateSegments = () => {
  const [segments, setSegments] = useState([]);
  const [rates, setRates] = useState([]);
  const [editingSegment, setEditingSegment] = useState(null);
  const [segmentFormOpen, setSegmentFormOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const segmentsData = await fetchRateSegments();
      const ratesData = await fetchAllRates();
      setSegments(segmentsData);
      setRates(ratesData);
    };

    fetchData();
  }, []);

  const handleEditSegment = (segment) => {
    setEditingSegment(segment);
    setSegmentFormOpen(true);
  };

  const handleDeleteSegment = async (idRateSegment) => {
    await deleteRateSegment(idRateSegment);
    setSegments(segments.filter((segment) => segment.idRateSegment !== idRateSegment));
  };

  const handleSaveSegment = async () => {
    const segmentsData = await fetchRateSegments();
    setSegments(segmentsData);
    setSegmentFormOpen(false);
  };

  const handleNewSegment = () => {
    setEditingSegment(null);
    setSegmentFormOpen(true);
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gestión de Segmentos de Tarifas
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleNewSegment}
          style={{ marginBottom: '20px' }}
        >
          Crear Nuevo Segmento
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tarifa</TableCell>
              <TableCell>Min Km</TableCell>
              <TableCell>Max Km</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segments.map((segment) => (
              <TableRow key={segment.idRateSegment}>
                <TableCell>{segment.idRateSegment}</TableCell>
                <TableCell>{rates.find(rate => rate.idRate === segment.idRate)?.rateType}</TableCell>
                <TableCell>{segment.minKm}</TableCell>
                <TableCell>{segment.maxKm}</TableCell>
                <TableCell>{segment.price}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditSegment(segment)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteSegment(segment.idRateSegment)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <RateSegmentForm
        open={segmentFormOpen}
        onClose={() => setSegmentFormOpen(false)}
        segment={editingSegment}
        rates={rates}
        onSave={handleSaveSegment}
      />
    </Container>
  );
};

export default RateSegments;
