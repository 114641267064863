import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography } from '@mui/material';
import { fetchServicesByStatus, updateServiceStatus } from '../services/api/serviceApi';
import AssignModal from './AssignModal';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ServicesPending = () => {
  const [pendingServices, setPendingServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchPendingServices = async () => {
      try {
        const services = await fetchServicesByStatus('PENDING');

        let filteredServices = services;
        if (user.roleId === 1) { // Rol Entidad
          filteredServices = services.filter(service => service.clientId === user.idUser);
        } else if (user.roleId === 2) { // Rol Oficina Entidad
          filteredServices = services.filter(service => service.clientId === user.idEntity);
        }

        setPendingServices(filteredServices);
      } catch (error) {
        console.error('Error fetching pending services:', error);
      }
    };

    fetchPendingServices();
  }, [user]);

  const handleOpenAssignModal = (service) => {
    setSelectedService(service);
    setAssignModalOpen(true);
  };

  const handleCloseAssignModal = () => {
    setAssignModalOpen(false);
    setSelectedService(null);
    refreshPendingServices();
  };

  const handleReject = async (serviceId) => {
    try {
      await updateServiceStatus(serviceId, 'REJECTED');
      refreshPendingServices();
    } catch (error) {
      console.error('Error rejecting service:', error);
    }
  };

  const refreshPendingServices = async () => {
    try {
      const updatedServices = await fetchServicesByStatus('PENDING');

      let filteredServices = updatedServices;
      if (user.roleId === 1) { // Rol Entidad
        filteredServices = updatedServices.filter(service => service.clientId === user.idUser);
      } else if (user.roleId === 2) { // Rol Oficina Entidad
        filteredServices = updatedServices.filter(service => service.clientId === user.idEntity);
      }

      setPendingServices(filteredServices);
    } catch (error) {
      console.error('Error refreshing pending services:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    const [hours, minutes, seconds] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  };

  return (
    <div className="container">
      <Typography variant="h4" align="center" gutterBottom>
        Servicios Pendientes
      </Typography>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>Fecha del Servicio</TableCell>
              <TableCell>Hora del Servicio</TableCell>
              <TableCell>Matrícula del Vehículo</TableCell>
              <TableCell>Marca del Vehículo</TableCell>
              <TableCell>Modelo del Vehículo</TableCell>
              <TableCell>Color del Vehículo</TableCell>
              <TableCell>Fecha de Recepción</TableCell>
              <TableCell>Dirección de Recepción</TableCell>
              <TableCell>Dirección de Entrega</TableCell>
              <TableCell>Nombre del Receptor</TableCell>
              <TableCell>NIF del Receptor</TableCell>
              <TableCell>Precio Base</TableCell>
              <TableCell>Kilometraje Inicial</TableCell>
              <TableCell>Kilometraje Final</TableCell>
              <TableCell>Kilometraje Total</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Estado del Vehículo</TableCell>
              <TableCell>Creado en</TableCell>
              <TableCell>Actualizado en</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Comentarios Recepción</TableCell>
              <TableCell>Comentarios Entrega</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingServices.map((service) => (
              <TableRow key={service.idService} className="table-row">
                <TableCell>{formatDate(service.serviceDate)}</TableCell>
                <TableCell>{formatTime(service.serviceTime)}</TableCell>
                <TableCell>{service.vehiclePlate}</TableCell>
                <TableCell>{service.vehicleBrand}</TableCell>
                <TableCell>{service.vehicleModel}</TableCell>
                <TableCell>{service.vehicleColor}</TableCell>
                <TableCell>{formatDate(service.receptionDate)}</TableCell>
                <TableCell>{service.receptionAddress}</TableCell>
                <TableCell>{service.deliveryAddress}</TableCell>
                <TableCell>{service.recipientName}</TableCell>
                <TableCell>{service.recipientNif}</TableCell>
                <TableCell>{service.basePrice.toFixed(2)}</TableCell>
                <TableCell>{service.initialKm}</TableCell>
                <TableCell>{service.finalKm}</TableCell>
                <TableCell>{service.totalKm}</TableCell>
                <TableCell>{service.serviceStatus}</TableCell>
                <TableCell>{service.vehicleStatus}</TableCell>
                <TableCell>{formatDate(service.createdAt)}</TableCell>
                <TableCell>{formatDate(service.updatedAt)}</TableCell>
                <TableCell>{service.vatPrice.toFixed(2)}</TableCell>
                <TableCell>{service.reEntityComments}</TableCell>
                <TableCell>{service.eentityComments}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenAssignModal(service)} aria-label="Asignar">
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleReject(service.idService)} aria-label="Rechazar">
                    <CancelIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedService && (
        <AssignModal
          open={assignModalOpen}
          onClose={handleCloseAssignModal}
          service={selectedService}
        />
      )}
    </div>
  );
};

export default ServicesPending;
