import React, { useEffect, useState } from 'react';
import { fetchRoleById } from '../services/api/roleApi'; 
import { useSelector } from 'react-redux';
import '../styles/components.scss'; 

const SidebarWelcome = () => {
  const user = useSelector((state) => state.auth.user);
  const [roleName, setRoleName] = useState('');

  useEffect(() => {
    const loadRoleName = async () => {
      try {
        // Si ya tenemos el roleId, intentar buscar el nombre del rol
        if (user && user.roleId) {
          const role = await fetchRoleById(user.roleId);
          setRoleName(role?.name || 'Rol desconocido');
        }
      } catch (error) {
        console.error('Error fetching role name:', error);
        setRoleName('Rol no disponible'); // Mostrar un mensaje si hay un error
      }
    };

    // Solo cargar si no tenemos un nombre de rol previamente
    if (user && !roleName) {
      loadRoleName();
    }
  }, [user, roleName]);

  if (!user) {
    return null; // Si no hay usuario, no mostrar nada
  }

  return (
    <div className="sidebar-welcome">
      <h2>{`Bienvenido, ${user.firstName} ${user.lastName || ''}`}</h2>
      <p>{`Rol: ${roleName}`}</p>
    </div>
  );
};

export default SidebarWelcome;
