import React, { useEffect, useState } from 'react';
import { getLastLocationForService } from '../services/api/driverLocationApi';
import { fetchServicesByStatus } from '../services/api/serviceApi';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Container, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../styles/pages.scss';

const mapContainerStyle = {
  height: "400px",
  width: "100%",
  margin: "0 auto",
  zIndex: 0,
};

const defaultCenter = [0, 0];

const DriversLocations = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [latestLocation, setLatestLocation] = useState(null);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchServicesByStatus('IN_PROGRESS');

        let filteredServices = response;

        if (user.roleId === 1) {
          filteredServices = response.filter(service => service.clientId === user.idUser);
        } else if (user.roleId === 2) {
          filteredServices = response.filter(service => service.clientId === user.idEntity);
        }

        setServices(filteredServices);
      } catch (error) {
        console.error('Error fetching services in progress:', error);
      }
    };

    loadServices();
  }, [user]);

  const handleServiceClick = async (service) => {
    setSelectedService(service);
    try {
      const location = await getLastLocationForService(service.idService);
      setLatestLocation(location);
    } catch (error) {
      console.error('Error fetching latest driver location:', error);
    }
  };

  useEffect(() => {
    if (selectedService) {
      const interval = setInterval(async () => {
        try {
          const location = await getLastLocationForService(selectedService.idService);
          setLatestLocation(location);
        } catch (error) {
          console.error('Error fetching latest driver location:', error);
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [selectedService]);

  const markerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41],
    iconSize: [25, 41],
    className: 'leaflet-div-icon'
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
      <Typography variant="h4" gutterBottom align="center">
        Localizaciones GPS de los Servicios
      </Typography>
      <Box className="mapContainer" sx={{ marginBottom: '40px', width: '100%' }}>
        <MapContainer
          style={mapContainerStyle}
          center={latestLocation ? [latestLocation.latitude, latestLocation.longitude] : defaultCenter}
          zoom={10}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {latestLocation && (
            <Marker
              position={[latestLocation.latitude, latestLocation.longitude]}
              icon={markerIcon}
            />
          )}
        </MapContainer>
      </Box>
      <Box className="servicesContainer" sx={{ width: '100%' }}>
        <Typography variant="h6" align="center" gutterBottom>
          Servicios en Progreso
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell><strong>ID del Servicio</strong></TableCell>
                <TableCell><strong>Fecha del Servicio</strong></TableCell>
                <TableCell><strong>Hora del Servicio</strong></TableCell>
                <TableCell><strong>Matrícula del Vehículo</strong></TableCell>
                <TableCell><strong>Acciones</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((service) => (
                <TableRow key={service.idService}>
                  <TableCell>{service.idService}</TableCell>
                  <TableCell>{service.serviceDate}</TableCell>
                  <TableCell>{service.serviceTime}</TableCell>
                  <TableCell>{service.vehiclePlate}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleServiceClick(service)}
                    >
                      Ver Localización
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DriversLocations;
