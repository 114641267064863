import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Button, TextField, Select, MenuItem, FormControl, InputLabel, Grid,
  Typography
} from '@mui/material';
import { fetchAllServices } from '../services/api/serviceApi';
import { fetchUsersByRoleId } from '../services/api/userApi';
import { saveExpense } from '../services/api/expenseApi';

const MAX_FILE_SIZE_MB = 10; // Tamaño máximo en MB

const CreateExpensesModal = ({ open, onClose, onExpenseCreated }) => {
  const [formData, setFormData] = useState({
    serviceId: '',
    userId: '',
    expenseType: '',
    amountBase: '',
    taxType: '',
    description: '',
  });
  const [services, setServices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileError, setFileError] = useState(null); // Para manejar el error de tamaño de archivo

  useEffect(() => {
    const fetchData = async () => {
      const [servicesData, driversData] = await Promise.all([
        fetchAllServices(),
        fetchUsersByRoleId(4)
      ]);
      setServices(servicesData);
      setDrivers(driversData);
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        setFileError(`El archivo supera el tamaño máximo de ${MAX_FILE_SIZE_MB} MB`);
        setImageFile(null);
        setPreviewImage(null);
      } else {
        setFileError(null);
        setImageFile(file);
        if (file instanceof Blob) {
          const previewURL = URL.createObjectURL(file);
          setPreviewImage(previewURL);
        } else {
          console.error('El archivo seleccionado no es válido.');
          setPreviewImage(null);
        }
      }
    } else {
      setImageFile(null);
      setPreviewImage(null);
    }
  };

  const handleSubmit = async () => {
    if (!formData.serviceId) {
      console.error('Service ID es obligatorio.');
      return;
    }

    if (!formData.userId) {
      console.error('User ID es obligatorio.');
      return;
    }

    if (!formData.expenseType) {
      console.error('Tipo de gasto es obligatorio.');
      return;
    }

    if (!formData.amountBase) {
      console.error('Monto base es obligatorio.');
      return;
    }

    if (fileError) {
      console.error('El archivo seleccionado no es válido.');
      return;
    }

    try {
      const newExpense = await saveExpense(formData, imageFile);
      onExpenseCreated(newExpense);
      onClose();
    } catch (error) {
      console.error('Error creando el gasto:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crear Nuevo Gasto</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Servicio</InputLabel>
              <Select
                name="serviceId"
                value={formData.serviceId}
                onChange={handleInputChange}
              >
                {services.map(service => (
                  <MenuItem key={service.idService} value={service.idService}>
                    {`${service.vehiclePlate} - ${new Date(service.serviceDate).toLocaleDateString()}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Conductor</InputLabel>
              <Select
                name="userId"
                value={formData.userId}
                onChange={handleInputChange}
              >
                {drivers.map(driver => (
                  <MenuItem key={driver.idUser} value={driver.idUser}>
                    {`${driver.firstName} ${driver.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Tipo de Gasto</InputLabel>
              <Select
                name="expenseType"
                value={formData.expenseType}
                onChange={handleInputChange}
              >
                <MenuItem value="FUEL">Combustible</MenuItem>
                <MenuItem value="CLEANING">Limpieza</MenuItem>
                <MenuItem value="OTHER">Otro</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="amountBase"
              label="Monto Base"
              type="number"
              fullWidth
              value={formData.amountBase}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Tipo de Impuesto</InputLabel>
              <Select
                name="taxType"
                value={formData.taxType}
                onChange={handleInputChange}
              >
                <MenuItem value="TEN_PERCENT">10%</MenuItem>
                <MenuItem value="TWENTY_ONE_PERCENT">21%</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="description"
              label="Descripción"
              fullWidth
              multiline
              rows={3}
              value={formData.description}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" component="label" fullWidth>
              Subir Imagen
              <input type="file" hidden onChange={handleImageChange} />
            </Button>
            {fileError && (
              <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                {fileError}
              </Typography>
            )}
            {previewImage && (
              <img src={previewImage} alt="Preview" style={{ marginTop: '10px', width: '100%' }} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary" disabled={!!fileError}>Crear Gasto</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateExpensesModal;
