import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Edit, Delete, Image as ImageIcon } from '@mui/icons-material';
import { fetchAllServices } from '../services/api/serviceApi';
import { fetchImagesByServiceId, fetchAllImages, deleteImageById, downloadImageById } from '../services/api/imageServiceApi';
import EditImageModal from './EditImageModal';
import CreateImageModal from './CreateImageModal';

const ImagePreviewModal = ({ open, onClose, imageUrl }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Previsualización de Imagen</DialogTitle>
    <DialogContent>
      {imageUrl ? (
        <img src={imageUrl} alt="" style={{ width: '100%', height: 'auto' }} />
      ) : (
        <p>No se pudo cargar la imagen.</p>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="contained">Cerrar</Button>
    </DialogActions>
  </Dialog>
);

const ServicesImages = () => {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [images, setImages] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false); // Nuevo estado para el modal de creación
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchAllServices();
        setServices(response);
      } catch (error) {
        console.error('Error al cargar los servicios:', error);
      }
    };

    const loadImages = async () => {
      try {
        const allImages = await fetchAllImages();
        setImages(allImages);
      } catch (error) {
        console.error('Error al cargar las imágenes:', error);
      }
    };

    loadServices();
    loadImages();
  }, []);

  const handleServiceChange = (event) => {
    setSelectedServiceId(event.target.value);
  };

  const applyFilters = async () => {
    try {
      if (selectedServiceId) {
        console.log("Selected Service ID: ", selectedServiceId); // Log to check if serviceId is correct
        const imagesData = await fetchImagesByServiceId(selectedServiceId);
        setImages(imagesData);
      } else {
        const allImages = await fetchAllImages();
        setImages(allImages);
      }
    } catch (error) {
      console.error('Error al aplicar filtros:', error);
    }
  };

  const handleEditImage = (image) => {
    setSelectedImage(image);
    setShowEditModal(true);
  };

  const handleDeleteImage = async (imageId) => {
    try {
      await deleteImageById(imageId);
      applyFilters(); // Actualizar la lista de imágenes después de eliminar
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };

  const handleViewImage = async (idImage) => {
    try {
      const response = await downloadImageById(idImage);
      const imageUrl = URL.createObjectURL(new Blob([response], { type: 'image/jpeg' }));
      setPreviewImageUrl(imageUrl);
      setImageModalOpen(true);
    } catch (error) {
      console.error('Error al descargar la imagen', error);
    }
  };

  // Cerrar modal de edición y recargar las imágenes
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    applyFilters(); // Recargar las imágenes al cerrar el modal de edición
  };

  // Cerrar modal de creación y recargar las imágenes
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    applyFilters(); // Recargar las imágenes al cerrar el modal de creación
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h4" align="center" gutterBottom>
          Gestión de Imágenes de Servicios
        </Typography>
      </Box>

      <Grid container spacing={2} alignItems="center" mb={4}>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth>
            <InputLabel id="select-service-label">Seleccionar Servicio</InputLabel>
            <Select
              labelId="select-service-label"
              value={selectedServiceId}
              onChange={handleServiceChange}
            >
              <MenuItem value="">
                <em>Todos los Servicios</em>
              </MenuItem>
              {services.map(service => (
                <MenuItem key={service.idService} value={service.idService}>
                  {`ID: ${service.idService} - Matrícula: ${service.vehiclePlate} - Fecha: ${new Date(service.createdAt).toLocaleDateString()}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" color="primary" onClick={applyFilters} fullWidth>
            Aplicar Filtros
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={() => setShowCreateModal(true)} fullWidth>
            Añadir Nueva Imagen
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo de Imagen</TableCell>
              <TableCell>Imagen</TableCell>
              <TableCell>Fecha de Creación</TableCell>
              <TableCell>Última Actualización</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {images.map((image) => (
              <TableRow key={image.idImage}>
                <TableCell>{image.imageType}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleViewImage(image.idImage)}>
                    <ImageIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{new Date(image.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(image.updatedAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditImage(image)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteImage(image.idImage)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedImage && (
        <EditImageModal
          open={showEditModal}
          onClose={handleCloseEditModal} // Cierra el modal de edición y recarga las imágenes
          image={selectedImage}
          setImages={setImages}
        />
      )}

      <CreateImageModal
        open={showCreateModal}
        onClose={handleCloseCreateModal} // Cierra el modal de creación y recarga las imágenes
        setImages={setImages}
      />

      <ImagePreviewModal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        imageUrl={previewImageUrl}
      />
    </Container>
  );
};

export default ServicesImages;
