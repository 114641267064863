import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Grid,
  Button,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { downloadImageById, updateImageService } from '../services/api/imageServiceApi';

const MAX_FILE_SIZE_MB = 10; // Tamaño máximo permitido para la imagen

const EditImageModal = ({ open, onClose, image, setImages }) => {
  const [editedImageType, setEditedImageType] = useState(image.imageType || '');
  const [imageFile, setImageFile] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(null); // Estado para manejar errores de archivo

  useEffect(() => {
    if (image) {
      setEditedImageType(image.imageType);
      fetchPreviewImage(image.idImage);
    }
  }, [image]);

  const fetchPreviewImage = async (idImage) => {
    setLoading(true);
    try {
      const response = await downloadImageById(idImage);
      const imageUrl = URL.createObjectURL(new Blob([response], { type: 'image/jpeg' }));
      setPreviewImageUrl(imageUrl);
    } catch (error) {
      console.error('Error fetching image preview:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Validar el tamaño del archivo
    if (file) {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        setFileError(`El archivo supera el tamaño máximo de ${MAX_FILE_SIZE_MB} MB.`);
        setImageFile(null);
        setPreviewImageUrl(null);
      } else {
        setFileError(null);
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImageFile(null);
      setPreviewImageUrl(null);
    }
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('imageType', editedImageType);
      if (imageFile) {
        formData.append('file', imageFile);
      }

      const updatedImage = await updateImageService(image.idImage, formData);

      setImages((prevImages) =>
        prevImages.map((img) =>
          img.idImage === updatedImage.idImage ? updatedImage : img
        )
      );

      onClose();
    } catch (error) {
      console.error('Error saving image changes:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Imagen del Servicio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Tipo de Imagen"
                value={editedImageType}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              fullWidth
              disabled={loading}
            >
              Subir Nueva Imagen
              <input
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </Button>
            {fileError && (
              <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                {fileError}
              </Typography>
            )}
            {previewImageUrl && (
              <Box mt={2}>
                <img
                  src={previewImageUrl}
                  alt="Preview"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleSaveChanges} color="primary" disabled={loading || !!fileError}>
          {loading ? <CircularProgress size={24} /> : 'Guardar Cambios'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditImageModal;
