import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PolicyIcon from '@mui/icons-material/Policy';
import PolicyIconOn from '@mui/icons-material/PolicyOutlined';
import PolicyIconOff from '@mui/icons-material/PolicyTwoTone';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/authSlice';
import '../../styles/components.scss';
import SidebarWelcome from '../SidebarWelcome'; 

const SidebarOficinaEntidad = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/');
  };

  const [openServices, setOpenServices] = useState(false);
  const [openPolicies, setOpenPolicies] = useState(false);

  const handleClickServices = () => {
    setOpenServices(!openServices);
  };

  const handleClickPolicies = () => {
    setOpenPolicies(!openPolicies);
  };

  return (
    <div className="sidebar">
      <SidebarWelcome />
      <List>
        {/* Submenú Servicios */}
        <ListItem button onClick={handleClickServices}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Servicios" />
          {openServices ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openServices} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/oficina-entidad/create-services')} sx={{ pl: 4 }}>
              <ListItemIcon><AssignmentIcon /></ListItemIcon>
              <ListItemText primary="Crear Servicios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/oficina-entidad/services')} sx={{ pl: 4 }}>
              <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
              <ListItemText primary="Servicios" />
            </ListItem>
          </List>
        </Collapse>

        {/* Submenú Políticas */}
        <ListItem button onClick={handleClickPolicies}>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
          <ListItemText primary="Políticas" />
          {openPolicies ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openPolicies} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/oficina-entidad/cookie-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIcon /></ListItemIcon>
              <ListItemText primary="Política de Cookies" />
            </ListItem>
            <ListItem button onClick={() => navigate('/oficina-entidad/legal-notice')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOn /></ListItemIcon>
              <ListItemText primary="Aviso Legal" />
            </ListItem>
            <ListItem button onClick={() => navigate('/oficina-entidad/privacy-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOff /></ListItemIcon>
              <ListItemText primary="Política de Privacidad" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => navigate('/oficina-entidad/reports')}>
          <ListItemIcon><AssignmentIcon /></ListItemIcon>
          <ListItemText primary="Informes" />
        </ListItem>

        <ListItem button onClick={() => navigate('/oficina-entidad/drivers-locations')}>
          <ListItemIcon><LocationOnIcon /></ListItemIcon>
          <ListItemText primary="Ubicaciones de Conductores" />
        </ListItem>

        <ListItem button onClick={() => navigate('/oficina-entidad/my-rates')}>
          <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
          <ListItemText primary="Mis Tarifas" />
        </ListItem>

        <ListItem button onClick={() => navigate('/oficina-entidad/profile')}>
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary="Perfil" />
        </ListItem>

        <ListItem button onClick={handleLogout}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarOficinaEntidad;
