import api from './apiConfig.jsx';

/**
 * Genera un informe basado en el filtro proporcionado.
 * @param {Object} reportFilter - Filtros y parámetros utilizados para generar el informe.
 * @returns {Promise<Blob>} - La respuesta del servidor con el informe en formato PDF (blob).
 */
export const generateReport = async (reportFilter) => {
  try {
    const response = await api.post('/reports/generate', reportFilter, {
      responseType: 'blob', // Para recibir el PDF como un blob
      headers: {
        'Accept': 'application/pdf', // Asegúrate de que el servidor sepa que esperas un PDF
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al generar el informe:', error);
    throw error;
  }
};
