import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/authSlice';
import '../../styles/components.scss';
import PolicyIcon from '@mui/icons-material/Policy';
import PolicyIconOn from '@mui/icons-material/PolicyOutlined';
import PolicyIconOff from '@mui/icons-material/PolicyTwoTone';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SidebarWelcome from '../SidebarWelcome'; 

const SidebarConductor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/');
  };

  const handleDownloadApp = () => {
    window.open('https://mega.nz/your-app-link', '_blank'); // Reemplaza con tu enlace de Mega
  };

  const [openPolicies, setOpenPolicies] = useState(false);

  const handleClickPolicies = () => {
    setOpenPolicies(!openPolicies);
  };

  return (
    <div className="sidebar">
      <SidebarWelcome />
      <List>
        <ListItem button onClick={() => navigate('/conductor/profile')}>
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <ListItemText primary="Perfil" />
        </ListItem>

        <ListItem button onClick={() => navigate('/conductor/reports')}>
          <ListItemIcon><ReceiptIcon /></ListItemIcon>
          <ListItemText primary="Mis Informes" />
        </ListItem>

        <ListItem button onClick={handleDownloadApp}>
          <ListItemIcon><DownloadIcon /></ListItemIcon>
          <ListItemText primary="Descargar App" />
        </ListItem>

        <ListItem button onClick={handleClickPolicies}>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
          <ListItemText primary="Políticas" />
          {openPolicies ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openPolicies} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/conductor/cookie-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIcon /></ListItemIcon>
              <ListItemText primary="Política de Cookies" />
            </ListItem>
            <ListItem button onClick={() => navigate('/conductor/legal-notice')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOn /></ListItemIcon>
              <ListItemText primary="Aviso Legal" />
            </ListItem>
            <ListItem button onClick={() => navigate('/conductor/privacy-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOff /></ListItemIcon>
              <ListItemText primary="Política de Privacidad" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleLogout}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarConductor;
