import api from './apiConfig';

/**
 * Obtener todos los usuarios.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todos los usuarios.
 */
export const fetchAllUsers = async () => {
    try {
        const response = await api.get('/users');
        return response.data;
    } catch (error) {
        console.error('Error al obtener todos los usuarios:', error);
        throw error;
    }
};

/**
 * Obtener un usuario por ID.
 * @param {Number|String} id - ID del usuario.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del usuario.
 */
export const fetchUserById = async (id) => {
    try {
        const response = await api.get(`/users/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error al obtener el usuario con ID ${id}:`, error);
        throw error;
    }
};

/**
 * Obtener usuarios por ID de rol.
 * @param {Number|String} roleId - ID del rol.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de usuarios asociados al rol.
 */
export const fetchUsersByRoleId = async (roleId) => {
    try {
        const response = await api.get(`/users/role/${roleId}`);
        return response.data;
    } catch (error) {
        console.error(`Error al obtener los usuarios con el rol ID ${roleId}:`, error);
        throw error;
    }
};

/**
 * Obtener usuarios por ID de entidad.
 * @param {Number|String} idEntity - ID de la entidad.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de usuarios asociados a la entidad.
 */
export const fetchUsersByEntityId = async (idEntity) => {
    try {
        const response = await api.get(`/users/entity/${idEntity}`);
        return response.data;
    } catch (error) {
        console.error(`Error al obtener los usuarios con la entidad ID ${idEntity}:`, error);
        throw error;
    }
};

/**
 * Obtener usuarios pendientes.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de usuarios pendientes.
 */
export const fetchPendingUsers = async () => {
    try {
        const response = await api.get('/users/pending');
        return response.data;
    } catch (error) {
        console.error('Error al obtener los usuarios pendientes:', error);
        throw error;
    }
};

/**
 * Obtener usuarios inactivos.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de usuarios inactivos.
 */
export const fetchInactiveUsers = async () => {
    try {
        const response = await api.get('/users/inactive');
        return response.data;
    } catch (error) {
        console.error('Error al obtener los usuarios inactivos:', error);
        throw error;
    }
};

/**
 * Activar un usuario por ID.
 * @param {Number|String} id - ID del usuario a activar.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del usuario activado.
 */
export const activateUser = async (id) => {
    try {
        const response = await api.put(`/users/activate/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error al activar el usuario con ID ${id}:`, error);
        throw error;
    }
};

/**
 * Confirmar un usuario pendiente por ID.
 * @param {Number|String} id - ID del usuario a confirmar.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del usuario confirmado.
 */
export const confirmUser = async (id) => {
    try {
        const response = await api.put(`/users/confirm/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error al confirmar el usuario con ID ${id}:`, error);
        throw error;
    }
};

/**
 * Guardar un nuevo usuario.
 * @param {Object} userData - Datos del usuario a guardar.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del usuario guardado.
 */
export const saveUser = async (userData) => {
    try {
        const response = await api.post('/users', userData);
        return response.data;
    } catch (error) {
        console.error('Error al guardar el usuario:', error);
        throw error;
    }
};

/**
 * Eliminar un usuario por ID.
 * @param {Number|String} id - ID del usuario a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación del usuario.
 */
export const deleteUserById = async (id) => {
    try {
        await api.delete(`/users/${id}`);
    } catch (error) {
        console.error(`Error al eliminar el usuario con ID ${id}:`, error);
        throw error;
    }
};

/**
 * Obtener el perfil del usuario autenticado.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del perfil del usuario autenticado.
 */
export const fetchUserProfile = async () => {
    try {
        const response = await api.get('/users/profile');
        return response.data;
    } catch (error) {
        console.error('Error al obtener el perfil del usuario:', error);
        throw error;
    }
};

/**
 * Actualizar el perfil de un usuario.
 * @param {Number|String} id - ID del usuario a actualizar.
 * @param {Object} userDetails - Detalles actualizados del usuario.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del usuario actualizado.
 */
export const updateUserProfile = async (id, userDetails) => {
    try {
        const response = await api.put(`/users/${id}`, userDetails);
        return response.data;
    } catch (error) {
        console.error(`Error al actualizar el perfil del usuario con ID ${id}:`, error);
        throw error;
    }
};
