import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Button,
  CircularProgress,
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem
} from '@mui/material';
import { fetchAllServices } from '../services/api/serviceApi'; 
import { createTrade } from '../services/api/tradeApi'; 

const CreateTradeModal = ({ open, onClose }) => {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [tradeVehiclePlate, setTradeVehiclePlate] = useState('');
  const [tradeVehicleBrand, setTradeVehicleBrand] = useState('');
  const [tradeVehicleModel, setTradeVehicleModel] = useState('');
  const [tradeVehicleColor, setTradeVehicleColor] = useState('');
  const [tradeVehicleYear, setTradeVehicleYear] = useState('');
  const [deliveryAddressTrade, setDeliveryAddressTrade] = useState('');
  const [recipientNameTrade, setRecipientNameTrade] = useState('');
  const [recipientNifTrade, setRecipientNifTrade] = useState('');
  const [initialKmTrade, setInitialKmTrade] = useState('');
  const [finalKmTrade, setFinalKmTrade] = useState(''); 
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchAllServices();
        setServices(response);
      } catch (error) {
        console.error('Error al cargar los servicios:', error);
      }
    };

    loadServices();
  }, []);

  // Este efecto se ejecuta cada vez que `open` cambia
  useEffect(() => {
    if (open) {
      // Restablecer todos los campos cuando el modal se abra
      setTradeVehiclePlate('');
      setTradeVehicleBrand('');
      setTradeVehicleModel('');
      setTradeVehicleColor('');
      setTradeVehicleYear('');
      setDeliveryAddressTrade('');
      setRecipientNameTrade('');
      setRecipientNifTrade('');
      setInitialKmTrade('');
      setFinalKmTrade('');
      setSelectedServiceId('');
    }
  }, [open]);

  const handleSaveTrade = async () => {
    if (
      !tradeVehiclePlate ||
      !tradeVehicleBrand ||
      !tradeVehicleModel ||
      !tradeVehicleColor ||
      !tradeVehicleYear ||
      !selectedServiceId
    ) {
      alert('Por favor, complete todos los campos requeridos.');
      return;
    }

    setLoading(true);
    try {
      const newTrade = {
        tradeVehiclePlate,
        tradeVehicleBrand,
        tradeVehicleModel,
        tradeVehicleColor,
        tradeVehicleYear,
        deliveryAddressTrade,
        recipientNameTrade,
        recipientNifTrade,
        initialKmTrade: initialKmTrade || 0,
        finalKmTrade: finalKmTrade || 0,
        idService: selectedServiceId,
      };

      await createTrade(newTrade);
      onClose(); // Cierra el modal después de crear el trade
    } catch (error) {
      console.error('Error al crear el trade:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Añadir Nuevo Vehículo de Intercambio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Matrícula del Vehículo"
              fullWidth
              value={tradeVehiclePlate}
              onChange={(e) => setTradeVehiclePlate(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Marca del Vehículo"
              fullWidth
              value={tradeVehicleBrand}
              onChange={(e) => setTradeVehicleBrand(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Modelo del Vehículo"
              fullWidth
              value={tradeVehicleModel}
              onChange={(e) => setTradeVehicleModel(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Color del Vehículo"
              fullWidth
              value={tradeVehicleColor}
              onChange={(e) => setTradeVehicleColor(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Año del Vehículo"
              fullWidth
              type="number"
              value={tradeVehicleYear}
              onChange={(e) => setTradeVehicleYear(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Dirección de Entrega"
              fullWidth
              value={deliveryAddressTrade}
              onChange={(e) => setDeliveryAddressTrade(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Nombre del Receptor"
              fullWidth
              value={recipientNameTrade}
              onChange={(e) => setRecipientNameTrade(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="NIF del Receptor"
              fullWidth
              value={recipientNifTrade}
              onChange={(e) => setRecipientNifTrade(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Kilometraje Inicial"
              fullWidth
              type="number"
              value={initialKmTrade}
              onChange={(e) => setInitialKmTrade(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Kilometraje Final"
              fullWidth
              type="number"
              value={finalKmTrade}
              onChange={(e) => setFinalKmTrade(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-service-label">Seleccionar Servicio</InputLabel>
              <Select
                labelId="select-service-label"
                value={selectedServiceId}
                onChange={(e) => setSelectedServiceId(e.target.value)}
                required
              >
                {services.map((service) => (
                  <MenuItem key={service.idService} value={service.idService}>
                    {`ID: ${service.idService} - Matrícula: ${service.vehiclePlate}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleSaveTrade} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Guardar Vehículo'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTradeModal;
