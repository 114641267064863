import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import '../styles/pages.scss';

const LegalNotice = () => {
  return (
    <Container className="custom-legal-notice-container">
      <Paper elevation={3} className="custom-legal-notice-paper">
        <Box padding={4} className="custom-legal-notice-box">
          <Typography variant="h4" gutterBottom className="custom-legal-notice-title">
            Aviso Legal
          </Typography>

          <Typography variant="h6" gutterBottom className="custom-legal-notice-section-title">
            1. Información General
          </Typography>
          <Typography paragraph className="custom-legal-notice-text">
            El presente aviso legal regula el uso del sitio web WeDelivery, propiedad de WeDelivery S.A.
            (en adelante, "la Empresa").
          </Typography>

          <Typography variant="h6" gutterBottom className="custom-legal-notice-section-title">
            2. Condiciones de Uso
          </Typography>
          <Typography paragraph className="custom-legal-notice-text">
            La utilización del sitio web de WeDelivery le atribuye la condición de Usuario del sitio web
            e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas
            en este Aviso Legal en la versión publicada por la Empresa en el momento en el que el Usuario
            acceda al sitio web. En consecuencia, el Usuario debe leer atentamente el presente Aviso Legal en
            cada una de las ocasiones en que se proponga utilizar el sitio web, ya que puede sufrir
            modificaciones.
          </Typography>

          <Typography variant="h6" gutterBottom className="custom-legal-notice-section-title">
            3. Propiedad Intelectual
          </Typography>
          <Typography paragraph className="custom-legal-notice-text">
            Todos los contenidos del sitio web, salvo que se indique lo contrario, son titularidad exclusiva
            de la Empresa o de terceros que han autorizado su uso en la plataforma, y con carácter enunciativo,
            pero no limitativo, el diseño gráfico, código fuente, logos, textos, gráficos, ilustraciones,
            fotografías, y otros elementos que aparecen en el sitio web.
          </Typography>

          <Typography variant="h6" gutterBottom className="custom-legal-notice-section-title">
            4. Protección de Datos
          </Typography>
          <Typography paragraph className="custom-legal-notice-text">
            De conformidad con lo establecido en el Reglamento General de Protección de Datos (RGPD), le
            informamos que los datos personales facilitados a través de los formularios de este sitio web
            o mediante correos electrónicos serán incorporados a los ficheros de WeDelivery S.A. para poder
            atender sus consultas, gestionar los servicios solicitados y/o tramitar su solicitud.
          </Typography>

          <Typography variant="h6" gutterBottom className="custom-legal-notice-section-title">
            5. Limitación de Responsabilidad
          </Typography>
          <Typography paragraph className="custom-legal-notice-text">
            WeDelivery S.A. no será responsable de posibles daños o perjuicios que se pudieran derivar de
            interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones
            en el funcionamiento operativo de este sistema electrónico, motivadas por causas ajenas a la Empresa,
            de retrasos o bloqueos en el uso del presente sistema electrónico causados por deficiencias o
            sobrecargas de líneas telefónicas o sobrecargas en el Centro de Procesos de Datos, en el sistema de
            Internet o en otros sistemas electrónicos, así como de daños que puedan ser causados por terceras
            personas mediante intromisiones ilegítimas fuera del control de la Empresa.
          </Typography>

          <Typography variant="h6" gutterBottom className="custom-legal-notice-section-title">
            6. Legislación Aplicable y Jurisdicción
          </Typography>
          <Typography paragraph className="custom-legal-notice-text">
            Las relaciones establecidas entre WeDelivery S.A. y el Usuario se regirán por lo dispuesto en la
            normativa española vigente, siendo competentes los Juzgados y Tribunales de la ciudad de [Ciudad],
            salvo que la legislación aplicable disponga otra cosa.
          </Typography>

          <Typography variant="h6" gutterBottom className="custom-legal-notice-section-title">
            7. Modificaciones
          </Typography>
          <Typography paragraph className="custom-legal-notice-text">
            La Empresa se reserva el derecho a efectuar sin previo aviso las modificaciones que considere
            oportunas en su sitio web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios
            que se presten a través del mismo como la forma en la que éstos aparezcan presentados o localizados
            en su sitio web.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default LegalNotice;
