import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { updateExtraHour, deleteExtraHourById } from '../services/api/extraHourApi';
import dayjs from 'dayjs'; // Asegúrate de importar dayjs
import { Delete, Save } from '@mui/icons-material';

const EditExtraHourModal = ({ open, onClose, extraHour, onSave, services }) => {
  const [editedExtraHour, setEditedExtraHour] = useState(extraHour);

  useEffect(() => {
    setEditedExtraHour(extraHour);
  }, [extraHour]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedExtraHour({ ...editedExtraHour, [name]: value });
  };

  const handleSaveChanges = async () => {
    try {
      await updateExtraHour(editedExtraHour.idExtraHour, editedExtraHour);
      onSave();
      onClose();
    } catch (error) {
      console.error('Error updating extra hour', error);
    }
  };

  const handleDeleteExtraHour = async () => {
    try {
      await deleteExtraHourById(editedExtraHour.idExtraHour);
      onSave();
      onClose();
    } catch (error) {
      console.error('Error deleting extra hour', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Hora Extra</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="service-label">Servicio</InputLabel>
              <Select
                labelId="service-label"
                name="serviceId"
                value={editedExtraHour.serviceId}
                onChange={handleInputChange}
                label="Servicio"
              >
                {services.map((service) => (
                  <MenuItem key={service.idService} value={service.idService}>
                    {service.vehiclePlate} - {dayjs(service.createdAt).format('DD/MM/YYYY')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="extraHours"
              label="Horas Extra"
              type="number"
              fullWidth
              value={editedExtraHour.extraHours}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="wedeliveryBenefit"
              label="Beneficio WeDelivery"
              type="number"
              fullWidth
              value={editedExtraHour.wedeliveryBenefit}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="wedeliveryBenefitVat"
              label="Beneficio WeDelivery (con IVA)"
              type="number"
              fullWidth
              value={editedExtraHour.wedeliveryBenefitVat}
              onChange={handleInputChange}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">Cancelar</Button>
        <Button onClick={handleDeleteExtraHour} color="error" variant="contained" startIcon={<Delete />}>
          Eliminar
        </Button>
        <Button onClick={handleSaveChanges} color="primary" variant="contained" startIcon={<Save />}>
          Guardar Cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExtraHourModal;
