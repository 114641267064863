import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Button, Stepper, Step, StepLabel,
  FormControl, Select, MenuItem,
  TextField, Checkbox, FormControlLabel, Grid, IconButton
} from '@mui/material';
import { fetchUsersByRoleId } from '../services/api/userApi';
import { createDriverService } from '../services/api/driverServiceApi';
import { createTrade } from '../services/api/tradeApi';
import { updateServiceStatus } from '../services/api/serviceApi';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const AssignModal = ({ open, onClose, service }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([{ driverId: '', driverType: 'CONTRATO' }]);
  const [trade, setTrade] = useState({});
  const [includeTrade, setIncludeTrade] = useState(false);

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const drivers = await fetchUsersByRoleId(4); // ID de rol 4 para obtener conductores
        setDrivers(drivers);
      } catch (error) {
        console.error('Error al obtener los conductores:', error);
      }
    };

    fetchDrivers();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDriverChange = (event, index) => {
    const selectedDriverId = event.target.value;
    const updatedSelectedDrivers = [...selectedDrivers];
    updatedSelectedDrivers[index] = {
      ...updatedSelectedDrivers[index],
      driverId: selectedDriverId,
    };
    setSelectedDrivers(updatedSelectedDrivers);
  };

  const handleDriverTypeChange = (event, index) => {
    const selectedDriverType = event.target.value;
    const updatedSelectedDrivers = [...selectedDrivers];
    updatedSelectedDrivers[index] = {
      ...updatedSelectedDrivers[index],
      driverType: selectedDriverType,
    };
    setSelectedDrivers(updatedSelectedDrivers);
  };

  const addDriverSelect = () => {
    setSelectedDrivers([...selectedDrivers, { driverId: '', driverType: 'CONTRATO' }]);
  };

  const removeDriverSelect = (index) => {
    const updatedSelectedDrivers = selectedDrivers.filter((_, i) => i !== index);
    setSelectedDrivers(updatedSelectedDrivers);
  };

  const handleTradeChange = (event) => {
    const { name, value } = event.target;
    setTrade({ ...trade, [name]: value });
  };

  const handleIncludeTradeChange = (event) => {
    setIncludeTrade(event.target.checked);
    if (!event.target.checked) {
      setTrade({});
    }
  };

  const handleAssign = async () => {
    try {
      // Asignar conductores
      for (const driver of selectedDrivers) {
        if (driver.driverId) {
          await createDriverService({
            serviceId: service.idService,
            driverId: driver.driverId,
            driverType: driver.driverType,
          });
        } else {
          console.error('El ID del conductor no puede ser nulo.');
        }
      }

      // Opcionalmente, crear un intercambio
      if (includeTrade) {
        await createTrade({
          ...trade,
          idService: service.idService,
        });
      }

      // Actualizar estado del servicio a 'ASSIGNED'
      await updateServiceStatus(service.idService, 'ASSIGNED');

      onClose(); // Cerrar el modal después de la asignación exitosa
    } catch (error) {
      console.error('Error durante la asignación:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Asignar Servicio</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Seleccionar Conductores</StepLabel>
          </Step>
          <Step>
            <StepLabel>Detalles del Intercambio (Opcional)</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <div>
            {selectedDrivers.map((driver, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={5}>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={driver.driverId}
                      onChange={(event) => handleDriverChange(event, index)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return ''; // Muestra nada cuando no se ha seleccionado nada
                        }
                        const selectedDriver = drivers.find(d => d.idUser === selected);
                        return `${selectedDriver.firstName} ${selectedDriver.lastName}`;
                      }}
                    >
                      {drivers.filter(d => !selectedDrivers.some(selected => selected.driverId === d.idUser)).map((d) => (
                        <MenuItem key={d.idUser} value={d.idUser}>
                          {`${d.firstName} ${d.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={driver.driverType}
                      onChange={(event) => handleDriverTypeChange(event, index)}
                    >
                      <MenuItem value="CONTRATO">Contrato</MenuItem>
                      <MenuItem value="AUTONOMO">Autónomo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} container alignItems="center" justifyContent="center">
                  <IconButton
                    color="secondary"
                    onClick={() => removeDriverSelect(index)}
                    aria-label="Eliminar Conductor"
                    disabled={selectedDrivers.length === 1}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button onClick={addDriverSelect} color="primary">
              Añadir otro Conductor
            </Button>
          </div>
        )}

        {activeStep === 1 && (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeTrade}
                  onChange={handleIncludeTradeChange}
                />
              }
              label="Incluir Intercambio"
            />
            {includeTrade && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Matrícula del Vehículo"
                    name="tradeVehiclePlate"
                    value={trade.tradeVehiclePlate || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Marca del Vehículo"
                    name="tradeVehicleBrand"
                    value={trade.tradeVehicleBrand || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Modelo del Vehículo"
                    name="tradeVehicleModel"
                    value={trade.tradeVehicleModel || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Color del Vehículo"
                    name="tradeVehicleColor"
                    value={trade.tradeVehicleColor || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Año del Vehículo"
                    name="tradeVehicleYear"
                    value={trade.tradeVehicleYear || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Dirección de Entrega"
                    name="deliveryAddressTrade"
                    value={trade.deliveryAddressTrade || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nombre del Receptor"
                    name="recipientNameTrade"
                    value={trade.recipientNameTrade || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="NIF del Receptor"
                    name="recipientNifTrade"
                    value={trade.recipientNifTrade || ''}
                    onChange={handleTradeChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep === 1 ? (
          <Button onClick={handleBack}>Atrás</Button>
        ) : null}
        {activeStep === 0 ? (
          <Button onClick={handleNext} color="primary">
            Siguiente
          </Button>
        ) : (
          <Button onClick={handleAssign} color="primary">
            Finalizar Asignación
          </Button>
        )}
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignModal;
