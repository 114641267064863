import api from './apiConfig';

/**
 * Obtiene todas las tarifas.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de tarifas.
 */
export const fetchAllRates = async () => {
    try {
        const response = await api.get('/rates');
        return response.data;
    } catch (error) {
        console.error('Error fetching all rates:', error);
        throw error;
    }
};

/**
 * Obtiene una tarifa por su ID.
 * @param {Number} id - ID de la tarifa.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la tarifa.
 */
export const fetchRateById = async (id) => {
    try {
        const response = await api.get(`/rates/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching rate by ID:', error);
        throw error;
    }
};

/**
 * Guarda una nueva tarifa.
 * @param {Object} rateData - Datos de la tarifa a guardar.
 * @returns {Promise<Object>} - La respuesta del servidor con la tarifa creada.
 */
export const saveRate = async (rateData) => {
    try {
        const response = await api.post('/rates', rateData);
        return response.data;
    } catch (error) {
        console.error('Error saving rate:', error);
        throw error;
    }
};

/**
 * Actualiza una tarifa existente.
 * @param {Number} id - ID de la tarifa.
 * @param {Object} rateData - Datos actualizados de la tarifa.
 * @returns {Promise<Object>} - La respuesta del servidor con la tarifa actualizada.
 */
export const updateRate = async (id, rateData) => {
    try {
        const response = await api.put(`/rates/${id}`, rateData);
        return response.data;
    } catch (error) {
        console.error('Error updating rate:', error);
        throw error;
    }
};

/**
 * Elimina una tarifa por su ID.
 * @param {Number} id - ID de la tarifa.
 * @returns {Promise<void>} - Confirmación de la eliminación de la tarifa.
 */
export const deleteRateById = async (id) => {
    try {
        await api.delete(`/rates/${id}`);
    } catch (error) {
        console.error('Error deleting rate by ID:', error);
        throw error;
    }
};
