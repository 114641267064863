import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReportIcon from '@mui/icons-material/Report';
import TaskIcon from '@mui/icons-material/Task';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MapIcon from '@mui/icons-material/Map';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PolicyIcon from '@mui/icons-material/Policy';
import PolicyIconOn from '@mui/icons-material/PolicyOutlined';
import PolicyIconOff from '@mui/icons-material/PolicyTwoTone';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/authSlice';
import '../../styles/components.scss';
import SidebarWelcome from '../SidebarWelcome'; 

const SidebarEntidad = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/');
  };

  const [openUsers, setOpenUsers] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  const [openPolicies, setOpenPolicies] = useState(false);

  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleClickServices = () => {
    setOpenServices(!openServices);
  };

  const handleClickPolicies = () => {
    setOpenPolicies(!openPolicies);
  };

  return (
    <div className="sidebar">
      <SidebarWelcome />
      <List>
        {/* Submenú Usuarios */}
        <ListItem button onClick={handleClickUsers}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
          {openUsers ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openUsers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/entidad/users')} sx={{ pl: 4 }}>
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText primary="Gestión de Usuarios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/entidad/profile')} sx={{ pl: 4 }}>
              <ListItemIcon><AccountCircleIcon /></ListItemIcon>
              <ListItemText primary="Perfil" />
            </ListItem>
          </List>
        </Collapse>

        {/* Submenú Servicios */}
        <ListItem button onClick={handleClickServices}>
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary="Servicios" />
          {openServices ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openServices} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/entidad/create-services')} sx={{ pl: 4 }}>
              <ListItemIcon><LocalShippingIcon /></ListItemIcon>
              <ListItemText primary="Crear Servicios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/entidad/services')} sx={{ pl: 4 }}>
              <ListItemIcon><TaskIcon /></ListItemIcon>
              <ListItemText primary="Servicios" />
            </ListItem>
            <ListItem button onClick={() => navigate('/entidad/services-completed')} sx={{ pl: 4 }}>
              <ListItemIcon><DoneAllIcon /></ListItemIcon>
              <ListItemText primary="Servicios Completados" />
            </ListItem>
          </List>
        </Collapse>

        {/* Submenú Políticas */}
        <ListItem button onClick={handleClickPolicies}>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
          <ListItemText primary="Políticas" />
          {openPolicies ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openPolicies} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate('/entidad/cookie-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIcon /></ListItemIcon>
              <ListItemText primary="Política de Cookies" />
            </ListItem>
            <ListItem button onClick={() => navigate('/entidad/legal-notice')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOn /></ListItemIcon>
              <ListItemText primary="Aviso Legal" />
            </ListItem>
            <ListItem button onClick={() => navigate('/entidad/privacy-policy')} sx={{ pl: 4 }}>
              <ListItemIcon><PolicyIconOff /></ListItemIcon>
              <ListItemText primary="Política de Privacidad" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => navigate('/entidad/driver-locations')}>
          <ListItemIcon><MapIcon /></ListItemIcon>
          <ListItemText primary="Localizaciones Conductores" />
        </ListItem>

        <ListItem button onClick={() => navigate('/entidad/reports')}>
          <ListItemIcon><ReportIcon /></ListItemIcon>
          <ListItemText primary="Informes" />
        </ListItem>

        <ListItem button onClick={() => navigate('/entidad/my-rates')}>
          <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
          <ListItemText primary="Mis Tarifas" />
        </ListItem>

        <ListItem button onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarEntidad;
