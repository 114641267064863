import api from './apiConfig';

/**
 * Obtiene todos los servicios de conductores.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todos los servicios de conductores.
 */
export const fetchAllDriverServices = async () => {
  try {
    const response = await api.get('/driver-services');
    return response.data;
  } catch (error) {
    console.error('Error al obtener todos los servicios de conductores:', error);
    throw error;
  }
};

/**
 * Obtiene un servicio de conductor por su ID.
 * @param {Number|String} id - ID del servicio de conductor.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del servicio de conductor.
 */
export const fetchDriverServiceById = async (id) => {
  try {
    const response = await api.get(`/driver-services/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener el servicio de conductor por ID:', error);
    throw error;
  }
};

/**
 * Obtiene todos los servicios de conductores asociados a un ID de servicio específico.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios de conductores asociados al ID de servicio.
 */
export const fetchDriverServicesByServiceId = async (serviceId) => {
  try {
    const response = await api.get(`/driver-services/service/${serviceId}`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los servicios de conductores por ID de servicio:', error);
    throw error;
  }
};

/**
 * Crea un nuevo servicio de conductor.
 * @param {Object} driverServiceData - Datos del nuevo servicio de conductor.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del servicio de conductor creado.
 */
export const createDriverService = async (driverServiceData) => {
  try {
    const response = await api.post('/driver-services', driverServiceData);
    return response.data;
  } catch (error) {
    console.error('Error al crear el servicio de conductor:', error);
    throw error;
  }
};

/**
 * Actualiza un servicio de conductor por su ID.
 * @param {Number|String} id - ID del servicio de conductor a actualizar.
 * @param {Object} driverServiceData - Datos actualizados del servicio de conductor.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del servicio de conductor actualizado.
 */
export const updateDriverService = async (id, driverServiceData) => {
  try {
    const response = await api.put(`/driver-services/${id}`, driverServiceData);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar el servicio de conductor:', error);
    throw error;
  }
};

/**
 * Elimina un servicio de conductor por su ID.
 * @param {Number|String} id - ID del servicio de conductor a eliminar.
 * @returns {Promise<Object>} - La respuesta del servidor confirmando la eliminación del servicio de conductor.
 */
export const deleteDriverService = async (id) => {
  try {
    const response = await api.delete(`/driver-services/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error al eliminar el servicio de conductor:', error);
    throw error;
  }
};

/**
 * Obtiene los servicios de un conductor por su ID.
 * @param {Number|String} driverId - ID del conductor.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de servicios asociados al conductor.
 */
export const fetchDriverServicesByDriverId = async (driverId) => {
  try {
    const response = await api.get(`/driver-services/driver/${driverId}`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los servicios del conductor:', error);
    throw error;
  }
};
