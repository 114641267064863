import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Select, MenuItem, FormControl, Grid,
  InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper
} from '@mui/material';
import { Print } from '@mui/icons-material';
import { fetchFilteredServices } from '../services/api/serviceApi';
import { generateReport } from '../services/api/reportApi';
import { fetchUsersByRoleId } from '../services/api/userApi';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import '../styles/pages.scss';

const FilterForm = ({ onFiltersChange, onGeneratePdf, isDriver }) => {
  const [filters, setFilters] = useState({
    startDate: `${dayjs('2000-01-01T00:00:00').format('YYYY-MM-DDTHH:mm:ss')}`,
    endDate: `${dayjs().format('YYYY-MM-DDTHH:mm:ss')}`,
    vehiclePlate: '',
    driverId: 0,
    serviceStatus: 'COMPLETED' // Filtro por defecto para servicios completados
  });

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    if (!isDriver) {
      const loadDrivers = async () => {
        try {
          const response = await fetchUsersByRoleId(4); // Rol de conductores
          setDrivers(response);
        } catch (error) {
          console.error('Error fetching drivers:', error);
        }
      };
      loadDrivers();
    }
  }, [isDriver]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleDriverChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, driverId: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFiltersChange(filters);
  };

  return (
    <form onSubmit={handleSubmit} className="filter-form">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Fecha de inicio"
            type="datetime-local"
            name="startDate"
            value={filters.startDate}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Fecha de fin"
            type="datetime-local"
            name="endDate"
            value={filters.endDate}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Matrícula del vehículo"
            name="vehiclePlate"
            value={filters.vehiclePlate || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        {!isDriver && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="driver-label">Conductor</InputLabel>
              <Select
                name="driverId"
                value={filters.driverId}
                onChange={handleDriverChange}
                displayEmpty
                labelId="driver-label"
              >
                <MenuItem value={0}>
                  <em>Conductor</em>
                </MenuItem>
                {drivers.map((driver) => (
                  <MenuItem key={driver.idUser} value={driver.idUser}>
                    {driver.firstName} {driver.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            Aplicar filtros
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Print />}
            onClick={() => onGeneratePdf(filters)}
          >
            Generar PDF
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ReportTable = ({ services }) => {
  return (
    <TableContainer component={Paper} className="report-table" style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID del Servicio</TableCell>
            <TableCell>ID del Cliente</TableCell>
            <TableCell>Fecha del Servicio</TableCell>
            <TableCell>Hora del Servicio</TableCell>
            <TableCell>Placa del Vehículo</TableCell>
            <TableCell>Marca del Vehículo</TableCell>
            <TableCell>Modelo del Vehículo</TableCell>
            <TableCell>Color del Vehículo</TableCell>
            <TableCell>Fecha de Recepción</TableCell>
            <TableCell>Dirección de Recepción</TableCell>
            <TableCell>Dirección de Entrega</TableCell>
            <TableCell>Nombre del Receptor</TableCell>
            <TableCell>NIF del Receptor</TableCell>
            <TableCell>Precio Base</TableCell>
            <TableCell>Kilometraje Inicial</TableCell>
            <TableCell>Kilometraje Final</TableCell>
            <TableCell>Kilometraje Total</TableCell>
            <TableCell>Tarifa por KM del Cliente</TableCell>
            <TableCell>Estado del Servicio</TableCell>
            <TableCell>Estado del Vehículo</TableCell>
            <TableCell>Comentarios del Conductor</TableCell>
            <TableCell>Fecha de Creación</TableCell>
            <TableCell>Fecha de Actualización</TableCell>
            <TableCell>Usuario Creador</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service.idService}>
              <TableCell>{service.idService}</TableCell>
              <TableCell>{service.clientId}</TableCell>
              <TableCell>{service.serviceDate}</TableCell>
              <TableCell>{service.serviceTime}</TableCell>
              <TableCell>{service.vehiclePlate}</TableCell>
              <TableCell>{service.vehicleBrand}</TableCell>
              <TableCell>{service.vehicleModel}</TableCell>
              <TableCell>{service.vehicleColor}</TableCell>
              <TableCell>{service.receptionDate}</TableCell>
              <TableCell>{service.receptionAddress}</TableCell>
              <TableCell>{service.deliveryAddress}</TableCell>
              <TableCell>{service.recipientName}</TableCell>
              <TableCell>{service.recipientNif}</TableCell>
              <TableCell>{service.basePrice}</TableCell>
              <TableCell>{service.initialKm}</TableCell>
              <TableCell>{service.finalKm}</TableCell>
              <TableCell>{service.totalKm}</TableCell>
              <TableCell>{service.clientRatePerKm}</TableCell>
              <TableCell>{service.serviceStatus}</TableCell>
              <TableCell>{service.vehicleStatus}</TableCell>
              <TableCell>{service.driverComments}</TableCell>
              <TableCell>{service.createdAt}</TableCell>
              <TableCell>{service.updatedAt}</TableCell>
              <TableCell>{service.userIdCreator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Reports = () => {
  const [services, setServices] = useState([]);
  const [filters, setFilters] = useState({
    startDate: `${dayjs('2000-01-01T00:00:00').format('YYYY-MM-DDTHH:mm:ss')}`,
    endDate: `${dayjs().format('YYYY-MM-DDTHH:mm:ss')}`,
    vehiclePlate: null,
    driverId: 0,
    serviceStatus: 'COMPLETED'
  });

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const loadServices = async () => {
      try {
        let updatedFilters = { ...filters };

        // Aplicar filtros según el rol del usuario
        if (user.roleId === 1) {
          // Filtro para Entidad
          updatedFilters.clientId = user.idUser;
        } else if (user.roleId === 2) {
          // Filtro para Oficina Entidad
          updatedFilters.clientId = user.idEntity;
        } else if (user.roleId === 4) {
          // Filtro para Conductores
          updatedFilters.driverId = user.idUser;
        }

        const response = await fetchFilteredServices(updatedFilters);
        setServices(response);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    loadServices();
  }, [filters, user]);

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleGeneratePdf = async (filters) => {
    try {
      const pdfData = await generateReport(filters);
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Informe_${new Date().toLocaleDateString()}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Container maxWidth="lg" className="report-container">
      <Typography variant="h4" gutterBottom>
        Gestión de Informes
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <FilterForm
          onFiltersChange={handleFiltersChange}
          onGeneratePdf={handleGeneratePdf}
          isDriver={user.roleId === 4}
        />
        <ReportTable services={services} />
      </Paper>
    </Container>
  );
};

export default Reports;
