// src/pages/CreateExtraHourModal.jsx
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { saveExtraHour } from '../services/api/extraHourApi';

const CreateExtraHourModal = ({ open, onClose, onSave, services }) => {
  const [newExtraHour, setNewExtraHour] = useState({
    serviceId: '',
    extraHours: 0,
    wedeliveryBenefit: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExtraHour({ ...newExtraHour, [name]: value });
  };

  const handleSave = async () => {
    try {
      await saveExtraHour(newExtraHour);
      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving extra hour', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crear Hora Extra</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="service-label">Servicio</InputLabel>
          <Select
            labelId="service-label"
            name="serviceId"
            value={newExtraHour.serviceId}
            onChange={handleInputChange}
            fullWidth
          >
            {services.map((service) => (
              <MenuItem key={service.idService} value={service.idService}>
                {service.vehiclePlate} - {service.createdAt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          name="extraHours"
          label="Horas Extra"
          type="number"
          fullWidth
          value={newExtraHour.extraHours}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="wedeliveryBenefit"
          label="Beneficio WeDelivery"
          type="number"
          fullWidth
          value={newExtraHour.wedeliveryBenefit}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleSave} color="primary">Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateExtraHourModal;
