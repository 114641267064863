import api from './apiConfig';

/**
 * Obtener todos los roles.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todos los roles.
 */
export const fetchRoles = async () => {
  try {
    const response = await api.get('/roles');
    return response.data;
  } catch (error) {
    console.error('Error al obtener todos los roles:', error);
    throw error;
  }
};

/**
 * Obtener un rol por ID.
 * @param {Number|String} roleId - ID del rol.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del rol.
 */
export const fetchRoleById = async (roleId) => {
  try {
    const response = await api.get(`/roles/${roleId}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener el rol con ID ${roleId}:`, error);
    throw error;
  }
};

/**
 * Guardar un nuevo rol.
 * @param {Object} roleData - Datos del rol a crear.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del rol creado.
 */
export const createRole = async (roleData) => {
  try {
    const response = await api.post('/roles', roleData);
    return response.data;
  } catch (error) {
    console.error('Error al crear el rol:', error);
    throw error;
  }
};

/**
 * Eliminar un rol por ID.
 * @param {Number|String} roleId - ID del rol a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación del rol.
 */
export const deleteRoleById = async (roleId) => {
  try {
    await api.delete(`/roles/${roleId}`);
  } catch (error) {
    console.error(`Error al eliminar el rol con ID ${roleId}:`, error);
    throw error;
  }
};
