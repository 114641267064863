import api from './apiConfig';

/**
 * Obtiene todas las horas extras registradas en el sistema.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todas las horas extras.
 */
export const fetchAllExtraHours = async () => {
  try {
    const response = await api.get('/extra-hours');
    return response.data;
  } catch (error) {
    console.error('Error al obtener todas las horas extras:', error);
    throw error;
  }
};

/**
 * Obtiene las horas extras específicas por su ID.
 * @param {Number|String} id - ID de las horas extras.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de las horas extras.
 */
export const fetchExtraHourById = async (id) => {
  try {
    const response = await api.get(`/extra-hours/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener las horas extras con ID ${id}:`, error);
    throw error;
  }
};

/**
 * Obtiene todas las horas extras asociadas a un servicio específico.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de horas extras del servicio.
 */
export const fetchExtraHoursByServiceId = async (serviceId) => {
  try {
    const response = await api.get(`/extra-hours/service/${serviceId}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener las horas extras para el servicio con ID ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Guarda nuevas horas extras en el sistema.
 * @param {Object} extraHourData - Datos de las nuevas horas extras.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de las horas extras guardadas.
 */
export const saveExtraHour = async (extraHourData) => {
  try {
    const response = await api.post('/extra-hours', extraHourData);
    return response.data;
  } catch (error) {
    console.error('Error al guardar las horas extras:', error);
    throw error;
  }
};

/**
 * Actualiza las horas extras específicas por su ID.
 * @param {Number|String} id - ID de las horas extras a actualizar.
 * @param {Object} extraHourData - Datos actualizados de las horas extras.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de las horas extras actualizadas.
 */
export const updateExtraHour = async (id, extraHourData) => {
  try {
    const response = await api.put(`/extra-hours/${id}`, extraHourData);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar las horas extras con ID ${id}:`, error);
    throw error;
  }
};

/**
 * Elimina las horas extras específicas por su ID.
 * @param {Number|String} id - ID de las horas extras a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación de las horas extras.
 */
export const deleteExtraHourById = async (id) => {
  try {
    await api.delete(`/extra-hours/${id}`);
  } catch (error) {
    console.error(`Error al eliminar las horas extras con ID ${id}:`, error);
    throw error;
  }
};

/**
 * Filtra las horas extras según los criterios especificados.
 * @param {Object} filterData - Criterios de filtrado para las horas extras.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de horas extras filtradas.
 */
export const filterExtraHours = async (filterData) => {
  try {
    const response = await api.post('/extra-hours/filter', filterData);
    return response.data;
  } catch (error) {
    console.error('Error al filtrar las horas extras:', error);
    throw error;
  }
};
