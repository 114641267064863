import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid
} from '@mui/material';
import { Print } from '@mui/icons-material';
import { fetchFilteredServices } from '../services/api/serviceApi';
import { generateReport } from '../services/api/reportApi';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import '../styles/pages.scss';

const FilterForm = ({ onFiltersChange, onGeneratePdf }) => {
  const [filters, setFilters] = useState({
    startDate: `${dayjs('2000-01-01').format('YYYY-MM-DDTHH:mm:ss')}`,
    endDate: `${dayjs().format('YYYY-MM-DDTHH:mm:ss')}`,
    vehiclePlate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const adjustedFilters = { ...filters };

    if (filters.vehiclePlate && filters.vehiclePlate.trim() === '') {
      adjustedFilters.vehiclePlate = null;
    }

    onFiltersChange(adjustedFilters);
  };

  return (
    <form onSubmit={handleSubmit} className="filter-form">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Fecha de inicio"
            type="datetime-local"
            name="startDate"
            value={filters.startDate.split('T')[0]} // Mostrar solo la fecha
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Fecha de fin"
            type="datetime-local"
            name="endDate"
            value={filters.endDate.split('T')[0]} // Mostrar solo la fecha
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Matrícula del vehículo"
            name="vehiclePlate"
            value={filters.vehiclePlate || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            Aplicar filtros
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Print />}
            onClick={() => onGeneratePdf(filters)}
          >
            Generar PDF
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ReportTable = ({ services }) => {
  return (
    <TableContainer component={Paper} className="report-table" style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID del Servicio</TableCell>
            <TableCell>ID del Cliente</TableCell>
            <TableCell>Fecha del Servicio</TableCell>
            <TableCell>Hora del Servicio</TableCell>
            <TableCell>Placa del Vehículo</TableCell>
            <TableCell>Marca del Vehículo</TableCell>
            <TableCell>Modelo del Vehículo</TableCell>
            <TableCell>Color del Vehículo</TableCell>
            <TableCell>Fecha de Recepción</TableCell>
            <TableCell>Dirección de Recepción</TableCell>
            <TableCell>Dirección de Entrega</TableCell>
            <TableCell>Nombre del Receptor</TableCell>
            <TableCell>NIF del Receptor</TableCell>
            <TableCell>Precio Base</TableCell>
            <TableCell>Kilometraje Inicial</TableCell>
            <TableCell>Kilometraje Final</TableCell>
            <TableCell>Kilometraje Total</TableCell>
            <TableCell>Tarifa por KM del Cliente</TableCell>
            <TableCell>Estado del Servicio</TableCell>
            <TableCell>Estado del Vehículo</TableCell>
            <TableCell>Comentarios del Conductor</TableCell>
            <TableCell>Fecha de Creación</TableCell>
            <TableCell>Fecha de Actualización</TableCell>
            <TableCell>Usuario Creador</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service.idService}>
              <TableCell>{service.idService}</TableCell>
              <TableCell>{service.clientId}</TableCell>
              <TableCell>{service.serviceDate}</TableCell>
              <TableCell>
                {service.serviceTime?.hour || "00"}:{service.serviceTime?.minute || "00"}:
                {service.serviceTime?.second || "00"}
              </TableCell>
              <TableCell>{service.vehiclePlate}</TableCell>
              <TableCell>{service.vehicleBrand}</TableCell>
              <TableCell>{service.vehicleModel}</TableCell>
              <TableCell>{service.vehicleColor}</TableCell>
              <TableCell>{service.receptionDate}</TableCell>
              <TableCell>{service.receptionAddress}</TableCell>
              <TableCell>{service.deliveryAddress}</TableCell>
              <TableCell>{service.recipientName}</TableCell>
              <TableCell>{service.recipientNif}</TableCell>
              <TableCell>{service.basePrice}</TableCell>
              <TableCell>{service.initialKm}</TableCell>
              <TableCell>{service.finalKm}</TableCell>
              <TableCell>{service.totalKm}</TableCell>
              <TableCell>{service.clientRatePerKm}</TableCell>
              <TableCell>{service.serviceStatus}</TableCell>
              <TableCell>{service.vehicleStatus}</TableCell>
              <TableCell>{service.driverComments}</TableCell>
              <TableCell>{service.createdAt}</TableCell>
              <TableCell>{service.updatedAt}</TableCell>
              <TableCell>{service.userIdCreator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ReportsConductor = () => {
  const [services, setServices] = useState([]);
  const [filters, setFilters] = useState({
    startDate: `${dayjs('2000-01-01').format('YYYY-MM-DDTHH:mm:ss')}`,
    endDate: `${dayjs().format('YYYY-MM-DDTHH:mm:ss')}`,
    vehiclePlate: null,
  });

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchFilteredServices({
          ...filters,
          driverId: user.idUser,
        });
        setServices(response);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    loadServices();
  }, [filters, user]);

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
      driverId: user.idUser,
    }));
  };

  const handleGeneratePdf = async (filters) => {
    try {
      const pdfData = await generateReport({ ...filters, driverId: user.idUser });
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Informe_${new Date().toLocaleDateString()}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Container maxWidth="lg" className="report-container">
      <Typography variant="h4" gutterBottom>
        Mis Informes
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <FilterForm onFiltersChange={handleFiltersChange} onGeneratePdf={handleGeneratePdf} />
        <ReportTable services={services} />
      </Paper>
    </Container>
  );
};

export default ReportsConductor;
