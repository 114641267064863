import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import { fetchPendingUsers, confirmUser, deleteUserById } from '../services/api/userApi'; 
import '../styles/pages.scss';

const UsersPending = () => {
  const [pendingUsers, setPendingUsers] = useState([]);

  useEffect(() => {
    const fetchPending = async () => {
      try {
        const users = await fetchPendingUsers();
        setPendingUsers(users);
      } catch (error) {
        console.error('Error fetching pending users:', error);
      }
    };

    fetchPending();
  }, []);

  const handleAccept = async (userId) => {
    try {
      await confirmUser(userId);
      setPendingUsers(pendingUsers.filter(user => user.idUser !== userId));
    } catch (error) {
      console.error('Error confirming user:', error);
    }
  };

  const handleDecline = async (userId) => {
    try {
      await deleteUserById(userId);
      setPendingUsers(pendingUsers.filter(user => user.idUser !== userId));
    } catch (error) {
      console.error('Error declining user:', error);
    }
  };

  return (
    <div className="container">
      <Typography variant="h4" gutterBottom className="page-title">
        Usuarios Pendientes
      </Typography>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Nombre</strong></TableCell>
              <TableCell><strong>Apellido</strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Acciones</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingUsers.length > 0 ? (
              pendingUsers.map((user) => (
                <TableRow key={user.idUser}>
                  <TableCell>{user.idUser}</TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={() => handleAccept(user.idUser)}
                      sx={{ marginRight: '10px' }}
                    >
                      Aceptar
                    </Button>
                    <Button 
                      variant="contained" 
                      color="secondary" 
                      onClick={() => handleDecline(user.idUser)}
                    >
                      Declinar
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body1" align="center">
                    No hay usuarios pendientes.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UsersPending;
