import React, { useState, useEffect } from 'react';
import { fetchServicesByStatus, updateServiceStatus } from '../services/api/serviceApi';
import { fetchUserById } from '../services/api/userApi';
import { uploadImage } from '../services/api/imageServiceApi';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux'; // Importar useSelector para acceder al usuario actual

const MAX_FILE_SIZE_MB = 10; // Tamaño máximo de archivo permitido en MB

const ServicesInProgress = () => {
  const [inProgressServices, setInProgressServices] = useState([]);
  const [clientNames, setClientNames] = useState({});
  const [selectedService, setSelectedService] = useState(null);
  const [files, setFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileError, setFileError] = useState(null); // Estado para errores de archivos grandes
  const { user } = useSelector((state) => state.auth); // Obtener el usuario autenticado

  useEffect(() => {
    const fetchInProgressServices = async () => {
      try {
        const services = await fetchServicesByStatus('IN_PROGRESS');

        // Filtrar servicios según el rol del usuario
        let filteredServices = services;
        if (user.roleId === 1) { // Rol Entidad
          filteredServices = services.filter(service => service.clientId === user.idUser);
        } else if (user.roleId === 2) { // Rol Oficina Entidad
          filteredServices = services.filter(service => service.clientId === user.idEntity);
        }

        setInProgressServices(filteredServices);

        // Obtener nombres de los clientes
        const clientNamePromises = filteredServices.map(async (service) => {
          const client = await fetchUserById(service.clientId);
          return { clientId: service.clientId, clientName: client.firstName };
        });

        const clients = await Promise.all(clientNamePromises);
        const clientNameMap = clients.reduce((acc, curr) => {
          acc[curr.clientId] = curr.clientName;
          return acc;
        }, {});

        setClientNames(clientNameMap);
      } catch (error) {
        console.error('Error fetching in-progress services:', error);
      }
    };

    fetchInProgressServices();
  }, [user]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const fileErrors = selectedFiles.some(file => file.size / 1024 / 1024 > MAX_FILE_SIZE_MB);

    if (fileErrors) {
      setFileError(`Uno o más archivos superan el tamaño máximo de ${MAX_FILE_SIZE_MB} MB.`);
      setFiles([]);
      setPreviewImages([]);
    } else {
      setFileError(null);
      setFiles(selectedFiles);
      const previewUrls = selectedFiles.map(file => URL.createObjectURL(file));
      setPreviewImages(previewUrls);
    }
  };

  const handleCompleteService = async () => {
    if (!selectedService || files.length === 0) return;

    try {
      const uploadResponses = await Promise.all(
        files.map((file) =>
          uploadImage(file, selectedService.idService, 'entrega')
        )
      );

      console.log('Uploaded images:', uploadResponses);

      const updatedService = await updateServiceStatus(selectedService.idService, 'PENDING_REVIEW');
      console.log('Service completed:', updatedService);

      // Refrescar la lista de servicios en progreso
      const updatedServices = await fetchServicesByStatus('IN_PROGRESS');

      // Filtrar servicios según el rol del usuario
      let filteredServices = updatedServices;
      if (user.roleId === 1) { // Rol Entidad
        filteredServices = updatedServices.filter(service => service.clientId === user.idUser);
      } else if (user.roleId === 2) { // Rol Oficina Entidad
        filteredServices = updatedServices.filter(service => service.clientId === user.idEntity);
      }

      setInProgressServices(filteredServices);

      // Cerrar el modal y limpiar la selección
      handleCloseModal();
    } catch (error) {
      console.error('Error completing service:', error);
    }
  };

  const handleCancelService = async (serviceId) => {
    try {
      // Actualizar el estado del servicio a CANCELLED
      const response = await updateServiceStatus(serviceId, 'CANCELLED');
      console.log('Service canceled:', response);

      // Refrescar la lista de servicios en progreso
      const updatedServices = await fetchServicesByStatus('IN_PROGRESS');

      // Filtrar servicios según el rol del usuario
      let filteredServices = updatedServices;
      if (user.roleId === 1) { // Rol Entidad
        filteredServices = updatedServices.filter(service => service.clientId === user.idUser);
      } else if (user.roleId === 2) { // Rol Oficina Entidad
        filteredServices = updatedServices.filter(service => service.clientId === user.idEntity);
      }

      setInProgressServices(filteredServices);
    } catch (error) {
      console.error('Error canceling service:', error);
    }
  };

  const handleOpenModal = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
    setFiles([]);
    setPreviewImages([]);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="container">
      <Typography variant="h4" className="page-title">
        Servicios en Progreso
      </Typography>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell>Fecha del Servicio</TableCell>
              <TableCell>Hora del Servicio</TableCell>
              <TableCell>Matrícula del Vehículo</TableCell>
              <TableCell>Marca del Vehículo</TableCell>
              <TableCell>Modelo del Vehículo</TableCell>
              <TableCell>Color del Vehículo</TableCell>
              <TableCell>Fecha de Recepción</TableCell>
              <TableCell>Dirección de Recepción</TableCell>
              <TableCell>Dirección de Entrega</TableCell>
              <TableCell>Nombre del Receptor</TableCell>
              <TableCell>NIF del Receptor</TableCell>
              <TableCell>Precio Base</TableCell>
              <TableCell>Kilometraje Inicial</TableCell>
              <TableCell>Kilometraje Final</TableCell>
              <TableCell>Kilometraje Total</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Estado del Vehículo</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inProgressServices.map((service) => (
              <TableRow key={service.idService} className="table-row">
                <TableCell>{clientNames[service.clientId]}</TableCell>
                <TableCell>{formatDate(service.serviceDate)}</TableCell>
                <TableCell>{service.serviceTime}</TableCell>
                <TableCell>{service.vehiclePlate}</TableCell>
                <TableCell>{service.vehicleBrand}</TableCell>
                <TableCell>{service.vehicleModel}</TableCell>
                <TableCell>{service.vehicleColor}</TableCell>
                <TableCell>{formatDate(service.receptionDate)}</TableCell>
                <TableCell>{service.receptionAddress}</TableCell>
                <TableCell>{service.deliveryAddress}</TableCell>
                <TableCell>{service.recipientName}</TableCell>
                <TableCell>{service.recipientNif}</TableCell>
                <TableCell>{service.basePrice}</TableCell>
                <TableCell>{service.initialKm}</TableCell>
                <TableCell>{service.finalKm}</TableCell>
                <TableCell>{service.totalKm}</TableCell>
                <TableCell>{service.serviceStatus}</TableCell>
                <TableCell>{service.vehicleStatus}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleOpenModal(service)}>
                    Completar Servicio
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={() => handleCancelService(service.idService)}>
                    Cancelar Servicio
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="complete-service-modal-title"
        aria-describedby="complete-service-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4 }}>
          <Typography id="complete-service-modal-title" variant="h6" component="h2">
            Completar Servicio
          </Typography>
          <input 
            type="file" 
            multiple 
            onChange={handleFileChange} 
          />
          {fileError && (
            <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
              {fileError}
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
            {previewImages.map((src, index) => (
              <img key={index} src={src} alt={`Preview ${index + 1}`} style={{ width: 100, height: 100, objectFit: 'cover' }} />
            ))}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleCompleteService} disabled={files.length === 0 || fileError}>
              Subir Imágenes & Completar
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ ml: 2 }}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ServicesInProgress;
