import React, { useState, useEffect } from 'react';
import {
  Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions,
  DialogContent, DialogTitle, Select, MenuItem, FormControl, InputLabel, Container,
  Typography, Paper, IconButton
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { fetchAllRates } from '../services/api/rateApi';
import { fetchAllRateEntities, createRateEntity, updateRateEntity, deleteRateEntity } from '../services/api/rateEntityApi';
import { fetchUsersByRoleId } from '../services/api/userApi';

const RateEntityForm = ({ open, onClose, rateEntity, rates, entities, onSave }) => {
  const [formData, setFormData] = useState({
    idRateEntity: rateEntity ? rateEntity.idRateEntity : 0,
    clientId: rateEntity ? rateEntity.clientId : 0,
    rateId: rateEntity ? rateEntity.rateId : 0,
    createdAt: rateEntity ? rateEntity.createdAt : new Date().toISOString().split('T')[0],
    updatedAt: new Date().toISOString().split('T')[0],
    deletedAt: rateEntity ? rateEntity.deletedAt : null,
  });

  useEffect(() => {
    if (rateEntity) {
      setFormData({
        idRateEntity: rateEntity.idRateEntity,
        clientId: rateEntity.clientId,
        rateId: rateEntity.rateId,
        createdAt: rateEntity.createdAt,
        updatedAt: new Date().toISOString().split('T')[0],
        deletedAt: rateEntity.deletedAt,
      });
    } else {
      setFormData({
        idRateEntity: 0,
        clientId: 0,
        rateId: 0,
        createdAt: new Date().toISOString().split('T')[0],
        updatedAt: new Date().toISOString().split('T')[0],
        deletedAt: null,
      });
    }
  }, [rateEntity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.clientId || !formData.rateId) {
      console.error("Se requiere seleccionar tanto una tarifa como una entidad.");
      return;
    }
    if (formData.idRateEntity) {
      await updateRateEntity(formData.idRateEntity, formData);
    } else {
      await createRateEntity(formData);
    }
    onSave();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{formData.idRateEntity ? 'Editar Tarifa de Entidad' : 'Crear Nueva Tarifa de Entidad'}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="rate-label">Tarifa</InputLabel>
          <Select
            labelId="rate-label"
            name="rateId"
            value={formData.rateId}
            onChange={handleChange}
          >
            {rates.map((rate) => (
              <MenuItem key={rate.idRate} value={rate.idRate}>
                {rate.rateType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="entity-label">Entidad</InputLabel>
          <Select
            labelId="entity-label"
            name="clientId"
            value={formData.clientId}
            onChange={handleChange}
          >
            {entities.map((user) => (
              <MenuItem key={user.idUser} value={user.idUser}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RateEntities = () => {
  const [rateEntities, setRateEntities] = useState([]);
  const [rates, setRates] = useState([]);
  const [entities, setEntities] = useState([]);
  const [editingRateEntity, setEditingRateEntity] = useState(null);
  const [rateEntityFormOpen, setRateEntityFormOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ratesData = await fetchAllRates();
        const rateEntitiesData = await fetchAllRateEntities();
        const entitiesData = await fetchUsersByRoleId(1);
        setRates(ratesData);
        setRateEntities(rateEntitiesData);
        setEntities(entitiesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleEditRateEntity = (rateEntity) => {
    setEditingRateEntity(rateEntity);
    setRateEntityFormOpen(true);
  };

  const handleDeleteRateEntity = async (idRateEntity) => {
    await deleteRateEntity(idRateEntity);
    setRateEntities(rateEntities.filter((entity) => entity.idRateEntity !== idRateEntity));
  };

  const handleSaveRateEntity = async () => {
    const rateEntitiesData = await fetchAllRateEntities();
    setRateEntities(rateEntitiesData);
    setRateEntityFormOpen(false);
  };

  const handleNewRateEntity = () => {
    setEditingRateEntity(null);
    setRateEntityFormOpen(true);
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gestión de Tarifas por Entidad
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleNewRateEntity}
          style={{ marginBottom: '20px' }}
        >
          Crear Nueva Tarifa por Entidad
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tarifa</TableCell>
              <TableCell>Entidad</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rateEntities.map((rateEntity) => (
              <TableRow key={rateEntity.idRateEntity}>
                <TableCell>{rateEntity.idRateEntity}</TableCell>
                <TableCell>{rates.find(rate => rate.idRate === rateEntity.rateId)?.rateType}</TableCell>
                <TableCell>{entities.find(entity => entity.idUser === rateEntity.clientId)?.firstName} {entities.find(entity => entity.idUser === rateEntity.clientId)?.lastName}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditRateEntity(rateEntity)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteRateEntity(rateEntity.idRateEntity)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <RateEntityForm
        open={rateEntityFormOpen}
        onClose={() => setRateEntityFormOpen(false)}
        rateEntity={editingRateEntity}
        rates={rates}
        entities={entities}
        onSave={handleSaveRateEntity}
      />
    </Container>
  );
};

export default RateEntities;
