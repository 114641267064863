import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import SidebarOficinaWeDelivery from '../sidebars/SidebarOficinaWeDelivery';
import DriversLocations from '../../pages/DriversLocations';
import Profile from '../common/Profile';
import Rates from '../../pages/Rates';
import RateSegments from '../../pages/RateSegments';
import RateEntities from '../../pages/RateEntities';
import Users from '../../pages/Users';
import Reports from '../../pages/Reports';
import CreateServices from '../../pages/CreateServices';
import ServicesAssigned from '../../pages/ServicesAssigned';
import ServicesInProgress from '../../pages/ServicesInProgress';
import ServicesPending from '../../pages/ServicesPending';
import ServicesPendingReview from '../../pages/ServicesPendingReview';
import ServicesCompleted from '../../pages/ServicesCompleted';
import Expenses from '../../pages/Expenses';
import ExtraHours from '../../pages/ExtraHours';
import '../../styles/components.scss';
import CookiePolicy from '../../pages/CookiePolicy';
import LegalNotice from '../../pages/LegalNotice';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import Services from '../../pages/Services';
import WelcomeMessage from '../WelcomeMessage'; // Importar el componente de mensaje de bienvenida
import EditServices from '../../pages/EditServices';
import ServicesImages from '../../pages/ServicesImages';

const DashboardOficinaWeDelivery = () => {
  const user = useSelector((state) => state.auth.user);
  const permissions = useSelector((state) => state.auth.permissions);

  if (!permissions) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="dashboard">
      <SidebarOficinaWeDelivery />
      <div className="content">
        <Routes>
          <Route path="services" element={<Services />} />
          <Route path="create-services" element={<CreateServices />} />
          <Route path="services-assigned" element={<ServicesAssigned />} />
          <Route path="services-in-progress" element={<ServicesInProgress />} />
          <Route path="services-pending" element={<ServicesPending />} />
          <Route path="services-pending-review" element={<ServicesPendingReview />} />
          <Route path="services-completed" element={<ServicesCompleted />} />
          <Route path="edit-services" element={<EditServices />} />
          <Route path="rates" element={<Rates />} />
          <Route path="rate-segments" element={<RateSegments />} />
          <Route path="rate-entities" element={<RateEntities />} />
          <Route path="reports" element={<Reports />} />
          <Route path="extra-hours" element={<ExtraHours />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="driver-location" element={<DriversLocations />} />
          <Route path="users" element={<Users />} />
          <Route path="profile" element={<Profile />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="legal-notice" element={<LegalNotice />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="services-images" element={<ServicesImages />} />
          {/* Ruta por defecto que muestra el mensaje de bienvenida */}
          <Route
            path="/"
            element={<WelcomeMessage firstName={user?.firstName} lastName={user?.lastName} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default DashboardOficinaWeDelivery;
