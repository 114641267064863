import React, { useState, useEffect } from 'react';
import { fetchServicesByStatus, fetchServiceById } from '../services/api/serviceApi';
import { fetchDriverServicesByServiceId } from '../services/api/driverServiceApi';
import { fetchExpensesByServiceId, fetchExpenseImage } from '../services/api/expenseApi';
import { fetchImagesByServiceId, downloadImageById } from '../services/api/imageServiceApi';
import { fetchExtraHoursByServiceId } from '../services/api/extraHourApi';
import { fetchTradesByServiceId } from '../services/api/tradeApi';
import { fetchTotalByServiceId } from '../services/api/totalApi';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, Typography, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux'; // Importar useSelector para acceder al usuario actual

const ServicesCompleted = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [relatedData, setRelatedData] = useState({
    trades: [],
    driverServices: [],
    expenses: [],
    images: [],
    extraHours: [],
    total: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const { user } = useSelector((state) => state.auth); // Obtener el usuario autenticado

  useEffect(() => {
    const fetchCompletedServices = async () => {
      try {
        const services = await fetchServicesByStatus('COMPLETED');

        // Filtrar servicios según el rol del usuario
        let filteredServices = services;
        if (user.roleId === 1) { // Rol Entidad
          filteredServices = services.filter(service => service.clientId === user.idUser);
        } else if (user.roleId === 2) { // Rol Oficina Entidad
          filteredServices = services.filter(service => service.clientId === user.idEntity);
        }

        setServices(filteredServices);
      } catch (error) {
        console.error('Error al obtener los servicios completados:', error);
      }
    };

    fetchCompletedServices();
  }, [user]);

  const handleRowClick = async (service) => {
    try {
      const serviceDetails = await fetchServiceById(service.idService);
      const trades = await fetchTradesByServiceId(service.idService);
      const driverServices = await fetchDriverServicesByServiceId(service.idService);
      const expenses = await fetchExpensesByServiceId(service.idService);
      const images = await fetchImagesByServiceId(service.idService);
      const extraHours = await fetchExtraHoursByServiceId(service.idService);
      const total = await fetchTotalByServiceId(service.idService);

      setSelectedService(serviceDetails);
      setRelatedData({ trades, driverServices, expenses, images, extraHours, total });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error al obtener los datos relacionados:', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
    setRelatedData({
      trades: [],
      driverServices: [],
      expenses: [],
      images: [],
      extraHours: [],
      total: null,
    });
    setImagePreview(null);
  };

  const handleViewImage = async (id, type) => {
    try {
      let imageBlob;
      if (type === 'expense') {
        imageBlob = await fetchExpenseImage(id);
      } else if (type === 'service') {
        imageBlob = await downloadImageById(id);
      }

      const imageUrl = URL.createObjectURL(imageBlob);
      setImagePreview(imageUrl);
    } catch (error) {
      console.error('Error al obtener la imagen:', error);
    }
  };

  return (
    <div>
      <h1>Servicios Completados</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID del Servicio</TableCell>
              <TableCell>ID del Cliente</TableCell>
              <TableCell>Fecha del Servicio</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No se encontraron servicios en estado COMPLETED.
                </TableCell>
              </TableRow>
            ) : (
              services.map((service) => (
                <TableRow key={service.idService} onClick={() => handleRowClick(service)}>
                  <TableCell>{service.idService}</TableCell>
                  <TableCell>{service.clientId}</TableCell>
                  <TableCell>{service.serviceDate}</TableCell>
                  <TableCell>{service.serviceStatus}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary">
                      Ver Detalles
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="service-details-modal-title"
        aria-describedby="service-details-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4, overflow: 'auto', maxHeight: '90vh' }}>
          <Typography id="service-details-modal-title" variant="h6" component="h2">
            Detalles del Servicio
          </Typography>
          {selectedService && (
            <>
              <Typography variant="body1"><strong>Cliente ID:</strong> {selectedService.clientId}</Typography>
              <Typography variant="body1"><strong>Fecha del Servicio:</strong> {selectedService.serviceDate}</Typography>
              <Typography variant="body1"><strong>Matrícula del Vehículo:</strong> {selectedService.vehiclePlate}</Typography>
              <Typography variant="body1"><strong>Marca del Vehículo:</strong> {selectedService.vehicleBrand}</Typography>
              <Typography variant="body1"><strong>Modelo del Vehículo:</strong> {selectedService.vehicleModel}</Typography>
              <Typography variant="body1"><strong>Color del Vehículo:</strong> {selectedService.vehicleColor}</Typography>
              <Typography variant="body1"><strong>Dirección de Recepción:</strong> {selectedService.receptionAddress}</Typography>
              <Typography variant="body1"><strong>Dirección de Entrega:</strong> {selectedService.deliveryAddress}</Typography>
              <Typography variant="body1"><strong>Nombre del Receptor:</strong> {selectedService.recipientName}</Typography>
              <Typography variant="body1"><strong>NIF del Receptor:</strong> {selectedService.recipientNif}</Typography>
              <Typography variant="body1"><strong>Precio Base:</strong> {selectedService.basePrice}</Typography>
              <Typography variant="body1"><strong>Kilometraje Inicial:</strong> {selectedService.initialKm}</Typography>
              <Typography variant="body1"><strong>Kilometraje Final:</strong> {selectedService.finalKm}</Typography>
              <Typography variant="body1"><strong>Kilometraje Total:</strong> {selectedService.totalKm}</Typography>
              <Typography variant="body1"><strong>Estado del Vehículo:</strong> {selectedService.vehicleStatus}</Typography>
              <Typography variant="body1"><strong>Comentarios Iniciales:</strong> {selectedService.reEntityComments}</Typography>
              <Typography variant="body1"><strong>Comentarios Finales:</strong> {selectedService.eEntityComments}</Typography>

              <Typography variant="h6" sx={{ mt: 2 }}>Trade</Typography>
              {relatedData.trades.map((trade) => (
                <Box key={trade.idTrade} sx={{ mb: 2 }}>
                  <Typography variant="body2"><strong>Matrícula del Vehículo:</strong> {trade.tradeVehiclePlate}</Typography>
                  <Typography variant="body2"><strong>Marca del Vehículo:</strong> {trade.tradeVehicleBrand}</Typography>
                  <Typography variant="body2"><strong>Modelo del Vehículo:</strong> {trade.tradeVehicleModel}</Typography>
                  <Typography variant="body2"><strong>Color del Vehículo:</strong> {trade.tradeVehicleColor}</Typography>
                  <Typography variant="body2"><strong>Año del Vehículo:</strong> {trade.tradeVehicleYear}</Typography>
                  <Typography variant="body2"><strong>Dirección de Entrega:</strong> {trade.deliveryAddressTrade}</Typography>
                  <Typography variant="body2"><strong>Nombre del Receptor:</strong> {trade.recipientNameTrade}</Typography>
                  <Typography variant="body2"><strong>NIF del Receptor:</strong> {trade.recipientNifTrade}</Typography>
                  <Typography variant="body2"><strong>Kilometraje Inicial:</strong> {trade.initialKmTrade}</Typography>
                  <Typography variant="body2"><strong>Kilometraje Final:</strong> {trade.finalKmTrade}</Typography>
                  <Typography variant="body2"><strong>Kilometraje Total:</strong> {trade.totalKmTrade}</Typography>
                </Box>
              ))}

              <Typography variant="h6" sx={{ mt: 2 }}>Driver Services</Typography>
              {relatedData.driverServices.map((driverService) => (
                <Box key={driverService.idDriverService} sx={{ mb: 2 }}>
                  <Typography variant="body2"><strong>Tipo de Conductor:</strong> {driverService.driverType}</Typography>
                  <Typography variant="body2"><strong>Tarifa del Conductor:</strong> {driverService.driverRate}</Typography>
                  <Typography variant="body2"><strong>Comentarios Iniciales:</strong> {driverService.reDriverComments}</Typography>
                  <Typography variant="body2"><strong>Comentarios Finales:</strong> {driverService.eDriverComments}</Typography>
                </Box>
              ))}

              <Typography variant="h6" sx={{ mt: 2 }}>Expenses</Typography>
              {relatedData.expenses.map((expense) => (
                <Box key={expense.idExpense} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    <strong>Tipo de Gasto:</strong> {expense.expenseType}<br />
                    <strong>Tipo de Impuesto:</strong> {expense.taxType}<br />
                    <strong>Monto Base:</strong> {expense.amountBase}<br />
                    <strong>Monto sin IVA:</strong> {expense.amountExclVat}<br />
                    <strong>Descripción:</strong> {expense.description}
                  </Typography>
                  {expense.image && (
                    <IconButton onClick={() => handleViewImage(expense.idExpense, 'expense')}>
                      <VisibilityIcon />
                    </IconButton>
                  )}
                </Box>
              ))}

              <Typography variant="h6" sx={{ mt: 2 }}>Imágenes del Servicio</Typography>
              {relatedData.images.map((image) => (
                <Box key={image.idImage} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    <strong>Tipo de Imagen:</strong> {image.imageType}
                  </Typography>
                  <IconButton onClick={() => handleViewImage(image.idImage, 'service')}>
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              ))}

              <Typography variant="h6" sx={{ mt: 2 }}>Horas Extras</Typography>
              {relatedData.extraHours.map((extraHour) => (
                <Box key={extraHour.idExtraHour} sx={{ mb: 2 }}>
                  <Typography variant="body2"><strong>Horas Extras:</strong> {extraHour.extraHours}</Typography>
                  <Typography variant="body2"><strong>Beneficio:</strong> {extraHour.wedeliveryBenefit}</Typography>
                  <Typography variant="body2"><strong>IVA del Beneficio:</strong> {extraHour.wedeliveryBenefitVat}</Typography>
                </Box>
              ))}

              <Typography variant="h6" sx={{ mt: 2 }}>Total</Typography>
              {relatedData.total && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2"><strong>Costo Total:</strong> {relatedData.total.totalCost}</Typography>
                  <Typography variant="body2"><strong>IVA del Costo Total:</strong> {relatedData.total.totalCostVat}</Typography>
                  <Typography variant="body2"><strong>Pago Total a Conductores:</strong> {relatedData.total.totalDriversPayment}</Typography>
                  <Typography variant="body2"><strong>Beneficio Total:</strong> {relatedData.total.totalBenefit}</Typography>
                </Box>
              )}
            </>
          )}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
              Cerrar
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={Boolean(imagePreview)}
        onClose={() => setImagePreview(null)}
        aria-labelledby="image-preview-modal-title"
        aria-describedby="image-preview-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4 }}>
          <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Button variant="outlined" color="secondary" onClick={() => setImagePreview(null)}>
              Cerrar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ServicesCompleted;
