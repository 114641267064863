import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { saveUser, fetchUsersByRoleId } from '../services/api/userApi';
import { Modal, Box, TextField, Button, Select, MenuItem, InputLabel, FormControl, Switch, FormControlLabel } from '@mui/material';

const EditUserModal = ({ open, onClose, onUserUpdated, user, roles }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    idUser: currentUser.idUser,
    firstName: '',
    lastName: null,
    nif: '',
    email: '',
    contact: '',  // Mantenido como 'contact' porque es necesario para el backend
    password: '',
    birthDate: '',
    address: '',
    roleId: '',
    pending: false,
    regular: true,
    idEntity: null,
  });
  const [entities, setEntities] = useState([]);
  const [showEntitySelect, setShowEntitySelect] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        idUser: user.idUser,
        firstName: user.firstName,
        lastName: user.lastName,
        nif: user.nif,
        email: user.email,
        contact: user.contact,  // Mantenido como 'contact' porque es necesario para el backend
        password: '',
        birthDate: user.birthDate,
        address: user.address,
        roleId: user.roleId,
        pending: user.pending,
        regular: user.regular,
        idEntity: user.idEntity,
      });
      setShowEntitySelect(user.roleId === 2); // Mostrar el select de entidades si el rol es "Oficina Entidad"
    }
  }, [user]);

  useEffect(() => {
    if (currentUser.roleId === 3 || currentUser.roleId === 5) {
      const fetchEntities = async () => {
        const response = await fetchUsersByRoleId(1); // Obtener todos los usuarios con rol "Entidad"
        setEntities(response);
      };
      fetchEntities();
    }
  }, [currentUser.roleId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
      lastName: name === 'lastName' && prevData.roleId !== 1 ? value : prevData.lastName,
    }));
  };

  const handleRoleChange = (event) => {
    const roleId = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      roleId,
      idEntity: roleId === 2 ? prevData.idEntity : null, // Limpiar idEntity si el rol no es Oficina Entidad
      lastName: roleId === 1 ? null : prevData.lastName, // lastName como null si es Entidad, sino se mantiene el valor
    }));
    setShowEntitySelect(roleId === 2); // Mostrar el select de entidades solo si se elige "Oficina Entidad"
  };

  const handleSubmit = async () => {
    try {
      const updatedUser = await saveUser(formData);
      onUserUpdated(updatedUser);
      onClose(); // Cerrar el modal después de la actualización
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle, overflow: 'auto', maxHeight: '90vh' }}>
        <h2>Editar Usuario</h2>
        <TextField
          label="Nombre"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Apellido"
          name="lastName"
          value={formData.lastName || ''}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled={formData.roleId === 1} // Deshabilita si el rol es "Entidad"
        />
        <TextField
          label="NIF"
          name="nif"
          value={formData.nif}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Teléfono"  // Cambiado de 'Contacto' a 'Teléfono'
          name="contact"  // Mantenido como 'contact' porque es necesario para el backend
          value={formData.contact}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Contraseña"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          placeholder="Dejar en blanco para mantener la contraseña actual"
        />
        <TextField
          label="Fecha de Nacimiento"
          name="birthDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formData.birthDate}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Dirección"
          name="address"
          value={formData.address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {currentUser.roleId !== 1 && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="role-label">Rol</InputLabel>
            <Select
              labelId="role-label"
              name="roleId"
              value={formData.roleId}
              onChange={handleRoleChange}
              fullWidth
            >
              {roles.map((role) => (
                <MenuItem key={role.idRole} value={role.idRole}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {(currentUser.roleId === 3 || currentUser.roleId === 5) && showEntitySelect && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="entity-label">Entidad</InputLabel>
            <Select
              labelId="entity-label"
              name="idEntity"
              value={formData.idEntity || ''}
              onChange={handleChange}
              fullWidth
            >
              {entities.map((entity) => (
                <MenuItem key={entity.idUser} value={entity.idUser}>
                  {entity.firstName} {entity.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {currentUser.roleId !== 1 && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.regular}
                  onChange={(e) => setFormData({ ...formData, regular: e.target.checked })}
                  name="regular"
                  color="primary"
                />
              }
              label="Usuario Regular"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.pending}
                  onChange={(e) => setFormData({ ...formData, pending: e.target.checked })}
                  name="pending"
                  color="primary"
                />
              }
              label="Usuario Pendiente"
            />
          </>
        )}
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          Guardar
        </Button>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default EditUserModal;
