import api from './apiConfig';

/**
 * Obtiene todos los segmentos de tarifas.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de segmentos de tarifas.
 */
export const fetchRateSegments = async () => {
  try {
    const response = await api.get(`/rate-segments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rate segments:', error);
    throw error;
  }
};

/**
 * Obtiene un segmento de tarifa por su ID.
 * @param {Number} id - ID del segmento de tarifa.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del segmento de tarifa.
 */
export const fetchRateSegmentById = async (id) => {
  try {
    const response = await api.get(`/rate-segments/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rate segment by ID:', error);
    throw error;
  }
};

/**
 * Obtiene los segmentos de tarifas por ID de tarifa.
 * @param {Number} idRate - ID de la tarifa.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de segmentos de tarifas asociados a la tarifa.
 */
export const fetchRateSegmentsByRateId = async (idRate) => {
  try {
    const response = await api.get(`/rate-segments/rate/${idRate}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rate segments by rate ID:', error);
    throw error;
  }
};

/**
 * Crea un nuevo segmento de tarifa.
 * @param {Object} rateSegmentData - Datos del segmento de tarifa a crear.
 * @returns {Promise<Object>} - La respuesta del servidor con el segmento de tarifa creado.
 */
export const createRateSegment = async (rateSegmentData) => {
  try {
    const response = await api.post(`/rate-segments`, rateSegmentData);
    return response.data;
  } catch (error) {
    console.error('Error creating rate segment:', error);
    throw error;
  }
};

/**
 * Actualiza un segmento de tarifa por su ID.
 * @param {Number} id - ID del segmento de tarifa.
 * @param {Object} rateSegmentData - Datos actualizados del segmento de tarifa.
 * @returns {Promise<Object>} - La respuesta del servidor con el segmento de tarifa actualizado.
 */
export const updateRateSegment = async (id, rateSegmentData) => {
  try {
    const response = await api.put(`/rate-segments/${id}`, rateSegmentData);
    return response.data;
  } catch (error) {
    console.error('Error updating rate segment:', error);
    throw error;
  }
};

/**
 * Elimina un segmento de tarifa por su ID.
 * @param {Number} id - ID del segmento de tarifa.
 * @returns {Promise<Object>} - La respuesta del servidor con la confirmación de eliminación.
 */
export const deleteRateSegment = async (id) => {
  try {
    const response = await api.delete(`/rate-segments/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting rate segment:', error);
    throw error;
  }
};
