import React from 'react';
import '../styles/pages.scss';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container">
      <h1 className="cookie-policy-title">Política de Cookies</h1>
      <p className="cookie-policy-text">
        En <span className="cookie-policy-brand-name">WeDelivery</span>, utilizamos cookies para mejorar tu experiencia en nuestra plataforma. Esta política de cookies explica qué son las cookies, cómo las usamos y las opciones que tienes con respecto a ellas.
      </p>

      <h2 className="cookie-policy-subtitle">¿Qué son las cookies?</h2>
      <p className="cookie-policy-text">
        Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web. Nos permiten reconocer tu navegador y recordar cierta información, como tus preferencias de usuario.
      </p>

      <h2 className="cookie-policy-subtitle">¿Cómo usamos las cookies?</h2>
      <p className="cookie-policy-text">
        Utilizamos cookies para varios propósitos, entre ellos:
      </p>
      <ul className="cookie-policy-list">
        <li>Recordar tus preferencias y configuraciones para mejorar tu experiencia en nuestra plataforma.</li>
        <li>Analizar cómo utilizas nuestro sitio web para mejorar continuamente nuestros servicios.</li>
        <li>Proporcionar contenido personalizado y relevante.</li>
      </ul>

      <h2 className="cookie-policy-subtitle">Tipos de cookies que utilizamos</h2>
      <p className="cookie-policy-text">
        Utilizamos los siguientes tipos de cookies en <span className="cookie-policy-brand-name">WeDelivery</span>:
      </p>
      <ul className="cookie-policy-list">
        <li><strong>Cookies esenciales:</strong> Necesarias para que nuestro sitio web funcione correctamente.</li>
        <li><strong>Cookies de rendimiento:</strong> Recopilan información sobre cómo utilizas nuestro sitio web para mejorar su funcionamiento.</li>
        <li><strong>Cookies de funcionalidad:</strong> Permiten recordar tus elecciones y proporcionar características mejoradas.</li>
        <li><strong>Cookies de publicidad:</strong> Ofrecen anuncios relevantes según tus intereses y miden la efectividad de campañas publicitarias.</li>
      </ul>

      <h2 className="cookie-policy-subtitle">Gestionar tus cookies</h2>
      <p className="cookie-policy-text">
        Puedes controlar y gestionar las cookies de varias maneras. Ten en cuenta que eliminar o bloquear cookies puede afectar tu experiencia de usuario.
      </p>
      <p className="cookie-policy-text">
        La mayoría de los navegadores permiten controlar las cookies a través de sus configuraciones. Para obtener más información, visita la configuración de tu navegador.
      </p>

      <h2 className="cookie-policy-subtitle">Cambios en nuestra política de cookies</h2>
      <p className="cookie-policy-text">
        Podemos actualizar esta política de cookies periódicamente. Te recomendamos revisar esta página para estar al tanto de cualquier cambio.
      </p>

      <h2 className="cookie-policy-subtitle">Contacto</h2>
      <p className="cookie-policy-text">
        Si tienes alguna pregunta sobre nuestra política de cookies, contacta con nosotros a través de <a href="mailto:tuemail@wedelivery.com" className="cookie-policy-contact-link">tuemail@wedelivery.com</a>.
      </p>
    </div>
  );
};

export default CookiePolicy;
