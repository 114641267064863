import api from './apiConfig';

/**
 * Obtener todas las transacciones de intercambio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todas las transacciones de intercambio.
 */
export const fetchTrades = async () => {
    try {
        const response = await api.get(`/trades`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las transacciones de intercambio:', error);
        throw error;
    }
};

/**
 * Obtener una transacción de intercambio por ID.
 * @param {Number|String} id - ID de la transacción de intercambio.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la transacción de intercambio.
 */
export const fetchTradeById = async (id) => {
    try {
        const response = await api.get(`/trades/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error al obtener la transacción de intercambio con ID ${id}:`, error);
        throw error;
    }
};

/**
 * Obtener todas las transacciones de intercambio asociadas a un servicio específico.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de transacciones de intercambio del servicio.
 */
export const fetchTradesByServiceId = async (serviceId) => {
    try {
        const response = await api.get(`/trades/service/${serviceId}`);
        return response.data;
    } catch (error) {
        console.error(`Error al obtener las transacciones de intercambio para el servicio con ID ${serviceId}:`, error);
        throw error;
    }
};

/**
 * Crear una nueva transacción de intercambio.
 * @param {Object} tradeData - Datos de la transacción de intercambio a crear.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la transacción de intercambio creada.
 */
export const createTrade = async (tradeData) => {
    try {
        const response = await api.post(`/trades`, tradeData);
        return response.data;
    } catch (error) {
        console.error('Error al crear la transacción de intercambio:', error);
        throw error;
    }
};

/**
 * Actualizar una transacción de intercambio por ID.
 * @param {Number|String} id - ID de la transacción de intercambio a actualizar.
 * @param {Object} tradeData - Datos actualizados de la transacción de intercambio.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la transacción de intercambio actualizada.
 */
export const updateTrade = async (id, tradeData) => {
    try {
        const response = await api.put(`/trades/${id}`, tradeData);
        return response.data;
    } catch (error) {
        console.error(`Error al actualizar la transacción de intercambio con ID ${id}:`, error);
        throw error;
    }
};

/**
 * Eliminar una transacción de intercambio por ID.
 * @param {Number|String} id - ID de la transacción de intercambio a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación de la transacción de intercambio.
 */
export const deleteTrade = async (id) => {
    try {
        await api.delete(`/trades/${id}`);
    } catch (error) {
        console.error(`Error al eliminar la transacción de intercambio con ID ${id}:`, error);
        throw error;
    }
};
