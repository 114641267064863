// src/pages/Rates.jsx
import React, { useState, useEffect } from 'react';
import {
  Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog,
  DialogActions, DialogContent, DialogTitle, TextField, Container,
  Typography, Paper, IconButton
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { fetchAllRates, saveRate, updateRate, deleteRateById } from '../services/api/rateApi';
import '../styles/pages.scss';

const RateForm = ({ open, onClose, rate, onSave }) => {
  const [formData, setFormData] = useState({
    idRate: null,
    rateType: '',
  });

  useEffect(() => {
    if (rate) {
      setFormData(rate);
    } else {
      setFormData({
        idRate: null,
        rateType: '',
      });
    }
  }, [rate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (formData.idRate) {
      await updateRate(formData.idRate, formData);
    } else {
      await saveRate(formData);
    }
    onSave();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{formData.idRate ? 'Editar Tarifa' : 'Crear Nueva Tarifa'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="rateType"
          label="Tipo de Tarifa"
          fullWidth
          value={formData.rateType}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

const Rates = () => {
  const [rates, setRates] = useState([]);
  const [editingRate, setEditingRate] = useState(null);
  const [rateFormOpen, setRateFormOpen] = useState(false);

  useEffect(() => {
    const fetchRates = async () => {
      const ratesData = await fetchAllRates();
      setRates(ratesData);
    };
    fetchRates();
  }, []);

  const handleNewRate = () => {
    setEditingRate(null);
    setRateFormOpen(true);
  };

  const handleEditRate = (rate) => {
    setEditingRate(rate);
    setRateFormOpen(true);
  };

  const handleDeleteRate = async (idRate) => {
    await deleteRateById(idRate);
    setRates(rates.filter((rate) => rate.idRate !== idRate));
  };

  const handleSave = async () => {
    const ratesData = await fetchAllRates();
    setRates(ratesData);
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gestión de Tarifas
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleNewRate}
          style={{ marginBottom: '20px' }}
        >
          Crear Nueva Tarifa
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tipo de Tarifa</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rates.map((rate) => (
              <TableRow key={rate.idRate}>
                <TableCell>{rate.idRate}</TableCell>
                <TableCell>{rate.rateType}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditRate(rate)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteRate(rate.idRate)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <RateForm open={rateFormOpen} onClose={() => setRateFormOpen(false)} rate={editingRate} onSave={handleSave} />
    </Container>
  );
};

export default Rates;
