import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import '../styles/pages.scss';

const PrivacyPolicy = () => {
  return (
    <div className="custom-privacy-policy-container">
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Typography variant="h4" gutterBottom className="custom-privacy-policy-title">
            Política de Privacidad
          </Typography>
        </Box>
        <Typography variant="body1" paragraph className="custom-privacy-policy-text">
          En WeDelivery, nos comprometemos a proteger su privacidad. Esta política de privacidad describe cómo recopilamos, usamos y protegemos su información personal cuando utiliza nuestra plataforma y servicios.
        </Typography>
        
        <Typography variant="h5" gutterBottom className="custom-privacy-policy-subtitle">
          Recopilación de Información
        </Typography>
        <Typography variant="body1" paragraph className="custom-privacy-policy-text">
          Recopilamos información personal que usted nos proporciona directamente, como su nombre, dirección de correo electrónico, número de teléfono, y cualquier otra información que nos envíe a través de la plataforma.
        </Typography>

        <Typography variant="h5" gutterBottom className="custom-privacy-policy-subtitle">
          Uso de la Información
        </Typography>
        <Typography variant="body1" paragraph className="custom-privacy-policy-text">
          Utilizamos su información personal para proporcionar y mejorar nuestros servicios, comunicarnos con usted, y cumplir con nuestras obligaciones legales. No compartiremos su información personal con terceros sin su consentimiento, excepto cuando sea necesario para prestar nuestros servicios o cumplir con la ley.
        </Typography>

        <Typography variant="h5" gutterBottom className="custom-privacy-policy-subtitle">
          Seguridad de la Información
        </Typography>
        <Typography variant="body1" paragraph className="custom-privacy-policy-text">
          Implementamos medidas de seguridad técnicas y organizativas para proteger su información personal contra el acceso no autorizado, la pérdida, el uso indebido o la divulgación.
        </Typography>

        <Typography variant="h5" gutterBottom className="custom-privacy-policy-subtitle">
          Sus Derechos
        </Typography>
        <Typography variant="body1" paragraph className="custom-privacy-policy-text">
          Usted tiene derecho a acceder, corregir o eliminar su información personal que tengamos en nuestra posesión. También puede oponerse al tratamiento de sus datos personales o solicitar la limitación de su uso. Para ejercer estos derechos, puede contactarnos en cualquier momento.
        </Typography>

        <Typography variant="h5" gutterBottom className="custom-privacy-policy-subtitle">
          Cambios en la Política de Privacidad
        </Typography>
        <Typography variant="body1" paragraph className="custom-privacy-policy-text">
          Podemos actualizar esta Política de Privacidad de vez en cuando para reflejar cambios en nuestras prácticas o en la legislación aplicable. Le notificaremos de cualquier cambio publicando la nueva política en nuestra plataforma.
        </Typography>

        <Typography variant="h5" gutterBottom className="custom-privacy-policy-subtitle">
          Contacto
        </Typography>
        <Typography variant="body1" paragraph className="custom-privacy-policy-text">
          Si tiene alguna pregunta sobre esta Política de Privacidad o sobre cómo manejamos su información personal, no dude en contactarnos a través de [Correo Electrónico de Contacto].
        </Typography>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
