import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  CircularProgress,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { fetchAllServices } from '../services/api/serviceApi';
import { uploadImage, fetchImagesByServiceId } from '../services/api/imageServiceApi';

const MAX_FILE_SIZE_MB = 10; // Tamaño máximo permitido para la imagen

const CreateImageModal = ({ open, onClose, setImages }) => {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [imageType, setImageType] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null); // Nuevo estado para la previsualización
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(null); // Estado para manejar errores de archivo

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchAllServices();
        setServices(response);
      } catch (error) {
        console.error('Error al cargar los servicios:', error);
      }
    };

    loadServices();
  }, []);

  // Resetear los valores cuando el modal se abre o se cierra
  useEffect(() => {
    if (!open) {
      // Limpiar todos los campos cuando el modal se cierra
      setSelectedServiceId('');
      setImageType('');
      setImageFile(null);
      setPreviewImageUrl(null);
      setFileError(null);
    }
  }, [open]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Validar el tamaño del archivo
    if (file) {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        setFileError(`El archivo supera el tamaño máximo de ${MAX_FILE_SIZE_MB} MB.`);
        setImageFile(null);
        setPreviewImageUrl(null);
      } else {
        setFileError(null);
        setImageFile(file);

        // Generar la URL de previsualización
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setPreviewImageUrl(null);
      setImageFile(null);
    }
  };

  const handleSaveImage = async () => {
    if (!selectedServiceId || !imageType || !imageFile) {
      alert("Por favor, complete todos los campos antes de guardar.");
      return;
    }

    setLoading(true);
    try {
      await uploadImage(imageFile, selectedServiceId, imageType);
      const updatedImages = await fetchImagesByServiceId(selectedServiceId);
      setImages(updatedImages);
      onClose();
    } catch (error) {
      console.error('Error al guardar la nueva imagen:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Añadir Nueva Imagen del Servicio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-service-label">Seleccionar Servicio</InputLabel>
              <Select
                labelId="select-service-label"
                value={selectedServiceId}
                onChange={(e) => setSelectedServiceId(e.target.value)}
              >
                {services.map(service => (
                  <MenuItem key={service.idService} value={service.idService}>
                    {`ID: ${service.idService} - Matrícula: ${service.vehiclePlate} - Fecha: ${new Date(service.createdAt).toLocaleDateString()}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="image-type-label">Tipo de Imagen</InputLabel>
              <Select
                labelId="image-type-label"
                value={imageType}
                onChange={(e) => setImageType(e.target.value)}
              >
                <MenuItem value="recogida">Recogida</MenuItem>
                <MenuItem value="entrega">Entrega</MenuItem>
                <MenuItem value="firma_conductor">Firma del Conductor</MenuItem>
                <MenuItem value="firma_receptor">Firma del Receptor</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              fullWidth
              disabled={loading}
            >
              Subir Imagen
              <input
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </Button>
            {fileError && (
              <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                {fileError}
              </Typography>
            )}
            {imageFile && (
              <Box mt={2}>
                <TextField
                  value={imageFile.name}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Box>
            )}
          </Grid>

          {previewImageUrl && (
            <Grid item xs={12}>
              <Box mt={2} display="flex" justifyContent="center">
                <img
                  src={previewImageUrl}
                  alt="Preview"
                  style={{ maxWidth: '100%', maxHeight: '300px' }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleSaveImage} color="primary" disabled={loading || !!fileError}>
          {loading ? <CircularProgress size={24} /> : 'Guardar Imagen'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateImageModal;
