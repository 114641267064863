import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box, Alert } from '@mui/material';
import { forgotPassword } from '../services/api/authApi';
import '../styles/global.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      setSuccess('El enlace de restablecimiento de contraseña se ha enviado a tu correo electrónico.');
      setError('');
    } catch (err) {
      setError('No se pudo enviar el correo electrónico de restablecimiento.');
      setSuccess('');
    }
  };

  return (
    <Container component="main" maxWidth="xs" className="forgot-password-container">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          backgroundColor: '#ffffff',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ marginBottom: '20px' }}>
          Restablecer Contraseña
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo Electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginBottom: '10px' }}
          />
          {error && <Alert severity="error" sx={{ marginBottom: '10px' }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ marginBottom: '10px' }}>{success}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              padding: '10px',
              backgroundColor: '#1976d2',
              ':hover': {
                backgroundColor: '#1565c0',
              },
            }}
          >
            Enviar Enlace de Restablecimiento
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
