import api from './apiConfig';

/**
 * Obtiene todos los gastos registrados en el sistema.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todos los gastos.
 */
export const fetchAllExpenses = async () => {
    try {
        const response = await api.get('/expenses');
        return response.data;
    } catch (error) {
        console.error('Error al obtener todos los gastos:', error);
        throw error;
    }
};

/**
 * Obtiene un gasto específico por su ID.
 * @param {Number|String} id - ID del gasto.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del gasto.
 */
export const fetchExpenseById = async (id) => {
    try {
        const response = await api.get(`/expenses/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener el gasto por ID:', error);
        throw error;
    }
};

/**
 * Obtiene todos los gastos asociados a un ID de servicio específico.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de gastos asociados al ID del servicio.
 */
export const fetchExpensesByServiceId = async (serviceId) => {
    try {
        const response = await api.get(`/expenses/service/${serviceId}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los gastos por ID de servicio:', error);
        throw error;
    }
};

/**
 * Obtiene todos los gastos asociados a un ID de usuario específico.
 * @param {Number|String} userId - ID del usuario.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de gastos asociados al ID del usuario.
 */
export const fetchExpensesByUserId = async (userId) => {
    try {
        const response = await api.get(`/expenses/user/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los gastos por ID de usuario:', error);
        throw error;
    }
};

/**
 * Actualiza un gasto específico por su ID.
 * @param {Number|String} id - ID del gasto a actualizar.
 * @param {Object} expense - Datos actualizados del gasto.
 * @param {File} [file] - Archivo opcional para actualizar la imagen del gasto.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del gasto actualizado.
 */
export const updateExpense = async (id, expense, file = null) => {
    const formData = new FormData();
    Object.keys(expense).forEach(key => {
        formData.append(key, expense[key]);
    });

    if (file) {
        formData.append('file', file);
    }

    try {
        const response = await api.put(`/expenses/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el gasto:', error);
        throw error;
    }
};

/**
 * Guarda un nuevo gasto en el sistema.
 * Si se proporciona un archivo, este se sube junto con los datos del gasto.
 * @param {Object} expenseData - Datos del nuevo gasto.
 * @param {File} [file] - Archivo opcional a subir.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del gasto guardado.
 */
export const saveExpense = async (expenseData, file = null) => {
    const formData = new FormData();
    Object.keys(expenseData).forEach(key => {
        formData.append(key, expenseData[key]);
    });

    if (file) {
        formData.append('file', file);
    }

    try {
        const response = await api.post('/expenses', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al guardar el gasto:', error);
        throw error;
    }
};

/**
 * Elimina un gasto específico por su ID.
 * @param {Number|String} id - ID del gasto a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación del gasto.
 */
export const deleteExpenseById = async (id) => {
    try {
        await api.delete(`/expenses/${id}`);
    } catch (error) {
        console.error('Error al eliminar el gasto por ID:', error);
        throw error;
    }
};

/**
 * Descarga la imagen de un gasto específico por su ID.
 * @param {Number|String} id - ID del gasto.
 * @returns {Promise<Blob>} - La respuesta del servidor con los datos de la imagen en formato Blob.
 */
export const fetchExpenseImage = async (id) => {
    try {
        const response = await api.get(`/expenses/image/${id}`, {
            responseType: 'blob' // This is important to ensure the response is a Blob (binary data)
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener la imagen del gasto:', error);
        throw error;
    }
};
