import React, { useEffect, useState } from 'react';
import { fetchUsersByRoleId } from '../services/api/userApi';
import { fetchAllPermissions, updatePermissions } from '../services/api/weDeliveryPermissionsApi';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, Button, Typography, Box } from '@mui/material';
import '../styles/pages.scss';

const PermissionManager = () => {
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchUsersAndPermissions = async () => {
      try {
        const usersResponse = await fetchUsersByRoleId(5); // Obtener usuarios del rol oficina_wedelivery
        setUsers(usersResponse);

        const permissionsResponse = await fetchAllPermissions(); // Obtener permisos de usuarios
        setPermissions(permissionsResponse);
      } catch (error) {
        console.error('Error al obtener usuarios o permisos:', error);
      }
    };

    fetchUsersAndPermissions();
  }, []);

  const getPermissionsForUser = (userId) => {
    return permissions.find((permission) => permission.userId === userId) || {};
  };

  const handlePermissionChange = (userId, field) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.userId === userId
          ? { ...permission, [field]: !permission[field] }
          : permission
      )
    );
  };

  const handleSave = async () => {
    try {
      await Promise.all(
        permissions.map((permission) =>
          updatePermissions(permission.id, permission)
        )
      );
      alert('Permisos actualizados con éxito');
    } catch (error) {
      console.error('Error al actualizar permisos:', error);
      alert('Error al actualizar permisos');
    }
  };

  return (
    <div className="permission-manager">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" className="main-title">Gestión de Permisos</Typography>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Cambios
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>NIF</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Ubicación del Conductor</TableCell>
              <TableCell>Tarifas</TableCell>
              <TableCell>Usuarios</TableCell>
              <TableCell>Informes</TableCell>
              <TableCell>Servicios</TableCell>
              <TableCell>Gastos</TableCell>
              <TableCell>Horas Extra</TableCell>
              <TableCell>Perfil</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              const userPermissions = getPermissionsForUser(user.idUser);
              return (
                <TableRow key={user.idUser}>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.nif}</TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.serviceStatus || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'serviceStatus')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.driverLocation || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'driverLocation')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.tarifas || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'tarifas')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.usuarios || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'usuarios')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.informes || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'informes')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.servicios || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'servicios')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.gastos || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'gastos')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.horasExtra || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'horasExtra')
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={userPermissions.perfil || false}
                      onChange={() =>
                        handlePermissionChange(user.idUser, 'perfil')
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PermissionManager;
