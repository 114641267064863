import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { connectNotificationService, disconnectNotificationService } from './services/NotificationService';  // Importar disconnectNotificationService
import { verifyTokenAndLoadUser } from './services/api/authApi';
import { ToastContainer } from 'react-toastify';
import { setCredentials, loadFromStorage } from './redux/authSlice';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login.jsx';
import DashboardAdmin from './components/dashboards/DashboardAdmin';
import DashboardConductor from './components/dashboards/DashboardConductor';
import DashboardEntidad from './components/dashboards/DashboardEntidad';
import DashboardOficinaEntidad from './components/dashboards/DashboardOficinaEntidad';
import DashboardOficinaWeDelivery from './components/dashboards/DashboardOficinaWeDelivery';
import ProtectedRoute from './components/ProtectedRoute';
import Profile from './components/common/Profile.jsx';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LegalNotice from './pages/LegalNotice';

function App() {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);

  useEffect(() => {
    // Cargar token y usuario desde storage solo una vez al iniciar la app
    if (!token) {
      dispatch(loadFromStorage());
    }

    if (token && !user) {
      const loadUser = async () => {
        try {
          const user = await verifyTokenAndLoadUser();
          dispatch(setCredentials({ token, user }));
        } catch (error) {
          console.error('Failed to load user from token:', error);
        }
      };
      loadUser();
    }
  }, [dispatch, token, user]);

  // Conecta el servicio de notificaciones cuando la app se monta
  useEffect(() => {
    if (user) {
      connectNotificationService(user.idUser, user.roleId);
    }

    // Cleanup function to disconnect the WebSocket and clear subscriptions when the component unmounts
    return () => {
      disconnectNotificationService();  // Limpiar suscripciones y desconectar el WebSocket
    };
  }, [user]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/*" element={<ResetPassword />} />
        <Route
          path="admin/*"
          element={<ProtectedRoute component={DashboardAdmin} token={token} role={user?.roleId} requiredRole={3} />}
        />
        <Route
          path="conductor/*"
          element={<ProtectedRoute component={DashboardConductor} token={token} role={user?.roleId} requiredRole={4} />}
        />
        <Route
          path="entidad/*"
          element={<ProtectedRoute component={DashboardEntidad} token={token} role={user?.roleId} requiredRole={1} />}
        />
        <Route
          path="oficina-entidad/*"
          element={<ProtectedRoute component={DashboardOficinaEntidad} token={token} role={user?.roleId} requiredRole={2} />}
        />
        <Route
          path="oficina-wedelivery/*"
          element={<ProtectedRoute component={DashboardOficinaWeDelivery} token={token} role={user?.roleId} requiredRole={5} />}
        />
        <Route
          path="perfil"
          element={<ProtectedRoute component={Profile} token={token} role={user?.roleId} requiredRole={[1, 2, 3, 4, 5]} />}
        />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="legal-notice" element={<LegalNotice />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App;
