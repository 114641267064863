import React, { useEffect, useState } from 'react';
import { fetchAllServices } from '../services/api/serviceApi';
import { fetchUserById } from '../services/api/userApi';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from '@mui/material';
import serviceStatusConst from '../constants/serviceStatus';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import '../styles/pages.scss'; // Importamos los estilos de forma general

// Definimos los colores para cada estado de servicio
const statusColors = {
  PENDING: '#FFD700', // Gold
  REJECTED: '#FF6347', // Tomato
  ASSIGNED: '#87CEEB', // Sky Blue
  IN_PROGRESS: '#1E90FF', // Dodger Blue
  PENDING_REVIEW: '#FF8C00', // Dark Orange
  COMPLETED: '#32CD32', // Lime Green
  FAILED: '#DC143C', // Crimson
  CANCELLED: '#808080', // Gray
};

// Componente para los botones de estado
const StatusButton = styled(Button)(({ status, selected }) => ({
  backgroundColor: selected ? statusColors[status] : '#D3D3D3',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '20px',
  margin: '5px',
  padding: '10px 20px',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: selected ? statusColors[status] : '#A9A9A9',
  },
}));

const Services = () => {
  const [services, setServices] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState(Object.keys(serviceStatusConst));
  const [userMap, setUserMap] = useState({});

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchAllServices();

        // Filtrar los servicios según el rol del usuario
        let filteredServices = response;
        if (user?.roleId === 1) {
          // Rol Entidad
          filteredServices = response.filter(service => service.clientId === user.idUser);
        } else if (user?.roleId === 2) {
          // Rol Oficina Entidad
          filteredServices = response.filter(service => service.clientId === user.idEntity);
        }

        setServices(filteredServices);

        // Mapeo de usuarios para mostrar el nombre del creador del servicio
        const userIds = [...new Set(filteredServices.map(service => service.userIdCreator))];
        const userMapTemp = {};
        for (const userId of userIds) {
          const user = await fetchUserById(userId);
          userMapTemp[userId] = `${user.firstName} ${user.lastName || ''}`.trim();
        }
        setUserMap(userMapTemp);
      } catch (error) {
        console.error('Error fetching services or users:', error);
      }
    };

    loadServices();
  }, [user]);

  const toggleStatusFilter = (status) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter(s => s !== status));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const roundToTwoDecimals = (num) => parseFloat(num).toFixed(2);

  const filteredServices = services.filter(service => selectedStatuses.includes(service.serviceStatus));

  return (
    <div className="container">
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        className="page-title"
      >
        Gestión de Servicios
      </Typography>

      <Box mb={4} display="flex" justifyContent="center" flexWrap="wrap" className="status-buttons">
        {Object.keys(serviceStatusConst).map((status) => (
          <StatusButton
            key={status}
            status={status}
            selected={selectedStatuses.includes(status)}
            onClick={() => toggleStatusFilter(status)}
          >
            {serviceStatusConst[status]}
          </StatusButton>
        ))}
      </Box>

      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>Fecha del Servicio</TableCell>
              <TableCell>Hora del Servicio</TableCell>
              <TableCell>Matrícula del Vehículo</TableCell>
              <TableCell>Marca del Vehículo</TableCell>
              <TableCell>Modelo del Vehículo</TableCell>
              <TableCell>Color del Vehículo</TableCell>
              <TableCell>Fecha de Recepción</TableCell>
              <TableCell>Dirección de Recepción</TableCell>
              <TableCell>Dirección de Entrega</TableCell>
              <TableCell>Nombre del Receptor</TableCell>
              <TableCell>NIF del Receptor</TableCell>
              <TableCell>Precio Base</TableCell>
              <TableCell>Kilometraje Inicial</TableCell>
              <TableCell>Kilometraje Final</TableCell>
              <TableCell>Kilometraje Total</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Estado del Vehículo</TableCell>
              <TableCell>Creado en</TableCell>
              <TableCell>Actualizado en</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Comentarios Recepción</TableCell>
              <TableCell>Comentarios Entrega</TableCell>
              <TableCell>Creador del Servicio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredServices.map((service) => (
              <TableRow
                key={service.idService}
                style={{ backgroundColor: statusColors[service.serviceStatus] }}
              >
                <TableCell>{formatDate(service.serviceDate)}</TableCell>
                <TableCell>{service.serviceTime}</TableCell>
                <TableCell>{service.vehiclePlate}</TableCell>
                <TableCell>{service.vehicleBrand}</TableCell>
                <TableCell>{service.vehicleModel}</TableCell>
                <TableCell>{service.vehicleColor}</TableCell>
                <TableCell>{formatDate(service.receptionDate)}</TableCell>
                <TableCell>{service.receptionAddress}</TableCell>
                <TableCell>{service.deliveryAddress}</TableCell>
                <TableCell>{service.recipientName}</TableCell>
                <TableCell>{service.recipientNif}</TableCell>
                <TableCell>{roundToTwoDecimals(service.basePrice)}</TableCell>
                <TableCell>{service.initialKm}</TableCell>
                <TableCell>{service.finalKm}</TableCell>
                <TableCell>{service.totalKm}</TableCell>
                <TableCell>{service.serviceStatus}</TableCell>
                <TableCell>{service.vehicleStatus}</TableCell>
                <TableCell>{formatDate(service.createdAt)}</TableCell>
                <TableCell>{formatDate(service.updatedAt)}</TableCell>
                <TableCell>{roundToTwoDecimals(service.vatPrice)}</TableCell>
                <TableCell>{service.reEntityComments}</TableCell>
                <TableCell>{service.eentityComments}</TableCell>
                <TableCell>{userMap[service.userIdCreator]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Services;
