import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SidebarConductor from '../sidebars/SidebarConductor';
import Profile from '../common/Profile';
import ReportsConductor from '../../pages/ReportsConductor'; // Componente de informes específico para conductores
import WelcomeMessage from '../WelcomeMessage'; // Importar el componente de mensaje de bienvenida
import { useSelector } from 'react-redux'; // Importar useSelector para acceder al estado del usuario
import CookiePolicy from '../../pages/CookiePolicy';
import LegalNotice from '../../pages/LegalNotice';
import PrivacyPolicy from '../../pages/PrivacyPolicy';

import '../../styles/components.scss';

const DashboardConductor = () => {
  // Obtener el usuario desde el estado de Redux
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="dashboard">
      <SidebarConductor />
      <div className="content">
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="reports" element={<ReportsConductor />} />
          <Route path='cookie-policy' element={<CookiePolicy />} />
          <Route path='legal-notice' element={<LegalNotice />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          {/* Ruta por defecto que muestra el mensaje de bienvenida */}
          <Route
            path="/"
            element={<WelcomeMessage firstName={user?.firstName} lastName={user?.lastName} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default DashboardConductor;
