import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SidebarAdmin from '../sidebars/SidebarAdmin';
import Users from '../../pages/Users';
import ServiceStatusManagement from '../../pages/ServiceStatusManagement';
import Rates from '../../pages/Rates';
import RateSegments from '../../pages/RateSegments';
import RateEntities from '../../pages/RateEntities';
import Reports from '../../pages/Reports';
import ExtraHours from '../../pages/ExtraHours';
import PermissionManager from '../../pages/PermissionManager';
import Profile from '../common/Profile';
import UsersPending from '../../pages/UsersPending';
import InactiveUsers from '../../pages/InactiveUsers';
import CreateServices from '../../pages/CreateServices';
import ServicesPending from '../../pages/ServicesPending';
import ServicesAssigned from '../../pages/ServicesAssigned';
import ServicesInProgress from '../../pages/ServicesInProgress';
import ServicesPendingReview from '../../pages/ServicesPendingReview';
import ServicesCompleted from '../../pages/ServicesCompleted';
import Expenses from '../../pages/Expenses';
import Services from '../../pages/Services';
import CookiePolicy from '../../pages/CookiePolicy';
import LegalNotice from '../../pages/LegalNotice';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import WelcomeMessage from '../WelcomeMessage';
import { useSelector } from 'react-redux';
import EditServices from '../../pages/EditServices';
import ServicesImages from '../../pages/ServicesImages';
import DriversLocations from '../../pages/DriversLocations'; 
import DriverServices from '../../pages/DriverServices'; 
import Trades from '../../pages/Trades';  // Importar la nueva página de Trades

import '../../styles/components.scss';

const DashboardAdmin = () => {
  // Obtener el usuario desde el estado de Redux
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="dashboard">
      <SidebarAdmin />
      <div className="content">
        <Routes>
          <Route path="create-services" element={<CreateServices />} />
          <Route path="users" element={<Users />} />
          <Route path="services" element={<Services />} />
          <Route path="service-status" element={<ServiceStatusManagement />} />
          <Route path="rates" element={<Rates />} />
          <Route path="rate-segments" element={<RateSegments />} />
          <Route path="rate-entities" element={<RateEntities />} />
          <Route path="reports" element={<Reports />} />
          <Route path="extra-hours" element={<ExtraHours />} />
          <Route path="permission-manager" element={<PermissionManager />} />
          <Route path="profile" element={<Profile />} />
          <Route path="services-pending" element={<ServicesPending />} />
          <Route path="services-assigned" element={<ServicesAssigned />} />
          <Route path="services-in-progress" element={<ServicesInProgress />} />
          <Route path="services-pending-review" element={<ServicesPendingReview />} />
          <Route path="services-completed" element={<ServicesCompleted />} />
          <Route path="edit-services" element={<EditServices />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="users-pending" element={<UsersPending />} />
          <Route path="inactive-users" element={<InactiveUsers />} />
          <Route path='cookie-policy' element={<CookiePolicy />} />
          <Route path='legal-notice' element={<LegalNotice />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path="services-images" element={<ServicesImages />} />
          <Route path="driver-location" element={<DriversLocations />} /> 
          <Route path="driver-services" element={<DriverServices />} />  
          <Route path="trades" element={<Trades />} />  {/* Añadir la ruta de Trades */}
          <Route
            path="/"
            element={<WelcomeMessage firstName={user?.firstName} lastName={user?.lastName} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default DashboardAdmin;
