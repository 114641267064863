import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { deleteUserById, fetchUsersByRoleId, fetchAllUsers } from '../services/api/userApi';
import { fetchRoles } from '../services/api/roleApi';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Typography, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateUserModal from './CreateUserModal';
import EditUserModal from './EditUserModal';
import UserServicesModal from './UserServicesModal';
import '../styles/pages.scss';

const roleMapping = {
    1: 'Entidad',
    2: 'Oficina Entidad',
    3: 'Admin',
    4: 'Conductor',
    5: 'Oficina WeDelivery',
};

const Users = () => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [servicesModalOpen, setServicesModalOpen] = useState(false);
    const { user } = useSelector((state) => state.auth);

    // Función para cargar usuarios y roles, envuelta en useCallback
    const fetchUsersAndRoles = useCallback(async () => {
        try {
            const rolesResponse = await fetchRoles();
            setRoles(rolesResponse);

            if (user.roleId === 1) {
                const oficinaEntidadUsers = await fetchUsersByRoleId(2);
                const filteredUsers = oficinaEntidadUsers.filter(u => u.idEntity === user.idUser);
                setUsers(filteredUsers);
            } else {
                const allUsers = await fetchAllUsers();
                setUsers(allUsers);
            }
        } catch (error) {
            console.error('Error fetching users or roles:', error);
        }
    }, [user.roleId, user.idUser]);

    useEffect(() => {
        fetchUsersAndRoles();
    }, [fetchUsersAndRoles]);

    const handleOpenCreateModal = () => {
        setCreateModalOpen(true);
    };

    const handleOpenEditModal = (user) => {
        setSelectedUser(user);
        setEditModalOpen(true);
    };

    const handleOpenServicesModal = (user) => {
        setSelectedUser(user);
        setServicesModalOpen(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            if (userId !== user.idUser) {
                await deleteUserById(userId);
                await fetchUsersAndRoles(); // Recargar la lista después de eliminar
            } else {
                console.warn('No se puede eliminar el usuario actual.');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleUserCreated = async () => {
        await fetchUsersAndRoles(); // Recargar la lista después de crear un usuario
        setCreateModalOpen(false);
    };

    const handleUserUpdated = async () => {
        await fetchUsersAndRoles(); // Recargar la lista después de editar un usuario
        setEditModalOpen(false);
    };

    const renderUserTables = () => {
        return roles.map((role) => {
            const usersByRole = users.filter(u => u.roleId === role.idRole);

            if (usersByRole.length === 0) return null; // No mostrar tabla si no hay usuarios para el rol

            return (
                <Box key={role.idRole} sx={{ marginBottom: 4 }}>
                    <Typography variant="h6" gutterBottom className="role-title">
                        {roleMapping[role.idRole]}
                    </Typography>
                    <UserList
                        users={usersByRole}
                        onEdit={handleOpenEditModal}
                        onDelete={handleDeleteUser}
                        onViewServices={handleOpenServicesModal}
                        currentUser={user}
                    />
                </Box>
            );
        });
    };

    return (
        <div className="container">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4" className="main-title">Gestión de Usuarios</Typography>
                {(user.roleId === 1 || user.roleId === 3 || user.roleId === 5) && (
                    <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>
                        Registrar nuevo usuario
                    </Button>
                )}
            </Box>

            {renderUserTables()}

            <CreateUserModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onUserCreated={handleUserCreated}
                roles={roles}
            />
            <EditUserModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                onUserUpdated={handleUserUpdated}
                user={selectedUser}
                roles={roles}
            />
            <UserServicesModal
                open={servicesModalOpen}
                onClose={() => setServicesModalOpen(false)}
                userId={selectedUser?.idUser}
            />
        </div>
    );
};

const UserList = ({ users, onEdit, onDelete, onViewServices, currentUser }) => {
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };

    return (
        <TableContainer component={Paper} className="table-container">
            <Table>
                <TableHead className="table-header">
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Apellido</TableCell>
                        <TableCell>NIF</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Teléfono</TableCell> {/* Cambiado de 'Contacto' a 'Teléfono' */}
                        <TableCell>Rol</TableCell>
                        <TableCell>Fecha de Nacimiento</TableCell>
                        <TableCell>Dirección</TableCell>
                        <TableCell>Fecha de Creación</TableCell>
                        <TableCell>Fecha de Actualización</TableCell>
                        <TableCell>Regular</TableCell>
                        <TableCell>Pendiente</TableCell>
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.idUser} className="table-row" onClick={() => onViewServices(user)}>
                            <TableCell>{user.firstName}</TableCell>
                            <TableCell>{user.lastName}</TableCell>
                            <TableCell>{user.nif}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.contact}</TableCell> {/* Manteniendo 'contact' para el backend */}
                            <TableCell>{roleMapping[user.roleId]}</TableCell>
                            <TableCell>{formatDate(user.birthDate)}</TableCell>
                            <TableCell>{user.address}</TableCell>
                            <TableCell>{formatDate(user.createdAt)}</TableCell>
                            <TableCell>{formatDate(user.updatedAt)}</TableCell>
                            <TableCell>{user.regular ? 'Sí' : 'No'}</TableCell>
                            <TableCell>{user.pending ? 'Sí' : 'No'}</TableCell>
                            <TableCell>
                                <IconButton onClick={(e) => { e.stopPropagation(); onEdit(user); }}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    onClick={(e) => { e.stopPropagation(); onDelete(user.idUser); }}
                                    disabled={user.idUser === currentUser.idUser}
                                    style={{
                                        opacity: user.idUser === currentUser.idUser ? 0.6 : 1,
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Users;
