// src/constants/serviceStatus.js
const serviceStatusConst = {
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    ASSIGNED: 'ASSIGNED',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_REVIEW: 'PENDING_REVIEW',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    CANCELLED: 'CANCELLED',
  };
  
  export default serviceStatusConst;
  