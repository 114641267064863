import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Snackbar, Alert } from '@mui/material';
import { fetchUserProfile, updateUserProfile } from '../../services/api/userApi';

const Profile = () => {
  const { user, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    nif: '',
    email: '',
    contact: '',
    birthDate: '',
    address: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (!token) {
      navigate('/');
      return;
    }

    const getUserProfile = async () => {
      try {
        const data = await fetchUserProfile(user.idUser);
        setProfileData(data);
      } catch (error) {
        console.error('Error al obtener el perfil del usuario:', error);
      }
    };

    getUserProfile();
  }, [user, token, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserProfile(user.idUser, profileData);
      setSnackbarMessage('Perfil actualizado con éxito');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      setSnackbarMessage('Error al actualizar el perfil');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Perfil
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nombre"
          name="firstName"
          value={profileData.firstName}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Apellido"
          name="lastName"
          value={profileData.lastName}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="NIF"
          name="nif"
          value={profileData.nif}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Correo Electrónico"
          name="email"
          value={profileData.email}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Teléfono de Contacto"
          name="contact"
          value={profileData.contact}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Fecha de Nacimiento"
          name="birthDate"
          type="date"
          value={profileData.birthDate}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Dirección"
          name="address"
          value={profileData.address}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Actualizar Perfil
        </Button>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Profile;
