import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, Box, Typography } from '@mui/material';
import { fetchServicesByStatus, updateServiceStatus } from '../services/api/serviceApi';
import { uploadImage } from '../services/api/imageServiceApi';
import { useSelector } from 'react-redux';

const MAX_FILE_SIZE_MB = 10; // Tamaño máximo del archivo en MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const ServicesAssigned = () => {
  const [assignedServices, setAssignedServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [files, setFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(''); // Nuevo estado para controlar los errores de tamaño
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchAssignedServices = async () => {
      try {
        const services = await fetchServicesByStatus('ASSIGNED');

        // Filtrar servicios según el rol del usuario
        let filteredServices = services;
        if (user.roleId === 1) {
          filteredServices = services.filter(service => service.clientId === user.idUser);
        } else if (user.roleId === 2) {
          filteredServices = services.filter(service => service.clientId === user.idEntity);
        }

        setAssignedServices(filteredServices);
      } catch (error) {
        console.error('Error fetching assigned services:', error);
      }
    };

    fetchAssignedServices();
  }, [user]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const previewUrls = [];

    // Reiniciar el error de tamaño de archivo
    setFileSizeError('');

    selectedFiles.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE_BYTES) {
        validFiles.push(file);
        previewUrls.push(URL.createObjectURL(file));
      } else {
        setFileSizeError(`El archivo ${file.name} excede el límite de ${MAX_FILE_SIZE_MB}MB.`);
      }
    });

    setFiles(validFiles);
    setPreviewImages(previewUrls);
  };

  const handleStartService = async () => {
    if (!selectedService || files.length === 0) return;

    try {
      const uploadResponses = await Promise.all(
        files.map(file =>
          uploadImage(file, selectedService.idService, 'recogida')
        )
      );

      console.log('Uploaded images:', uploadResponses);

      const updatedService = await updateServiceStatus(selectedService.idService, 'IN_PROGRESS');
      console.log('Service started:', updatedService);

      const updatedServices = await fetchServicesByStatus('ASSIGNED');
      setAssignedServices(updatedServices);

      handleCloseModal();
    } catch (error) {
      console.error('Error starting service:', error);
    }
  };

  const handleOpenModal = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
    setFiles([]);
    setPreviewImages([]);
    setFileSizeError(''); // Reiniciar el error al cerrar el modal
  };

  return (
    <div>
      <h1>Servicios Asignados</h1>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>Matrícula del Vehículo</TableCell>
              <TableCell>Marca del Vehículo</TableCell>
              <TableCell>Modelo del Vehículo</TableCell>
              <TableCell>Color del Vehículo</TableCell>
              <TableCell>Fecha de Recepción</TableCell>
              <TableCell>Dirección de Recepción</TableCell>
              <TableCell>Dirección de Entrega</TableCell>
              <TableCell>Nombre del Receptor</TableCell>
              <TableCell>NIF del Receptor</TableCell>
              <TableCell>Precio Base</TableCell>
              <TableCell>Kilometraje Inicial</TableCell>
              <TableCell>Kilometraje Final</TableCell>
              <TableCell>Kilometraje Total</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Estado del Vehículo</TableCell>
              <TableCell>Usuario Creador</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignedServices.map((service) => (
              <TableRow key={service.idService} className="table-row">
                <TableCell>{service.vehiclePlate}</TableCell>
                <TableCell>{service.vehicleBrand}</TableCell>
                <TableCell>{service.vehicleModel}</TableCell>
                <TableCell>{service.vehicleColor}</TableCell>
                <TableCell>{service.receptionDate}</TableCell>
                <TableCell>{service.receptionAddress}</TableCell>
                <TableCell>{service.deliveryAddress}</TableCell>
                <TableCell>{service.recipientName}</TableCell>
                <TableCell>{service.recipientNif}</TableCell>
                <TableCell>{service.basePrice}</TableCell>
                <TableCell>{service.initialKm}</TableCell>
                <TableCell>{service.finalKm}</TableCell>
                <TableCell>{service.totalKm}</TableCell>
                <TableCell>{service.serviceStatus}</TableCell>
                <TableCell>{service.vehicleStatus}</TableCell>
                <TableCell>{service.userIdCreator}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleOpenModal(service)}>
                    Iniciar Servicio
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="start-service-modal-title"
        aria-describedby="start-service-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4 }}>
          <Typography id="start-service-modal-title" variant="h6" component="h2">
            Iniciar Servicio para {selectedService?.vehiclePlate}
          </Typography>
          <input 
            type="file" 
            multiple 
            onChange={handleFileChange} 
          />
          {fileSizeError && (
            <Typography color="error" sx={{ mt: 1 }}>
              {fileSizeError}
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
            {previewImages.map((src, index) => (
              <img key={index} src={src} alt={`Preview ${index + 1}`} style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: '8px' }} />
            ))}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleStartService} disabled={files.length === 0 || !!fileSizeError}>
              Subir Imágenes & Iniciar
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ ml: 2 }}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ServicesAssigned;
