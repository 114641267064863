import api from './apiConfig';

/**
 * Obtiene todas las imágenes del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todas las imágenes.
 */
export const fetchAllImages = async () => {
  try {
    const response = await api.get('/images');
    return response.data;
  } catch (error) {
    console.error('Error al obtener todas las imágenes:', error);
    throw error;
  }
};

/**
 * Obtiene una imagen del servicio por su ID.
 * @param {Number|String} id - ID de la imagen.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la imagen.
 */
export const fetchImageById = async (id) => {
  try {
    const response = await api.get(`/images/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener la imagen con ID ${id}:`, error);
    throw error;
  }
};

/**
 * Obtiene las imágenes asociadas a un servicio específico por el ID del servicio.
 * @param {Number|String} serviceId - ID del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de imágenes del servicio.
 */
export const fetchImagesByServiceId = async (serviceId) => {
  try {
    const response = await api.get(`/images/service/${serviceId}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener imágenes para el ID del servicio ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Guarda una nueva imagen del servicio.
 * @param {Object} imageData - Datos de la imagen a guardar.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la imagen guardada.
 */
export const saveImage = async (imageData) => {
  try {
    const response = await api.post('/images', imageData);
    return response.data;
  } catch (error) {
    console.error('Error al guardar la imagen:', error);
    throw error;
  }
};

/**
 * Actualiza una imagen existente del servicio.
 * @param {Number|String} idImage - ID de la imagen.
 * @param {FormData} formData - Datos del formulario que contienen el archivo de imagen y otros datos.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la imagen actualizada.
 */
export const updateImageService = async (idImage, formData) => {
  try {
    const response = await api.put(`/images/${idImage}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar la imagen con ID ${idImage}:`, error);
    throw error;
  }
};

/**
 * Elimina una imagen del servicio por su ID.
 * @param {Number|String} id - ID de la imagen a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación de la imagen.
 */
export const deleteImageById = async (id) => {
  try {
    await api.delete(`/images/${id}`);
  } catch (error) {
    console.error(`Error al eliminar la imagen con ID ${id}:`, error);
    throw error;
  }
};

/**
 * Sube una nueva imagen del servicio.
 * @param {File} file - Archivo de imagen a subir.
 * @param {Number|String} serviceId - ID del servicio asociado.
 * @param {Number} imageNumber - Número de la imagen.
 * @param {String} imageType - Tipo de la imagen (e.g., 'recogida', 'entrega').
 * @returns {Promise<String>} - La URL de la imagen subida.
 */
export const uploadImage = async (file, serviceId, imageType) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('serviceId', serviceId);
    formData.append('imageType', imageType);  // Asegúrate de usar 'imageType' aquí

    const response = await api.post('/images/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al subir la imagen:', error);
    throw error;
  }
};

/**
 * Elimina una imagen del servicio por su nombre de archivo.
 * @param {String} fileName - Nombre del archivo de la imagen a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación de la imagen.
 */
export const deleteImageByName = async (fileName) => {
  try {
    await api.delete(`/images/delete/${fileName}`);
  } catch (error) {
    console.error(`Error al eliminar la imagen con nombre ${fileName}:`, error);
    throw error;
  }
};

/**
 * Descarga la imagen de un servicio por su ID.
 * @param {Number|String} idImage - ID de la imagen.
 * @returns {Promise<Blob>} - La imagen en formato blob.
 */
export const downloadImageById = async (idImage) => {
  try {
    const response = await api.get(`/images/download/${idImage}`, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error(`Error al descargar la imagen con ID ${idImage}:`, error);
    throw error;
  }
};
