import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { fetchTrades, deleteTrade } from '../services/api/tradeApi'; 
import CreateTradeModal from './CreateTradeModal';
import EditTradeModal from './EditTradeModal';

const Trades = () => {
  const [trades, setTrades] = useState([]);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const loadTrades = async () => {
    try {
      const response = await fetchTrades();
      setTrades(response);
    } catch (error) {
      console.error('Error al cargar los trades:', error);
    }
  };

  useEffect(() => {
    loadTrades();
  }, []);

  const handleDeleteTrade = async (idTrade) => {
    try {
      await deleteTrade(idTrade);
      loadTrades(); 
    } catch (error) {
      console.error('Error al eliminar el trade:', error);
    }
  };

  const handleEditTrade = (trade) => {
    setSelectedTrade(trade);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    loadTrades();
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    loadTrades();
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Gestión de Vehículos de Intercambio
      </Typography>

      <Grid container justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowCreateModal(true)}
        >
          Añadir Nuevo Vehículo de Intercambio
        </Button>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID Vehículo</TableCell>
              <TableCell>Matrícula</TableCell>
              <TableCell>Marca</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Año</TableCell>
              <TableCell>Dirección de Entrega</TableCell>
              <TableCell>Nombre del Receptor</TableCell>
              <TableCell>NIF del Receptor</TableCell>
              <TableCell>Kilometraje Inicial</TableCell>
              <TableCell>Kilometraje Final</TableCell>
              <TableCell>Total Kilometraje</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades.map((trade) => (
              <TableRow key={trade.idTrade}>
                <TableCell>{trade.idTrade}</TableCell>
                <TableCell>{trade.tradeVehiclePlate}</TableCell>
                <TableCell>{trade.tradeVehicleBrand}</TableCell>
                <TableCell>{trade.tradeVehicleModel}</TableCell>
                <TableCell>{trade.tradeVehicleColor}</TableCell>
                <TableCell>{trade.tradeVehicleYear}</TableCell>
                <TableCell>{trade.deliveryAddressTrade}</TableCell>
                <TableCell>{trade.recipientNameTrade}</TableCell>
                <TableCell>{trade.recipientNifTrade}</TableCell>
                <TableCell>{trade.initialKmTrade}</TableCell>
                <TableCell>{trade.finalKmTrade}</TableCell>
                <TableCell>{trade.totalKmTrade}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditTrade(trade)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteTrade(trade.idTrade)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedTrade && (
        <EditTradeModal
          open={showEditModal}
          onClose={handleCloseEditModal}
          trade={selectedTrade}
          setTrades={setTrades}
        />
      )}

      <CreateTradeModal
        open={showCreateModal}
        onClose={handleCloseCreateModal}
      />
    </Container>
  );
};

export default Trades;
