import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  Button,
} from '@mui/material';
import { fetchAllExpenses, deleteExpenseById, fetchExpenseImage } from '../services/api/expenseApi';
import { fetchAllServices } from '../services/api/serviceApi';
import { fetchUsersByRoleId } from '../services/api/userApi';
import CreateExpensesModal from './CreateExpensesModal';
import EditExpensesModal from './EditExpensesModal';
import { Image, Edit, Delete } from '@mui/icons-material';
import '../styles/pages.scss';

const ExpenseImageModal = ({ open, onClose, imageUrl }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Imagen del Gasto</DialogTitle>
    <DialogContent>
      {imageUrl ? (
        <img src={imageUrl} alt="Expense" style={{ width: '100%', height: 'auto' }} />
      ) : (
        <p>No se pudo cargar la imagen.</p>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="contained">Cerrar</Button>
    </DialogActions>
  </Dialog>
);

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [services, setServices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [entities, setEntities] = useState([]);
  const [filters, setFilters] = useState({
    serviceId: '',
    driverId: '',
    entityId: ''
  });
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [expensesData, servicesData, driversResponse, entitiesResponse] = await Promise.all([
          fetchAllExpenses(),
          fetchAllServices(),
          fetchUsersByRoleId(4), // Role ID 4 for drivers
          fetchUsersByRoleId(1)  // Role ID 1 for entities
        ]);
        setExpenses(expensesData);
        setFilteredExpenses(expensesData);
        setServices(servicesData);
        setDrivers(driversResponse);
        setEntities(entitiesResponse);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleOpenEditModal = (expense) => {
    setSelectedExpense(expense);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedExpense(null);
  };

  const handleDeleteExpense = async (idExpense) => {
    try {
      await deleteExpenseById(idExpense);
      const updatedExpenses = expenses.filter(expense => expense.idExpense !== idExpense);
      setExpenses(updatedExpenses);
      setFilteredExpenses(updatedExpenses);
    } catch (error) {
      console.error('Error deleting expense', error);
    }
  };

  const handleFiltersChange = (newFilters) => {
    const applyFilters = () => {
      let filtered = expenses;

      if (newFilters.serviceId) {
        filtered = filtered.filter(expense => expense.serviceId === parseInt(newFilters.serviceId));
      }

      if (newFilters.driverId) {
        filtered = filtered.filter(expense => expense.userId === parseInt(newFilters.driverId));
      }

      if (newFilters.entityId) {
        filtered = filtered.filter(expense => {
          const service = services.find(service => service.idService === expense.serviceId);
          return service && service.clientId === parseInt(newFilters.entityId);
        });
      }

      setFilteredExpenses(filtered);
    };

    applyFilters();
  };

  const handleExpenseCreated = (newExpense) => {
    const updatedExpenses = [...expenses, newExpense];
    setExpenses(updatedExpenses);
    setFilteredExpenses(updatedExpenses);
  };

  const handleExpenseUpdated = (updatedExpense) => {
    const updatedExpenses = expenses.map(expense =>
      expense.idExpense === updatedExpense.idExpense ? updatedExpense : expense
    );
    setExpenses(updatedExpenses);
    setFilteredExpenses(updatedExpenses);
  };

  const handleViewImage = async (idExpense) => {
    try {
      const response = await fetchExpenseImage(idExpense);
      const imageUrl = URL.createObjectURL(new Blob([response], { type: 'image/jpeg' }));
      setImageUrl(imageUrl);
      setImageModalOpen(true);
    } catch (error) {
      console.error('Error fetching expense image', error);
    }
  };

  return (
    <div className="expenses-page">
      <h1 className="page-title">Gestión de Gastos</h1>
      <Box mb={2} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>
          Crear Gasto
        </Button>
      </Box>
      <form onSubmit={(e) => { e.preventDefault(); handleFiltersChange(filters); }} className="filter-form">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="service-label">Servicio</InputLabel>
              <Select
                name="serviceId"
                value={filters.serviceId}
                onChange={(e) => setFilters((prevFilters) => ({ ...prevFilters, serviceId: e.target.value }))}
                labelId="service-label"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Servicio</em>
                </MenuItem>
                {services.map((service) => (
                  <MenuItem key={service.idService} value={service.idService}>
                    {`${service.vehiclePlate} - ${new Date(service.createdAt).toLocaleDateString('es-ES')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="driver-label">Conductor</InputLabel>
              <Select
                name="driverId"
                value={filters.driverId}
                onChange={(e) => setFilters((prevFilters) => ({ ...prevFilters, driverId: e.target.value }))}
                labelId="driver-label"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Conductor</em>
                </MenuItem>
                {drivers.map((driver) => (
                  <MenuItem key={driver.idUser} value={driver.idUser}>
                    {driver.firstName} {driver.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> {/* Este cierre de Grid es necesario */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="entity-label">Entidad</InputLabel>
              <Select
                name="entityId"
                value={filters.entityId}
                onChange={(e) => setFilters((prevFilters) => ({ ...prevFilters, entityId: e.target.value }))}
                labelId="entity-label"
                displayEmpty
              >
                <MenuItem value="">
                  <em>Entidad</em>
                </MenuItem>
                {entities.map((entity) => (
                  <MenuItem key={entity.idUser} value={entity.idUser}>
                    {entity.firstName} {entity.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Aplicar filtros
            </Button>
          </Grid>
        </Grid>
      </form>
      {loading ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} className="expense-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID del Gasto</TableCell>
                <TableCell>Servicio</TableCell>
                <TableCell>Conductor</TableCell>
                <TableCell>Tipo de Gasto</TableCell>
                <TableCell>Monto Base</TableCell>
                <TableCell>Tipo de Impuesto</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Imagen</TableCell>
                <TableCell>Fecha de Creación</TableCell>
                <TableCell>Fecha de Actualización</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredExpenses.map((expense) => (
                <TableRow key={expense.idExpense}>
                  <TableCell>{expense.idExpense}</TableCell>
                  <TableCell>
                    {services.find(service => service.idService === expense.serviceId)?.vehiclePlate} - 
                    {new Date(services.find(service => service.idService === expense.serviceId)?.createdAt).toLocaleDateString('es-ES')}
                  </TableCell>
                  <TableCell>
                    {drivers.find(driver => driver.idUser === expense.userId)?.firstName} {drivers.find(driver => driver.idUser === expense.userId)?.lastName}
                  </TableCell>
                  <TableCell>{expense.expenseType}</TableCell>
                  <TableCell>{expense.amountBase}</TableCell>
                  <TableCell>{expense.taxType}</TableCell>
                  <TableCell>{expense.description}</TableCell>
                  <TableCell>
                    {expense.image && (
                      <IconButton color="primary" onClick={() => handleViewImage(expense.idExpense)}>
                        <Image />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{new Date(expense.createdAt).toLocaleDateString('es-ES')}</TableCell>
                  <TableCell>{new Date(expense.updatedAt).toLocaleDateString('es-ES')}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenEditModal(expense)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteExpense(expense.idExpense)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <CreateExpensesModal
        open={showCreateModal}
        onClose={handleCloseCreateModal}
        onExpenseCreated={handleExpenseCreated}
        services={services}
        drivers={drivers}
      />

      {selectedExpense && (
        <EditExpensesModal
          open={showEditModal}
          onClose={handleCloseEditModal}
          expense={selectedExpense}
          services={services}
          drivers={drivers}
          onExpenseUpdated={handleExpenseUpdated}
        />
      )}

      <ExpenseImageModal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        imageUrl={imageUrl}
      />
    </div>
  );
};

export default Expenses;
