import api from './apiConfig';

/**
 * Autentica al usuario y obtiene el token JWT.
 * @param {Object} credentials - Objeto que contiene el email y la contraseña.
 * @param {string} credentials.email - Email del usuario.
 * @param {string} credentials.password - Contraseña del usuario.
 * @param {boolean} rememberMe - Indica si el usuario desea mantener la sesión iniciada.
 * @returns {Promise<Object>} - La respuesta del servidor con el token JWT y los detalles del usuario.
 */
export const login = async (credentials, rememberMe) => {
  try {
    const response = await api.post('/auth/login', credentials);
    const { token, user } = response.data;

    // Eliminar la propiedad password antes de almacenar el objeto user
    const { password, ...userWithoutPassword } = user;

    // Guardar el token y el usuario sin la contraseña en localStorage o sessionStorage basado en rememberMe
    if (rememberMe) {
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('user', JSON.stringify(userWithoutPassword));
    } else {
      sessionStorage.setItem('jwtToken', token);
      sessionStorage.setItem('user', JSON.stringify(userWithoutPassword));
    }

    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};


/**
 * Inicia el proceso de recuperación de contraseña.
 * @param {string} email - Email del usuario que ha olvidado su contraseña.
 * @returns {Promise<string>} - La respuesta del servidor confirmando el envío del token de restablecimiento de contraseña.
 */
export const forgotPassword = async (email) => {
  try {
    const response = await api.post('/auth/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error('Error during password reset request:', error);
    throw error;
  }
};

/**
 * Restablece la contraseña del usuario.
 * @param {string} token - Token de restablecimiento de contraseña.
 * @param {string} newPassword - Nueva contraseña para el usuario.
 * @returns {Promise<string>} - La respuesta del servidor confirmando el restablecimiento de la contraseña.
 */
export const resetPassword = async (token, newPassword) => {
  try {
    const response = await api.post('/auth/reset-password', { token, newPassword });
    return response.data;
  } catch (error) {
    console.error('Error during password reset:', error);
    throw error;
  }
};

/**
 * Verifica el token JWT y carga los datos del usuario.
 * @returns {Promise<Object>} - Los datos del usuario si el token es válido.
 */
export const verifyTokenAndLoadUser = async () => {
  try {
    const response = await api.get('/auth/verify-token');
    return response.data;
  } catch (error) {
    console.error('Error during token verification:', error);
    throw error;
  }
};

/**
 * Cierra la sesión del usuario eliminando el token JWT.
 */
export const logout = () => {
  localStorage.removeItem('jwtToken');
  sessionStorage.removeItem('jwtToken');
  localStorage.removeItem('user');
  sessionStorage.removeItem('user');
};
