import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SOCKET_URL = process.env.REACT_APP_API_URL;

let stompClient = null;
let currentSubscriptions = {};

/**
 * Conecta el servicio de notificaciones a través de WebSocket.
 * Configura la suscripción al canal de notificaciones para recibir mensajes en tiempo real.
 * @param {Number} idUser - ID del usuario actual.
 * @param {Number} roleId - Rol del usuario actual.
 */
export const connectNotificationService = (idUser, roleId) => {
  if (!idUser || !roleId) {
    console.error('idUser o roleId no está definido');
    return;
  }

  // Prevenir múltiples conexiones WebSocket
  if (stompClient && stompClient.connected) {
    console.log('WebSocket connection already active.');
    return;
  }

  const socket = new SockJS(`${SOCKET_URL}/ws`);
  
  stompClient = new Client({
    webSocketFactory: () => socket,
    debug: (str) => {
      console.log(str);
    },
    onConnect: (frame) => {
      console.log('Connected: ' + frame);

      // Limpiar suscripciones anteriores
      clearCurrentSubscriptions();

      // Suscripción a notificaciones específicas del usuario
      subscribeToChannel(`/topic/notifications/user/${idUser}`, message => {
        showNotification(message.body);
      });

      // Suscripción a notificaciones generales según el rol
      if (roleId === 3) { // Admin
        subscribeToChannel('/topic/notifications/admin', message => {
          showNotification(message.body);
        });
      } else if (roleId === 5) { // Oficina WeDelivery
        subscribeToChannel('/topic/notifications/wedelivery', message => {
          showNotification(message.body);
        });
      }
    },
    onWebSocketClose: (event) => {
      console.error('WebSocket connection closed:', event);
      reconnect(idUser, roleId);
    },
    onStompError: (frame) => {
      console.error('Broker reported error: ' + frame.headers['message']);
      console.error('Additional details: ' + frame.body);
      reconnect(idUser, roleId);
    },
  });

  stompClient.activate();
};

/**
 * Suscribe a un canal específico y guarda la suscripción.
 * @param {String} channel - El canal al que suscribirse.
 * @param {Function} callback - Función a ejecutar al recibir un mensaje.
 */
const subscribeToChannel = (channel, callback) => {
  if (currentSubscriptions[channel]) {
    console.log(`Already subscribed to channel: ${channel}`);
    return;
  }
  
  currentSubscriptions[channel] = stompClient.subscribe(channel, callback);
  console.log(`Subscribed to channel: ${channel}`);
};

/**
 * Limpiar todas las suscripciones actuales.
 */
const clearCurrentSubscriptions = () => {
  for (const channel in currentSubscriptions) {
    if (currentSubscriptions[channel]) {
      currentSubscriptions[channel].unsubscribe();
      console.log(`Unsubscribed from channel: ${channel}`);
    }
  }
  currentSubscriptions = {};
};

/**
 * Desconecta el WebSocket y limpia las suscripciones.
 */
export const disconnectNotificationService = () => {
  if (stompClient && stompClient.connected) {
    clearCurrentSubscriptions();
    stompClient.deactivate();
    console.log('WebSocket connection deactivated and subscriptions cleared.');
  }
};

/**
 * Reconecta el WebSocket si la conexión se cierra o falla.
 * @param {Number} idUser - ID del usuario actual.
 * @param {Number} roleId - Rol del usuario actual.
 */
const reconnect = (idUser, roleId) => {
  if (stompClient) {
    stompClient.deactivate();
    stompClient.onDisconnect = () => {
      stompClient = null;
      console.log('WebSocket client deactivated. Attempting to reconnect...');
      connectNotificationService(idUser, roleId);
    };
  } else {
    console.log('Reconnecting WebSocket...');
    connectNotificationService(idUser, roleId);
  }
};

/**
 * Muestra una notificación utilizando react-toastify.
 * @param {string} message - El mensaje a mostrar en la notificación.
 */
export const showNotification = (message) => {
  const position = toast.POSITION?.TOP_RIGHT || 'top-right';

  toast.info(message, {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
