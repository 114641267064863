import api from './apiConfig';

/**
 * Obtener todos los totales.
 * @returns {Promise<Array>} - La respuesta del servidor con la lista de todos los totales.
 */
export const fetchTotals = async () => {
  try {
    const response = await api.get('/totals');
    return response.data;
  } catch (error) {
    console.error('Error al obtener los totales:', error);
    throw error;
  }
};

/**
 * Obtener un total por ID.
 * @param {Number|String} id - ID del total.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del total.
 */
export const fetchTotalById = async (id) => {
  try {
    const response = await api.get(`/totals/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener el total con ID ${id}:`, error);
    throw error;
  }
};

/**
 * Crear un nuevo total.
 * @param {Object} totalData - Datos del total a crear.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles del total creado.
 */
export const createTotal = async (totalData) => {
  try {
    const response = await api.post('/totals', totalData);
    return response.data;
  } catch (error) {
    console.error('Error al crear el total:', error);
    throw error;
  }
};

/**
 * Eliminar un total por ID.
 * @param {Number|String} id - ID del total a eliminar.
 * @returns {Promise<void>} - La respuesta del servidor confirmando la eliminación del total.
 */
export const deleteTotal = async (id) => {
  try {
    await api.delete(`/totals/${id}`);
  } catch (error) {
    console.error(`Error al eliminar el total con ID ${id}:`, error);
    throw error;
  }
};

export const fetchTotalByServiceId = async (serviceId) => {
  try {
      const response = await api.get(`/totals/service/${serviceId}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching total by service ID:', error);
      throw error;
  }
};