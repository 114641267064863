import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Typography, Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUsersByRoleId } from '../services/api/userApi';
import { createService } from '../services/api/serviceApi';

const CreateServices = () => {
  const [formData, setFormData] = useState({
    clientId: '',
    vehiclePlate: '',
    vehicleBrand: '',
    vehicleModel: '',
    vehicleColor: '',
    receptionAddress: '',
    deliveryAddress: '',
    recipientName: '',
    recipientNif: '',
    basePrice: '',
    vehicleStatus: '',
    reEntityComments: '',
    eentityComments: '',
  });

  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const initializeForm = () => {
      if (user.roleId === 1) {
        setFormData((prevData) => ({
          ...prevData,
          clientId: user.idUser,
        }));
      } else if (user.roleId === 2) {
        setFormData((prevData) => ({
          ...prevData,
          clientId: user.idEntity,
        }));
      } else {
        fetchClients();
      }
    };

    const fetchClients = async () => {
      try {
        const response = await fetchUsersByRoleId(1);
        setClients(response);
      } catch (error) {
        console.error('Error al obtener los clientes:', error);
      }
    };

    initializeForm();
  }, [user]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que todos los campos estén completos
    const requiredFields = [
      'clientId',
      'vehiclePlate',
      'vehicleBrand',
      'vehicleModel',
      'vehicleColor',
      'receptionAddress',
      'deliveryAddress',
      'recipientName',
      'recipientNif',
      'basePrice',
      'vehicleStatus',
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        alert('Por favor complete todos los campos obligatorios.');
        return;
      }
    }

    const serviceData = {
      ...formData,
      serviceStatus: 'PENDING',
      userIdCreator: user.idUser,
    };

    try {
      await createService(serviceData);
      navigate('/admin/services-pending');
    } catch (error) {
      console.error('Error al crear el servicio:', error);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" gutterBottom>
        Crear un Nuevo Servicio
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {(user.roleId !== 1 && user.roleId !== 2) && (
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="client-label">Cliente</InputLabel>
                <Select
                  labelId="client-label"
                  name="clientId"
                  value={formData.clientId}
                  onChange={handleChange}
                  required
                >
                  {clients.map((client) => (
                    <MenuItem key={client.idUser} value={client.idUser}>
                      {client.firstName} {client.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              label="Matrícula del Vehículo"
              name="vehiclePlate"
              value={formData.vehiclePlate}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Marca del Vehículo"
              name="vehicleBrand"
              value={formData.vehicleBrand}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Modelo del Vehículo"
              name="vehicleModel"
              value={formData.vehicleModel}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Color del Vehículo"
              name="vehicleColor"
              value={formData.vehicleColor}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Dirección de Recepción"
              name="receptionAddress"
              value={formData.receptionAddress}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Dirección de Entrega"
              name="deliveryAddress"
              value={formData.deliveryAddress}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Nombre del Receptor"
              name="recipientName"
              value={formData.recipientName}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="NIF del Receptor"
              name="recipientNif"
              value={formData.recipientNif}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio Base"
              name="basePrice"
              type="number"
              value={formData.basePrice}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Estado del Vehículo"
              name="vehicleStatus"
              value={formData.vehicleStatus}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Comentarios de Recepción"
              name="reEntityComments"
              value={formData.reEntityComments}
              onChange={handleChange}
              fullWidth
              multiline
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Comentarios de Entrega"
              name="eentityComments"
              value={formData.eentityComments}
              onChange={handleChange}
              fullWidth
              multiline
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Crear Servicio
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CreateServices;
