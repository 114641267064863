import React, { useEffect, useState } from 'react';
import { fetchAllServices, deleteServiceById } from '../services/api/serviceApi';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import EditServicesModal from './EditServicesModal';
import '../styles/pages.scss';

const EditServices = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchAllServices();
        let filteredServices = response;

        if (user?.roleId === 1) {
          filteredServices = response.filter(service => service.clientId === user.idUser);
        } else if (user?.roleId === 2) {
          filteredServices = response.filter(service => service.clientId === user.idEntity);
        }

        setServices(filteredServices);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    loadServices();
  }, [user]);

  const handleEditService = (service) => {
    setSelectedService(service);
    setShowEditModal(true);
  };

  const handleDeleteService = async (serviceId) => {
    try {
      await deleteServiceById(serviceId);
      setServices(services.filter(service => service.idService !== serviceId));
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="container">
      <Typography variant="h4" align="center" gutterBottom className="page-title">
        Editar y Eliminar Servicios
      </Typography>

      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>ID del Servicio</TableCell>
              <TableCell>Fecha del Servicio</TableCell>
              <TableCell>Hora del Servicio</TableCell>
              <TableCell>Matrícula del Vehículo</TableCell>
              <TableCell>Marca del Vehículo</TableCell>
              <TableCell>Modelo del Vehículo</TableCell>
              <TableCell>Color del Vehículo</TableCell>
              <TableCell>Dirección de Recepción</TableCell>
              <TableCell>Dirección de Entrega</TableCell>
              <TableCell>Nombre del Receptor</TableCell>
              <TableCell>NIF del Receptor</TableCell>
              <TableCell>Precio Base</TableCell>
              <TableCell>Kilometraje Inicial</TableCell>
              <TableCell>Kilometraje Final</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.idService}>
                <TableCell>{service.idService}</TableCell>
                <TableCell>{formatDate(service.serviceDate)}</TableCell>
                <TableCell>{service.serviceTime}</TableCell>
                <TableCell>{service.vehiclePlate}</TableCell>
                <TableCell>{service.vehicleBrand}</TableCell>
                <TableCell>{service.vehicleModel}</TableCell>
                <TableCell>{service.vehicleColor}</TableCell>
                <TableCell>{service.receptionAddress}</TableCell>
                <TableCell>{service.deliveryAddress}</TableCell>
                <TableCell>{service.recipientName}</TableCell>
                <TableCell>{service.recipientNif}</TableCell>
                <TableCell>{service.basePrice.toFixed(2)}</TableCell>
                <TableCell>{service.initialKm}</TableCell>
                <TableCell>{service.finalKm}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditService(service)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteService(service.idService)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedService && (
        <EditServicesModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          service={selectedService}
          setServices={setServices}
        />
      )}
    </div>
  );
};

export default EditServices;
