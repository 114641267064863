import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import { fetchUsersByRoleId } from '../services/api/userApi'; // Importación correcta
import { updateDriverService } from '../services/api/driverServiceApi';

const EditDriverModal = ({ open, onClose, driverService, setDriverServices }) => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState(driverService.driverId || '');
  const [driverType, setDriverType] = useState(driverService.driverType || '');
  const [active, setActive] = useState(driverService.active || false); // Estado para "activo"
  const [loading, setLoading] = useState(false);

  // Recargar datos cuando el modal se abre para asegurarse de tener los valores correctos
  useEffect(() => {
    const loadDrivers = async () => {
      try {
        const response = await fetchUsersByRoleId(4); // Llamada a userApi
        setDrivers(response);
      } catch (error) {
        console.error('Error al cargar los conductores:', error);
      }
    };

    loadDrivers();
  }, []);

  useEffect(() => {
    if (open) {
      // Resetear los valores cada vez que el modal se abre
      setSelectedDriverId(driverService.driverId || '');
      setDriverType(driverService.driverType || '');
      setActive(driverService.active || false);
    }
  }, [open, driverService]);

  const handleSaveChanges = async () => {
    if (!selectedDriverId || !driverType) {
      alert("Por favor, complete todos los campos antes de guardar.");
      return;
    }

    setLoading(true);
    try {
      const updatedDriverService = {
        ...driverService,
        driverId: selectedDriverId,
        driverType,
        active, // Incluir el estado "activo"
      };

      await updateDriverService(driverService.idDriverService, updatedDriverService);
      onClose(); // Cierra el modal después de guardar los cambios
    } catch (error) {
      console.error('Error al guardar los cambios en el driver service:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Conductor del Servicio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-driver-label">Seleccionar Conductor</InputLabel>
              <Select
                labelId="select-driver-label"
                value={selectedDriverId}
                onChange={(e) => setSelectedDriverId(e.target.value)}
              >
                {drivers.map(driver => (
                  <MenuItem key={driver.idUser} value={driver.idUser}>
                    {`ID: ${driver.idUser} - Nombre: ${driver.firstName} ${driver.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="driver-type-label">Tipo de Conductor</InputLabel>
              <Select
                labelId="driver-type-label"
                value={driverType}
                onChange={(e) => setDriverType(e.target.value)}
              >
                <MenuItem value="CONTRATO">Contrato</MenuItem>
                <MenuItem value="AUTONOMO">Autónomo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="active-label">Activo</InputLabel>
              <Select
                labelId="active-label"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleSaveChanges} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Guardar Cambios'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDriverModal;
