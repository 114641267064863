import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import { updateService } from '../services/api/serviceApi';

const EditServicesModal = ({ open, onClose, service, setServices }) => {
  const [editedService, setEditedService] = useState(service);

  useEffect(() => {
    if (service) {
      setEditedService(service);
    }
  }, [service]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const updatedService = await updateService(editedService.idService, editedService);
      setServices((prevServices) =>
        prevServices.map((s) =>
          s.idService === updatedService.idService ? updatedService : s
        )
      );
      onClose();
    } catch (error) {
      console.error('Error updating service:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Editar Servicio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Campos editables */}
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Matrícula del Vehículo"
              name="vehiclePlate"
              value={editedService.vehiclePlate || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Marca del Vehículo"
              name="vehicleBrand"
              value={editedService.vehicleBrand || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Modelo del Vehículo"
              name="vehicleModel"
              value={editedService.vehicleModel || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Color del Vehículo"
              name="vehicleColor"
              value={editedService.vehicleColor || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Dirección de Recepción"
              name="receptionAddress"
              value={editedService.receptionAddress || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Dirección de Entrega"
              name="deliveryAddress"
              value={editedService.deliveryAddress || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Nombre del Receptor"
              name="recipientName"
              value={editedService.recipientName || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="NIF del Receptor"
              name="recipientNif"
              value={editedService.recipientNif || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Precio Base"
              name="basePrice"
              type="number"
              value={editedService.basePrice || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Kilometraje Inicial"
              name="initialKm"
              type="number"
              value={editedService.initialKm || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Kilometraje Final"
              name="finalKm"
              type="number"
              value={editedService.finalKm || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Estado del Vehículo"
              name="vehicleStatus"
              value={editedService.vehicleStatus || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Comentarios de Recepción"
              name="reEntityComments"
              value={editedService.reEntityComments || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Comentarios de Entrega"
              name="eentityComments"
              value={editedService.eentityComments || ''}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSaveChanges} color="primary">
          Guardar Cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditServicesModal;
