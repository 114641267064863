import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography
} from '@mui/material';
import { fetchAllServices } from '../services/api/serviceApi';
import { fetchUsersByRoleId } from '../services/api/userApi';
import { updateExpense, fetchExpenseImage } from '../services/api/expenseApi';

const MAX_FILE_SIZE_MB = 10; // Tamaño máximo permitido para la imagen

const EditExpensesModal = ({ open, onClose, expense, onExpenseUpdated }) => {
  const [editedExpense, setEditedExpense] = useState(expense || {});
  const [services, setServices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileError, setFileError] = useState(null); // Manejar errores de archivo

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [servicesData, driversData] = await Promise.all([
          fetchAllServices(),
          fetchUsersByRoleId(4)
        ]);
        setServices(servicesData);
        setDrivers(driversData);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (expense) {
      setEditedExpense(expense);

      if (expense.image) {
        // Obtener la imagen si ya está asociada con el gasto
        const fetchImage = async () => {
          try {
            const response = await fetchExpenseImage(expense.idExpense);
            const imageUrl = URL.createObjectURL(new Blob([response], { type: 'image/jpeg' }));
            setPreviewImage(imageUrl);
          } catch (error) {
            console.error('Error fetching expense image', error);
          }
        };
        fetchImage();
      }
    }
  }, [expense]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedExpense({ ...editedExpense, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        setFileError(`El archivo supera el tamaño máximo de ${MAX_FILE_SIZE_MB} MB.`);
        setImageFile(null);
        setPreviewImage(null);
      } else {
        setFileError(null);
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImageFile(null);
      setPreviewImage(null);
    }
  };

  const handleSaveChanges = async () => {
    if (fileError) {
      console.error('El archivo seleccionado no es válido.');
      return;
    }

    try {
      const updatedExpense = { ...editedExpense };
      const result = await updateExpense(editedExpense.idExpense, updatedExpense, imageFile);
      onExpenseUpdated(result);
      onClose();
    } catch (error) {
      console.error('Error updating expense', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Gasto</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="service-label">Servicio</InputLabel>
              <Select
                labelId="service-label"
                name="serviceId"
                value={editedExpense.serviceId || ''}
                onChange={handleInputChange}
              >
                {services.map((service) => (
                  <MenuItem key={service.idService} value={service.idService}>
                    {`${service.vehiclePlate} - ${new Date(service.serviceDate).toLocaleDateString()}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="driver-label">Conductor</InputLabel>
              <Select
                labelId="driver-label"
                name="userId"
                value={editedExpense.userId || ''}
                onChange={handleInputChange}
              >
                {drivers.map((driver) => (
                  <MenuItem key={driver.idUser} value={driver.idUser}>
                    {`${driver.firstName} ${driver.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="expenseType-label">Tipo de Gasto</InputLabel>
              <Select
                labelId="expenseType-label"
                name="expenseType"
                value={editedExpense.expenseType || ''}
                onChange={handleInputChange}
              >
                <MenuItem value="FUEL">Combustible</MenuItem>
                <MenuItem value="CLEANING">Limpieza</MenuItem>
                <MenuItem value="OTHER">Otros</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              margin="dense"
              name="amountBase"
              label="Monto Base"
              type="number"
              fullWidth
              value={editedExpense.amountBase || ''}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="taxType-label">Tipo de Impuesto</InputLabel>
              <Select
                labelId="taxType-label"
                name="taxType"
                value={editedExpense.taxType || ''}
                onChange={handleInputChange}
              >
                <MenuItem value="TEN_PERCENT">10%</MenuItem>
                <MenuItem value="TWENTY_ONE_PERCENT">21%</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              margin="dense"
              name="description"
              label="Descripción"
              type="text"
              fullWidth
              value={editedExpense.description || ''}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              fullWidth
            >
              Subir Imagen
              <input
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </Button>
            {fileError && (
              <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                {fileError}
              </Typography>
            )}
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                style={{ marginTop: '10px', maxWidth: '100%', maxHeight: '200px' }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSaveChanges} color="primary" disabled={!!fileError}>
          Guardar Cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExpensesModal;
