import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { fetchAllServices } from '../services/api/serviceApi';
import { fetchDriverServicesByServiceId, fetchAllDriverServices, deleteDriverService } from '../services/api/driverServiceApi';
import EditDriverModal from './EditDriverModal';
import CreateDriverModal from './CreateDriverModal';

const DriverServices = () => {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [driverServices, setDriverServices] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedDriverService, setSelectedDriverService] = useState(null);

  useEffect(() => {
    const loadServices = async () => {
      try {
        const response = await fetchAllServices();
        setServices(response);
      } catch (error) {
        console.error('Error al cargar los servicios:', error);
      }
    };

    const loadDriverServices = async () => {
      try {
        const allDriverServices = await fetchAllDriverServices();
        setDriverServices(allDriverServices);
      } catch (error) {
        console.error('Error al cargar los driver services:', error);
      }
    };

    loadServices();
    loadDriverServices();
  }, []);

  const handleServiceChange = (event) => {
    setSelectedServiceId(event.target.value);
  };

  const applyFilters = async () => {
    try {
      if (selectedServiceId) {
        const driverServicesData = await fetchDriverServicesByServiceId(selectedServiceId);
        setDriverServices(driverServicesData);
      } else {
        const allDriverServices = await fetchAllDriverServices();
        setDriverServices(allDriverServices);
      }
    } catch (error) {
      console.error('Error al aplicar filtros:', error);
    }
  };

  const handleEditDriverService = (driverService) => {
    setSelectedDriverService(driverService);
    setShowEditModal(true);
  };

  const handleDeleteDriverService = async (driverServiceId) => {
    try {
      await deleteDriverService(driverServiceId);
      applyFilters(); // Actualizar la lista después de eliminar
    } catch (error) {
      console.error('Error al eliminar el driver service:', error);
    }
  };

  // Cerrar modal de edición y recargar los driver services
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    applyFilters();
  };

  // Cerrar modal de creación y recargar los driver services
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    applyFilters();
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h4" align="center" gutterBottom>
          Gestión de Conductores de Servicios
        </Typography>
      </Box>

      <Grid container spacing={2} alignItems="center" mb={4}>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth>
            <InputLabel id="select-service-label">Seleccionar Servicio</InputLabel>
            <Select
              labelId="select-service-label"
              value={selectedServiceId}
              onChange={handleServiceChange}
            >
              <MenuItem value="">
                <em>Todos los Servicios</em>
              </MenuItem>
              {services.map(service => (
                <MenuItem key={service.idService} value={service.idService}>
                  {`ID: ${service.idService} - Matrícula: ${service.vehiclePlate} - Fecha: ${new Date(service.createdAt).toLocaleDateString()}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" color="primary" onClick={applyFilters} fullWidth>
            Aplicar Filtros
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={() => setShowCreateModal(true)} fullWidth>
            Añadir Nuevo Conductor al Servicio
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID Servicio</TableCell>
              <TableCell>ID Conductor</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell>Fecha de Creación</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {driverServices.map((driverService) => (
              <TableRow key={driverService.idDriverService}>
                <TableCell>{driverService.serviceId}</TableCell>
                <TableCell>{driverService.driverId}</TableCell>
                <TableCell>{driverService.driverType}</TableCell>
                <TableCell>{driverService.active ? "Sí" : "No"}</TableCell>
                <TableCell>{new Date(driverService.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditDriverService(driverService)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteDriverService(driverService.idDriverService)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedDriverService && (
        <EditDriverModal
          open={showEditModal}
          onClose={handleCloseEditModal}
          driverService={selectedDriverService}
          setDriverServices={setDriverServices}
        />
      )}

      <CreateDriverModal
        open={showCreateModal}
        onClose={handleCloseCreateModal}
        setDriverServices={setDriverServices}
      />
    </Container>
  );
};

export default DriverServices;
