import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchRateEntitiesByClientId } from '../services/api/rateEntityApi';
import { fetchRateSegmentsByRateId } from '../services/api/rateSegmentApi';
import { fetchRateById } from '../services/api/rateApi';
import { useSelector } from 'react-redux';
import '../styles/pages.scss';

const MyRates = () => {
  const { user } = useSelector((state) => state.auth);
  const [rateEntities, setRateEntities] = useState([]);
  const [rateSegments, setRateSegments] = useState({});
  const [rateNames, setRateNames] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRatesForEntity = async () => {
      try {
        const rateEntitiesData = await fetchRateEntitiesByClientId(user.idEntity || user.idUser);
        setRateEntities(rateEntitiesData);

        const rateNamesData = {};
        for (const rateEntity of rateEntitiesData) {
          const rateDetails = await fetchRateById(rateEntity.rateId);
          rateNamesData[rateEntity.rateId] = rateDetails.rateType;
        }
        setRateNames(rateNamesData);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener las tarifas asociadas a la entidad:', error);
        setLoading(false);
      }
    };

    fetchRatesForEntity();
  }, [user]);

  const handleAccordionChange = async (rateId) => {
    const isExpanded = expanded === rateId;
    setExpanded(isExpanded ? false : rateId);

    if (!isExpanded && !rateSegments[rateId]) {
      try {
        const segments = await fetchRateSegmentsByRateId(rateId);
        setRateSegments((prevSegments) => ({
          ...prevSegments,
          [rateId]: segments,
        }));
      } catch (error) {
        console.error(`Error al obtener los segmentos de tarifa para la tarifa ${rateId}:`, error);
      }
    }
  };

  return (
    <div className="my-rates-container">
      <h1 className="my-rates-title">Mis Tarifas</h1>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        rateEntities.map((rateEntity) => (
          <Accordion
            key={rateEntity.idRateEntity}
            expanded={expanded === rateEntity.rateId}
            onChange={() => handleAccordionChange(rateEntity.rateId)}
            className="rate-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${rateEntity.rateId}-content`}
              id={`panel-${rateEntity.rateId}-header`}
              className="rate-accordion-summary"
            >
              <Typography className="rate-name">
                Tarifa: {rateNames[rateEntity.rateId] || rateEntity.rateId}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="rate-accordion-details">
              {rateSegments[rateEntity.rateId] ? (
                <TableContainer component={Paper} className="rate-table-container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Mínimo KM</TableCell>
                        <TableCell>Máximo KM</TableCell>
                        <TableCell>Precio</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rateSegments[rateEntity.rateId].map((segment) => (
                        <TableRow key={segment.idRateSegment}>
                          <TableCell>{segment.minKm}</TableCell>
                          <TableCell>{segment.maxKm}</TableCell>
                          <TableCell>{segment.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No se encontraron segmentos para esta tarifa.</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </div>
  );
};

export default MyRates;
