import api from './apiConfig';

/**
 * Actualiza la localización del conductor.
 * @param {Object} driverLocation - Objeto que contiene los detalles de la localización del conductor.
 * @returns {Promise<Object>} - La respuesta del servidor con la localización actualizada.
 */
export const updateDriverLocation = async (driverLocation) => {
  try {
    const response = await api.post('/driver-locations/update', driverLocation);
    return response.data;
  } catch (error) {
    console.error('Error updating driver location:', error);
    throw error;
  }
};

/**
 * Obtiene todas las localizaciones de conductores.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de todas las localizaciones de conductores.
 */
export const getAllDriverLocations = async () => {
  try {
    const response = await api.get('/driver-locations');
    return response.data;
  } catch (error) {
    console.error('Error fetching all driver locations:', error);
    throw error;
  }
};

/**
 * Obtiene la última localización para un servicio específico.
 * @param {Number} serviceId - ID del servicio.
 * @returns {Promise<Object>} - La respuesta del servidor con la última localización registrada para el servicio.
 */
export const getLastLocationForService = async (serviceId) => {
  try {
    const response = await api.get(`/driver-locations/latest/${serviceId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching last location for service ${serviceId}:`, error);
    throw error;
  }
};

/**
 * Obtiene todas las localizaciones para un servicio específico.
 * @param {Number} serviceId - ID del servicio.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de todas las localizaciones para el servicio especificado.
 */
export const getAllLocationsForService = async (serviceId) => {
  try {
    const response = await api.get(`/driver-locations/all/${serviceId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching all locations for service ${serviceId}:`, error);
    throw error;
  }
};
