import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, Button, Typography, Box
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { fetchAllExtraHours, filterExtraHours, deleteExtraHourById } from '../services/api/extraHourApi';
import CreateExtraHourModal from './CreateExtraHourModal';
import EditExtraHourModal from './EditExtraHourModal';
import { fetchAllServices } from '../services/api/serviceApi';
import dayjs from 'dayjs';
import '../styles/pages.scss';

const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

const ExtraHours = () => {
  const [extraHours, setExtraHours] = useState([]);
  const [selectedExtraHour, setSelectedExtraHour] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [filters, setFilters] = useState({
    serviceId: '',
  });
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllExtraHours();
        setExtraHours(data);
        const servicesData = await fetchAllServices();
        setServices(servicesData);
      } catch (error) {
        console.error('Error fetching extra hours', error);
      }
    };

    fetchData();
  }, []);

  const handleRowClick = (extraHour) => {
    setSelectedExtraHour(extraHour);
    setShowEditModal(true);
  };

  const handleDeleteExtraHour = async (idExtraHour) => {
    try {
      await deleteExtraHourById(idExtraHour);
      setExtraHours(extraHours.filter(eh => eh.idExtraHour !== idExtraHour));
    } catch (error) {
      console.error('Error deleting extra hour', error);
    }
  };

  const handleFiltersChange = async (filters) => {
    try {
      const filteredExtraHours = await filterExtraHours(filters);
      setExtraHours(filteredExtraHours);
    } catch (error) {
      console.error('Error applying filters', error);
    }
  };

  const refreshExtraHours = async () => {
    try {
      const data = await fetchAllExtraHours();
      setExtraHours(data);
    } catch (error) {
      console.error('Error refreshing extra hours', error);
    }
  };

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Gestión de Horas Extra
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setShowCreateModal(true)}>
            Crear Hora Extra
          </Button>
        </Grid>
        <Grid item>
          <form onSubmit={(e) => { e.preventDefault(); handleFiltersChange(filters); }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <FormControl>
                  <InputLabel id="service-label">Servicios</InputLabel>
                  <Select
                    labelId="service-label"
                    name="serviceId"
                    value={filters.serviceId}
                    onChange={(e) => setFilters({ ...filters, serviceId: e.target.value })}
                  >
                    <MenuItem value="">
                      <em>Ninguno</em>
                    </MenuItem>
                    {services.map((service) => (
                      <MenuItem key={service.idService} value={service.idService}>
                        {service.vehiclePlate} - {formatDate(service.createdAt)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Aplicar filtros
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Servicio (Matrícula - Fecha)</TableCell>
              <TableCell>Horas Extra</TableCell>
              <TableCell>Beneficio WeDelivery</TableCell>
              <TableCell>Beneficio WeDelivery (con IVA)</TableCell>
              <TableCell>Creado En</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {extraHours.map((extraHour) => (
              <TableRow key={extraHour.idExtraHour}>
                <TableCell>{extraHour.idExtraHour}</TableCell>
                <TableCell>
                  {services.find(service => service.idService === extraHour.serviceId)?.vehiclePlate} - 
                  {formatDate(services.find(service => service.idService === extraHour.serviceId)?.createdAt)}
                </TableCell>
                <TableCell>{extraHour.extraHours}</TableCell>
                <TableCell>{extraHour.wedeliveryBenefit}</TableCell>
                <TableCell>{extraHour.wedeliveryBenefitVat}</TableCell>
                <TableCell>{formatDate(extraHour.createdAt)}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleRowClick(extraHour)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteExtraHour(extraHour.idExtraHour)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal para crear horas extra */}
      {showCreateModal && (
        <CreateExtraHourModal
          open={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onSave={refreshExtraHours}
          services={services}
        />
      )}

      {/* Modal para editar horas extra */}
      {showEditModal && selectedExtraHour && (
        <EditExtraHourModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          extraHour={selectedExtraHour}
          onSave={refreshExtraHours}
          services={services}
        />
      )}
    </Box>
  );
};

export default ExtraHours;
