import api from './apiConfig';

/**
 * Obtiene todas las asociaciones de tarifas con entidades.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de asociaciones.
 */
export const fetchAllRateEntities = async () => {
  try {
    const response = await api.get('/rate-entities');
    return response.data;
  } catch (error) {
    console.error('Error fetching rate entities:', error);
    throw error;
  }
};

/**
 * Obtiene una asociación de tarifa con entidad por su ID.
 * @param {Number} id - ID de la asociación de tarifa con entidad.
 * @returns {Promise<Object>} - La respuesta del servidor con los detalles de la asociación.
 */
export const fetchRateEntityById = async (id) => {
  try {
    const response = await api.get(`/rate-entities/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rate entity by ID:', error);
    throw error;
  }
};

/**
 * Obtiene asociaciones de tarifas con entidades por ID de cliente.
 * @param {Number} clientId - ID del cliente.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de asociaciones.
 */
export const fetchRateEntitiesByClientId = async (clientId) => {
  try {
    const response = await api.get(`/rate-entities/client/${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rate entities by client ID:', error);
    throw error;
  }
};

/**
 * Obtiene asociaciones de tarifas con entidades por ID de tarifa.
 * @param {Number} rateId - ID de la tarifa.
 * @returns {Promise<Array>} - La respuesta del servidor con una lista de asociaciones.
 */
export const fetchRateEntitiesByRateId = async (rateId) => {
  try {
    const response = await api.get(`/rate-entities/rate/${rateId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rate entities by rate ID:', error);
    throw error;
  }
};

/**
 * Crea una nueva asociación de tarifa con entidad.
 * @param {Object} rateEntityData - Datos de la asociación de tarifa con entidad a crear.
 * @returns {Promise<Object>} - La respuesta del servidor con la asociación creada.
 */
export const createRateEntity = async (rateEntityData) => {
  try {
    const response = await api.post('/rate-entities', rateEntityData);
    return response.data;
  } catch (error) {
    console.error('Error creating rate entity:', error);
    throw error;
  }
};

/**
 * Actualiza una asociación de tarifa con entidad por su ID.
 * @param {Number} id - ID de la asociación de tarifa con entidad.
 * @param {Object} rateEntityData - Datos actualizados de la asociación.
 * @returns {Promise<Object>} - La respuesta del servidor con la asociación actualizada.
 */
export const updateRateEntity = async (id, rateEntityData) => {
  try {
    const response = await api.put(`/rate-entities/${id}`, rateEntityData);
    return response.data;
  } catch (error) {
    console.error('Error updating rate entity:', error);
    throw error;
  }
};

/**
 * Elimina una asociación de tarifa con entidad por su ID.
 * @param {Number} id - ID de la asociación de tarifa con entidad.
 * @returns {Promise<Object>} - La respuesta del servidor con la confirmación de eliminación.
 */
export const deleteRateEntity = async (id) => {
  try {
    const response = await api.delete(`/rate-entities/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting rate entity:', error);
    throw error;
  }
};
