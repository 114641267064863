import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SidebarEntidad from '../sidebars/SidebarEntidad';
import Users from '../../pages/Users';
import CreateServices from '../../pages/CreateServices';
import DriversLocations from '../../pages/DriversLocations';
import Reports from '../../pages/Reports';
import Services from '../../pages/Services';
import ServicesCompleted from '../../pages/ServicesCompleted';
import Profile from '../common/Profile';
import MyRates from '../../pages/MyRates';
import WelcomeMessage from '../WelcomeMessage'; // Importar el componente de mensaje de bienvenida
import { useSelector } from 'react-redux'; // Importar useSelector para acceder al estado del usuario
import '../../styles/components.scss';
import CookiePolicy from '../../pages/CookiePolicy';
import LegalNotice from '../../pages/LegalNotice';
import PrivacyPolicy from '../../pages/PrivacyPolicy';

const DashboardEntidad = () => {
  // Obtener el usuario desde el estado de Redux
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="dashboard">
      <SidebarEntidad />
      <div className="content">
        <Routes>
          <Route path="users" element={<Users />} />
          <Route path="create-services" element={<CreateServices />} />
          <Route path="services" element={<Services />} />
          <Route path="services-completed" element={<ServicesCompleted />} />
          <Route path="driver-locations" element={<DriversLocations />} />
          <Route path="reports" element={<Reports />} />
          <Route path="my-rates" element={<MyRates />} />
          <Route path="profile" element={<Profile />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="legal-notice" element={<LegalNotice />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          {/* Ruta por defecto que muestra el mensaje de bienvenida */}
          <Route
            path="/"
            element={<WelcomeMessage firstName={user?.firstName} lastName={user?.lastName} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default DashboardEntidad;
