import React, { useEffect, useState } from 'react';
import { fetchAllServices, updateServiceStatus } from '../services/api/serviceApi';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, Button, CircularProgress, Box } from '@mui/material';
import serviceStatusConst from '../constants/serviceStatus';
import '../styles/pages.scss';

const ServiceStatusManagement = () => {
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [filterStatus, setFilterStatus] = useState('');

  useEffect(() => {
    const loadServices = async () => {
      try {
        const serviceResponse = await fetchAllServices();
        setServices(serviceResponse);
        setFilteredServices(serviceResponse);
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setLoading(false);
      }
    };

    loadServices();
  }, []);

  const handleStatusChange = (serviceId, newStatus) => {
    setSelectedStatus((prevState) => ({
      ...prevState,
      [serviceId]: newStatus,
    }));
  };

  const handleUpdateStatus = async (serviceId) => {
    try {
      const newStatus = selectedStatus[serviceId];
      if (newStatus) {
        await updateServiceStatus(serviceId, newStatus);
        setServices((prevServices) =>
          prevServices.map((service) =>
            service.idService === serviceId ? { ...service, serviceStatus: newStatus } : service
          )
        );
        setFilteredServices((prevServices) =>
          prevServices.map((service) =>
            service.idService === serviceId ? { ...service, serviceStatus: newStatus } : service
          )
        );
        setSelectedStatus((prevState) => ({
          ...prevState,
          [serviceId]: '',
        }));
      }
    } catch (error) {
      console.error('Error al actualizar el estado del servicio:', error);
    }
  };

  const handleFilterChange = (e) => {
    const status = e.target.value;
    setFilterStatus(status);
    if (status) {
      setFilteredServices(services.filter((service) => service.serviceStatus === status));
    } else {
      setFilteredServices(services);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="serviceStatus">
      <h1>Gestión de Estado de Servicios</h1>
      <FormControl fullWidth margin="normal">
        <InputLabel id="filter-status-label">Filtrar por Estado</InputLabel>
        <Select labelId="filter-status-label" value={filterStatus} onChange={handleFilterChange}>
          <MenuItem value="">
            <em>Todos</em>
          </MenuItem>
          {Object.values(serviceStatusConst).map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer component={Paper} className="responsive-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID del Servicio</TableCell>
              <TableCell>Fecha de Creación</TableCell>
              <TableCell>Matrícula del Vehículo</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredServices.map((service) => {
              const currentStatus = selectedStatus[service.idService] || service.serviceStatus;
              const isStatusChanged = currentStatus !== service.serviceStatus;

              return (
                <TableRow key={service.idService}>
                  <TableCell>{service.idService}</TableCell>
                  <TableCell>{service.createdAt}</TableCell>
                  <TableCell>{service.vehiclePlate}</TableCell>
                  <TableCell>
                    <Select
                      value={currentStatus}
                      onChange={(e) => handleStatusChange(service.idService, e.target.value)}
                      fullWidth
                    >
                      {Object.values(serviceStatusConst).map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdateStatus(service.idService)}
                      disabled={!isStatusChanged}
                    >
                      Actualizar
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServiceStatusManagement;
