import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  fetchServicesByStatus,
  fetchServiceById,
  updateServiceStatus,
} from '../services/api/serviceApi';
import {
  fetchDriverServicesByServiceId,
} from '../services/api/driverServiceApi';
import {
  fetchExpensesByServiceId,
} from '../services/api/expenseApi';
import {
  fetchImagesByServiceId,
} from '../services/api/imageServiceApi';
import {
  fetchExtraHoursByServiceId,
} from '../services/api/extraHourApi';
import {
  fetchTradesByServiceId,
} from '../services/api/tradeApi';
import PendingReviewModal from './PendingReviewModal';

const ServicesPendingReview = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [relatedData, setRelatedData] = useState({
    trades: [],
    driverServices: [],
    expenses: [],
    images: [],
    extraHours: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchPendingReviewServices = async () => {
      try {
        const services = await fetchServicesByStatus('PENDING_REVIEW');

        let filteredServices = services;
        if (user.roleId === 1) { 
          filteredServices = services.filter(service => service.clientId === user.idUser);
        } else if (user.roleId === 2) {
          filteredServices = services.filter(service => service.clientId === user.idEntity);
        }

        setServices(filteredServices);
      } catch (error) {
        console.error('Error al obtener los servicios pendientes de revisión:', error);
      }
    };

    fetchPendingReviewServices();
  }, [user]);

  const handleRowClick = async (service) => {
    try {
      const serviceDetails = await fetchServiceById(service.idService);
      const trades = await fetchTradesByServiceId(service.idService);
      const driverServices = await fetchDriverServicesByServiceId(service.idService);
      const expenses = await fetchExpensesByServiceId(service.idService);
      const images = await fetchImagesByServiceId(service.idService);
      const extraHours = await fetchExtraHoursByServiceId(service.idService);

      setSelectedService(serviceDetails);
      setRelatedData({ trades, driverServices, expenses, images, extraHours });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error al obtener los datos relacionados:', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
    setRelatedData({ trades: [], driverServices: [], expenses: [], images: [], extraHours: [] });
  };

  const handleFinalizeService = async () => {
    try {
      if (selectedService) {
        await updateServiceStatus(selectedService.idService, 'COMPLETED');
        setIsModalOpen(false);
        setSelectedService(null);
        setRelatedData({ trades: [], driverServices: [], expenses: [], images: [], extraHours: [] });

        const updatedServices = await fetchServicesByStatus('PENDING_REVIEW');

        let filteredServices = updatedServices;
        if (user.roleId === 1) {
          filteredServices = updatedServices.filter(service => service.clientId === user.idUser);
        } else if (user.roleId === 2) {
          filteredServices = updatedServices.filter(service => service.clientId === user.idEntity);
        }

        setServices(filteredServices);
      }
    } catch (error) {
      console.error('Error al finalizar el servicio:', error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Servicios Pendientes de Revisión
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID del Servicio</TableCell>
              <TableCell>ID del Cliente</TableCell>
              <TableCell>Fecha del Servicio</TableCell>
              <TableCell>Estado del Servicio</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No se encontraron servicios en estado PENDING_REVIEW.
                </TableCell>
              </TableRow>
            ) : (
              services.map((service) => (
                <TableRow key={service.idService}>
                  <TableCell>{service.idService}</TableCell>
                  <TableCell>{service.clientId}</TableCell>
                  <TableCell>{service.serviceDate}</TableCell>
                  <TableCell>{service.serviceStatus}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleRowClick(service)}>
                      Revisar
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedService && (
        <PendingReviewModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          selectedService={selectedService}
          relatedData={relatedData}
          handleFinalizeService={handleFinalizeService}
        />
      )}
    </div>
  );
};

export default ServicesPendingReview;
