import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress
} from '@mui/material';
import { fetchServicesByUserIdCreator } from '../services/api/serviceApi';

const UserServicesModal = ({ open, onClose, userId }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserServices = async () => {
      if (userId) {
        try {
          const response = await fetchServicesByUserIdCreator(userId);
          setServices(response);
        } catch (error) {
          console.error('Error fetching services:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserServices();
  }, [userId]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ backgroundColor: '#1976d2', color: 'white', textAlign: 'center' }}>
        Servicios Creados por el Usuario
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <CircularProgress />
          </div>
        ) : services.length > 0 ? (
          <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                <TableRow>
                  <TableCell><strong>Matrícula</strong></TableCell>
                  <TableCell><strong>Fecha de Creación</strong></TableCell>
                  <TableCell><strong>Estado Actual</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {services.map(service => (
                  <TableRow key={service.idService}>
                    <TableCell>{service.vehiclePlate}</TableCell>
                    <TableCell>{new Date(service.createdAt).toLocaleString()}</TableCell>
                    <TableCell>{service.serviceStatus}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: '20px' }}>
            No se encontraron servicios para este usuario.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
        <Button onClick={handleClose} variant="contained" color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserServicesModal;
