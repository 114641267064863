import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components.scss';

const WelcomeMessage = ({ firstName, lastName }) => {
  return (
    <div className="welcome-message-container">
      <div className="welcome-message-content">
        <h1 className="welcome-message-title">
          {`Bienvenido${lastName ? '' : ''}, ${firstName}${lastName ? ` ${lastName}` : ''}!`}
        </h1>
        <p className="welcome-message-subtitle">Nos alegra verte de nuevo. Selecciona una opción del menú para comenzar.</p>
      </div>
    </div>
  );
};

WelcomeMessage.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
};

export default WelcomeMessage;
